import React from "react";
import {
  Box,
  Typography,
  CardActionArea,
  Avatar,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  duration: {
    color: theme.palette.primary.main,
  },
}));

const ChatHeader = ({
  handleClick,
  image,
  name,
  text,
  question,
  itemStyle,
}) => {
  const classes = useStyles();

  return (
    <CardActionArea onClick={handleClick} className={itemStyle}>
      <Box m={2} display="flex" alignItems="center">
        <Avatar alt="avatar-image" src={image}>
          {name?.charAt(0)}
        </Avatar>

        <Box flexGrow="1" mx={1}>
          <Typography className={classes.ellipsis} variant="subtitle1">
            {name}
          </Typography>
          <Typography className={classes.ellipsis} variant="subtitle2">
            {text}
          </Typography>
        </Box>

        <Box textAlign="right">
          <Typography variant="body2">{`${question}`}</Typography>
          <Typography variant="body2">remaining</Typography>
          {/* <Typography variant="body2">{`${day}`}</Typography> */}
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default ChatHeader;
