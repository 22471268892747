import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
} from "@material-ui/core";
import Routes from "../../routes/routes";

import DeleteIcon from "@material-ui/icons/Delete";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import AuthService from "../../utils/services/auth-service";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  getFormattedDate,
} from "../../utils/app-util";
import AppConstants from "../../utils/app-constants";
import DataService from "../../utils/services/data-service";
import { TextInput } from "sixsprints-react-ui";
import { isRequired } from "../../utils/validations";
import { Form } from "informed";
import CustomDatePicker from "../../components/form/date-picker";
import { useLocation } from "react-router-dom";
import Loader from "../../components/layout/loader";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { height } from "@mui/system";
import { SampleContext } from "../../contexts/SampleContext";
import "./editReport.css";
import { isValid, set } from "date-fns";

const EditReport = () => {
  const gClasses = useGlobalStyles();
  const user = AuthService.getUser();
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const tempReport = (location || {}).state || {};
  const [report, setReport] = useState();
  const [imagesRemove, setImagesRemove] = useState();
  const [fuimages, setfImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const formApi = useRef();
  const fileRef = useRef(null);
  let history = useHistory();
  const [uimages, setImages] = useState([]);
  const { back1, back2, button1 } = useContext(SampleContext);
  const [loader, setloader] = useState(false);
  const reportslug = (location || {}).state.slug || {};
  const [reportv, setReportV] = useState(report?.value);
  const [reportN, setReportN] = useState(report?.reportName);
  const [reportD, setReportD] = useState(report?.reportDate);
  
  useEffect(()=>{
    
    Api2.get(`/report/${reportslug}`).subscribe((resp)=>{
      setReport(resp.data.data);
      setImagesRemove(resp.data.data.images);
      setReportV(resp.data.data?.value);
      setReportN(resp.data.data?.reportName);
      setReportD(resp.data.data?.reportDate);
    })
    
  },[])


  const [toggle, setToggle] = useState(false);
  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  function onImageChange(e) {
    handleUploadFiles(Array.prototype.slice.call(e.target.files));
    setImages([...e.target.files]);
  }

  useEffect(() => {
    if (uimages.length < 1) return;

    // const newImageUrls: any = []; 

    // uimages.forEach((image: any) =>{

    //   newImageUrls.push(URL.createObjectURL(image));
    // }
    // );

    var hashImageUrls; 

    uimages.forEach((image) =>{
      var hash1 = {image: URL.createObjectURL(image), type: image.type};

      hashImageUrls.push(hash1); 
    }
    );

    setImageURLs(hashImageUrls);
  }, [uimages]);


  const handleUploadFiles = (files) => {
    const uploaded = [...fuimages];
    files.some((file) => {
      uploaded.push(file);
    });
    setfImages(uploaded);
  };

  const handleChange = (evt) => {
    let fileArray = Object.values(evt.target.files);

    let formData = new FormData();
    formData.append("file", fileArray[0]);
    setIsLoading(true);
    Api.post(`file/upload/secure?dir=reports/${user.slug}`, formData).subscribe(
      (resp) => {
        setImages((prev) => [...prev, resp.data.data]);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleSubmit = async (val) => {
    val.preventDefault();
    setloader(true);
    const formData = new FormData();
    if (uimages.length > 0) {
      for (let i = 0; i < uimages.length; i++) {
        formData.append("files", uimages[i]);
      }
    } else {
      if (!val.value) {
        // SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FILE_MSG);
        // return;
      }
    }

    if (imagesRemove.length > 0) {
      for (let i = 0; i < imagesRemove.length; i++) {
        formData.append("files", imagesRemove[i]);
      }
    } else {
      // if (!val.value) {
      //   SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FILE_MSG);
      //   return;
      // }
    }
    



    formData.append("action", "SAVE");
    formData.append("slug", report?.slug);
    formData.append("patientSlug", user.slug);
    formData.append("reportDate", new Date(reportD).getTime());
    formData.append("reportName", reportN);
    formData.append("value", reportv);
    const token = AuthService.getToken();
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL1}/report/edit`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response) {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.REPORT_ADD_SUCESS);
        window.location.replace("/report");
        setImages([]);
        formApi.current.reset();
        setloader(false);
      }
    } catch (error) {}


  };

  const onImageRemoveClicked = async (val, i) => {
    setImagesRemove(imagesRemove.filter((item) => item !== val));

    setImageURLs(imageURLS.filter((item) => item !== val));
 

    const formData = new FormData();
    formData.append("slug", report?.slug);
    formData.append("removeAt", i);

    const token = AuthService.getToken();
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL1}/report/delete_report_image`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response) {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.IMAGE_D);
        // setReport(imagesRemove);
        // history.push(Routes.VIEW_REPORT, report);
      }
    } catch (error) {}
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  const pdfclick = (val, type) => {
    var blob = base64toBlob(val, type);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

 
  
  return (
    <>
      <div className="row w-100" style={back1}>
        <div className="col-md-8 mx-auto" style={{ marginTop: "68px" }}>
          <div className="d-flex justify-content-between mb-4 p-2 ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.goBack();
              }}
            >
              <span className="me-2">
                <i className="fa-solid fa-arrow-left"></i>
              </span>
              <span>Report</span>
            </div>
            <div className=" ">
              <form className="">
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    id="custom-switch"
                    className="form-check-input"
                    onClick={toggler}
                  />
                  <label
                    title=""
                    for="custom-switch"
                    className="form-check-label"
                  >
                    Edit
                  </label>
                </div>
              </form>
            </div>
          </div>
          {toggle ? (
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="upload-btn-wrapper pb-3 ps-5  d-flex justify-content-between align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  <input
                    ref={fileRef}
                    type="file"
                    class="form-control"
                    id="signature"
                    autocomplete="off"
                    name="paym[]"
                    validate={isRequired}
                    validateOnMount
                    validateOnChange
                    multiple
                    accept="image/*"
                    onChange={onImageChange}
                    style={{ visibility: "hidden" }}
                  />

                  <label htmlFor="signature">
                    <div
                      class="file btn btn-primary fileupbutton"
                      style={button1}
                    >
                      Select files to Upload
                    </div>
                  </label>
                  {/* <button
                    className="btn btn-primary upload_btn "
                    style={{ cursor: "pointer" }}
                    type="file"
                    name="myfile"
                  >
                    Select Files to upload
                  </button>
                  <input type="file" name="myfile" /> */}
                </div>
              </div>

              <div className="delete  row mb-2">
                {imagesRemove?.map((imageSrc, index) => (
                  <div
                    className="col-md-4 d-flex align-items-center mb-3"
                    style={{ gap: "50px" }}
                  >
                    {imageSrc.contenttype == "application/pdf" ? (
                      <img src="/pdf.png" 
                      style={{
                        maxWidth: "150px",
                        width: "100%",
                        height: "100%",
                        minWidth: "150px",
                        objectFit: "contain",
                      }}
                      className ="ps-5"
                      />
                    ) : (
                      <img
                        className="  ps-5"
                        src={imageSrc.content}
                        alt=""
                        style={{
                          maxWidth: "190px",
                          width: "100%",
                          height: "100%",
                          minWidth: "190px",
                          objectFit: "contain",
                        }}
                      />
                    )}

                    <i
                      className="fa-solid fa-trash pe-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => onImageRemoveClicked(imageSrc, index)}
                    ></i>
                  </div>
                ))}

                {imageURLS.map((imageSrc, index) => (
                  <div
                    className="col-md-4 d-flex align-items-center mb-2 mt-2"
                    style={{ gap: "50px" }}
                  >

                    {imageSrc.type == "application/pdf" ? (
                    
                      <img src="/pdf.png" 
                      style={{
                        maxWidth: "150px",
                        width: "100%",
                        height: "100%",
                        minWidth: "150px",
                        objectFit: "contain",
                      }}
                      />
                    ) : (
                      <img
                        className="  ps-5"
                        src={imageSrc.image}
                        alt=""
                        style={{
                          maxWidth: "190px",
                          width: "100%",
                          height: "100%",
                          minWidth: "190px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    <i
                      className="fa-solid fa-trash pe-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => onImageRemoveClicked(imageSrc, index)}
                    ></i>
                  </div>
                ))}
              </div>

              <form className="mb-2 p-5" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">
                    Report Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    validate={isRequired}
                    required="true"
                    value={reportN}
                    onChange={(e) => setReportN(e.target.value)}
                  />
                </div>
                {/* <div class="col-md-6">
                  <label for="validationDefault03" class="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationDefault03"
                    required
                  />
                </div> */}
                <div className="mb-3">
                  <label for="Reportvalue" className="form-label">
                    Value*
                  </label>
                  <input
                    type="text"
                    value={reportv}
                    onChange={(e) => setReportV(e.target.value)}
                    className="form-control"
                    required="true"
                    id="Reportvalue"
                    validate={isRequired}
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    Report Date*
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={getFormattedDate(reportD, "YYYY-MM-DD")}
                    onChange={(e) => setReportD(e.target.value)}
                    required="true"

                  />
                </div>
                {loader ? (
                  <button class="btn btn-dark" type="button" disabled>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <div className=" mb-2">
                    <button
                      className="btn btn-dark"
                      // onClick={(e) => handleSubmit(e)}
                      type="submit"
                      style={button1}
                    >
                      Save
                    </button>
                  </div>
                )}
              </form>
            </div>
          ) : (
            <div className="">
              <div className="row gy-4 mb-5">
                {imagesRemove?.map((imageSrc, index) => (
                  <div className="col-md-4 pe-0 my-auto d-flex flex-column justify-content-center align-items-center ">
                    {imageSrc.contenttype == "application/pdf" ? (
                      <>
                      <img src="/pdf.png" style={{
                             maxWidth: "150px",
                             width: "100%",
                             height: "100%",
                             minWidth: "150px",
                             objectFit: "contain",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            pdfclick(imageSrc.data.data, imageSrc.contenttype)
                          }
                          />
                      </>
                    ) : (
                      <>
                        <img
                          className="img-fluid p-3"
                          src={imageSrc.content}
                          alt=""
                          style={{
                            maxWidth: "100%",
                            width: "190px",
                            height: "200px",
                            minWidth: "190px",
                            objectFit: "contain",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            pdfclick(imageSrc.data.data, imageSrc.contenttype)
                          }
                        />
                        
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div className="form_content p-2 mb-5">
                <div className="mb-3">
                  <h6>Report Name</h6>
                  <span>{report?.reportName}</span>
                </div>
                <div className="mb-3">
                  <h6>Value</h6>
                  <span>{report?.value}</span>
                </div>
                <div className="mb-3">
                  <h6>Report Date</h6>
                  <span>
                    {getFormattedDate(report?.reportDate, "dddd, Do MMMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditReport;
