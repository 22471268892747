import React from "react";
import { Paper, Typography, IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
  
  const type = "primary";
  var col1 = "";
  var col2 = "";
  var col3 = "";
  var col4 = "";


  if (type === "primary"){
    col1=theme.palette.primary.light;
    col2=theme.palette.primary.main;
    col3=theme.palette.primary.dark;
    col4=theme.palette.primary.contrastText;
  }
  else{
    col1=theme.palette.secondary.light;
    col2=theme.palette.secondary.main;
    col3=theme.palette.secondary.dark;
    col4=theme.palette.secondary.contrastText;
  }
  
  return ({
    pt: {
      padding: theme.spacing(2, 0),
      color: col4
    },
    m: {
      margin: theme.spacing(1),
      padding: "1rem",
      background: `${col3} 0% 0% no-repeat padding-box`,
      boxShadow: "0px 5px 20px #00000008",
      borderRadius: 14
    },
    greyBg: {
      background: "#F6F7FC 0% 0% no-repeat padding-box"
    }
  });

});


const CardTile = ({ icon, title, subtitle }) => {
  const classes = useStyles();

  return (
    <Paper style={{height: "152px"}} className={classes.m}>
      <IconButton className={classes.greyBg}>
        <img alt="icon" height="26" width="26" src={icon} />
      </IconButton>
      <Typography className={classes.pt} variant="body2">
        <strong>{title}</strong>
      </Typography>
      <Typography variant="body2">{subtitle}</Typography>
    </Paper>
  );
};

export default CardTile;
