import React, { useEffect, useState, useContext } from "react";
import Api from "../../utils/api";

import useGlobalStyles from "../../utils/global-style";
import AuthService from "../../utils/services/auth-service";
import MapSection from "./map";
import { useParams, useHistory } from "react-router-dom";
import Sidebutton from "../../components/layout/sidebutton";
import { useLocation } from "react-router-dom";
import Mobilebutton from "../../components/layout/mobilebutton";
import { SampleContext } from "../../contexts/SampleContext";
import "./doctor-profile.css";
import Api2 from "../../utils/api2";


const DoctorProfile = () => {
  let history = useHistory();
  const [doctor, setDoctor] = useState(null);
  const [drTImings, setDrTimings] = useState([]);
  const [userSessions, setUserSessions] = useState([]);
  const gClasses = useGlobalStyles();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const name = searchParams.get("n");
  const tenant = AuthService.getTenant();
  const [productSlug, setProductSlug] = useState("");
  const params = useParams();
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  var bookButton = "";
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const { back3, back4, back1, back2 } = useContext(SampleContext);
  var back5 = { back4 };
  back5.margin = "0px 5px";
  var back6 = { back1 };
  back6.margin = "0px 5px";

  useEffect(() => {
    const tenant = AuthService.getTenant();
    if (AuthService.isAuthenticated) {
      Api2.get("/chat-session/my").subscribe((resp) => {
        setUserSessions(resp.data.data.content);
      });
    }
  }, []);

  const getSocialIcon = (type) => {
    let icon;
    
    switch (type) {
      case "FACEBOOK":
        icon = <i className="fa-brands fa-facebook-f"></i>;
        break;
      case "LINKEDIN":
        icon = <i className="fa-brands fa-linkedin"></i>;
        break;
      case "INSTAGRAM":
        icon = <i className="fa-brands fa-instagram"></i>;
        break;
      case "TWITTER":
        icon = <i className="fa-brands fa-twitter"></i>;
        break;
      case "WEBSITE":
        icon = <i class="fa-brands fa-firefox-browser"></i>;
        break;

      default:
        break;
    }

    return icon;
  };

  useEffect(() => {
    Api.get(`getappointmenttype?status=Active`).subscribe((resp) => {
      setAppointmentTypes(resp.data);
    });

    Api.get(`getprofile?id=${params.id}`).subscribe((resp) => {
      if (!resp.data.status) {
        window.location.replace("/");
      }

      const arr = resp.data.data || {} || {} || [];
      setDoctor(arr);
      setLoad(false);

      if (arr && arr.slots) {
        let availSummaryObj = arr?.slots.map((item) => {
          let newObj = {
            type: item.appointmentType,
            days: `${item.fromDay} - ${item.toDay}`,
            timings: `${item.fromTime} - ${item.toTime}`,
            fee: `${item.fee}`,
          };
          return newObj;
        });
        setDrTimings(availSummaryObj);
      }
    });
  }, [params.id]);

  var location;

  location = {
    lat: "",
    lng: "",
  };

  var mapKey;
  if (doctor?.lat !== undefined) {
    location = {
      lat: parseFloat(doctor?.lat),
      lng: parseFloat(doctor?.long),
    };
    mapKey = tenant.google_api_key;
  }

  console.log(doctor?.lat?.length);


  String.prototype.convertToRGB = function(){
 
    var aRgbHex = this.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16),
        parseFloat('0.5',2)
    ];
    return aRgb;
}

const colorcode = back2.backgroundColor.replace('#','')
const colorc = colorcode.convertToRGB();
const code = colorc.join();

  return (
    <>
      {load ? (
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
          style={{ margin: "90px auto" }}
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          {doctor && (
            <>
              {/* Desktop View */}
              <div
                className="d-none d-md-block d-lg-block d-sm-none pt-5 mt-5"
                style={back3}
              >
                <div className="container">
                  <div className="row overflow-hidden">
                    <Sidebutton
                      doctor={doctor}
                      AuthService={AuthService}
                      profile={true}
                      docid={doctor?.slug}
                      docterm={doctor?.terms}
                    />

                    <main className="col-lg-9 col-md-9 col-sm-9 " style={back1}>
                      <h5 className=" text-start">{doctor?.name}</h5>
                      <p className="p-0 mb-0">{doctor?.speciality?.name}</p>
                      {doctor?.registrationNo && (

                        <p className="p-0">Reg No: {doctor?.registrationNo}</p>

                      )}
                      <h6 className=" text-start">
                        <b>{doctor?.shortTitle}</b>
                      </h6>
                      <p>{doctor?.bio}</p>


                      <div className="d-grid gap-1 mb-4 mt-1">
                        {drTImings?.length > 0 && (
                          <>
                        <h6 className="text-center text-lg-start mb-0 pt-2">
                          Doctor’s Availability
                        </h6>
                        <hr style={{ borderTop: "dotted 1px" }} />
                        <div className="p-2 rounded-3 row">
                          {drTImings.map((item, index) => {
                            let display = false;

                            {
                              appointmentTypes.map((item1, index2) => {
                                if (item1._id == item.type) {
                                  if (item1.status == "Active") {
                                    item.name = item1.title;
                                    display = true;
                                  }
                                }
                              });
                            }

                            return (
                              <>
                                {display && (
                                  <div
                                    className="col-lg-6 col-6 text-start p-2"
                                    style={back4}
                                  >
                                    <b>
                                      {item.name} - ₹ {item.fee}
                                    </b>
                                    <p>
                                      {item.days}, {item.timings}
                                    </p>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        </>
                        )}

                        {doctor?.notes && (
                          <>
                            <br />
                            <h6 className="text-center text-lg-start mb-0 pt-2">
                              Notices & Announcements
                            </h6>
                            <hr style={{ borderTop: "dotted 1px" }} />
                            <div className="p-2 rounded-3 row">
                              <div className=" col-12 text-start">
                                {doctor?.notes}
                              </div>
                            </div>
                          </>
                        )}

                        <br />
                        {doctor?.lat?.length !== 0 &&
                            doctor?.lat?.length !== undefined &&
                            doctor?.long?.length !== 0 &&
                            doctor?.long?.length !== undefined && (
                              <h6 className="text-center text-lg-start mb-4 pt-2 ">
                          {" "}
                          Location
                        </h6>
                        )}
                        <div className=" text-center bg-image" style={back6}>
                          {doctor?.lat?.length !== 0 &&
                            doctor?.lat?.length !== undefined &&
                            doctor?.long?.length !== 0 &&
                            doctor?.long?.length !== undefined && (
                              <MapSection
                                location={location}
                                zoomLevel={17}
                                mapKey={tenant?.google_api_key}
                                style={{ width: "100%", height: "100%" }}
                              />
                            )}
                          <div className="col-12 text-start pt-2">
                            {doctor?.address && <>{doctor?.address}, &nbsp;</>}
                            {doctor?.address2 && (
                              <>{doctor?.address2}, &nbsp;</>
                            )}
                            {doctor?.city && <>{doctor?.city}, &nbsp;</>}
                            {doctor?.state && <>{doctor?.state}, &nbsp;</>}
                            {doctor?.country && <>{doctor?.country}, &nbsp;</>}
                            {doctor?.pincode && <>{doctor?.pincode} &nbsp;</>}
                            <br />

                            {doctor?.booking_number && (
                              <>
                                <a
                                  href={`tel:${doctor?.booking_number}`}
                                  style={back1}
                                  className="link-tag"
                                >
                                  {doctor?.booking_number}
                                </a>{" "}
                                &nbsp;| &nbsp;
                              </>
                            )}
                            {doctor?.email && (
                              <>
                                <a
                                  href={`mailto:${doctor?.email}`}
                                  style={back1}
                                  className="link-tag"
                                >
                                  {doctor?.email}
                                </a>
                                <br />
                              </>
                            )}
                          </div>

                          {doctor?.social_links && (
                            <>
                              <h6 className="text-center text-lg-start mb-4 pt-2 mt-4">
                                Social links
                              </h6>
                              <hr style={{ borderTop: "dotted 1px" }} />

                              <div className="links d-flex">
                                {doctor.social_links
                                  .filter((item) => item.link !== "")
                                  .map((platform, indx) => {
                                    return (
                                      <a
                                        className="me-3 fs-3"
                                        href={platform.url}
                                        target="_blank"
                                        style={back1}
                                      >
                                        {getSocialIcon(platform.link)}
                                      </a>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                       
                    </main>
                  </div>
                </div>
              </div>

              {/* Mobile View */}

              <section className="d-lg-none d-block" style={back1}>
                <div className="container-fluid" >
                  <div className="row overflow-hidden">
                    <main className="col-lg-12 col-md-12 col-sm-12 p-0">
                      <div className="card" style={{"--bs-card-bg":"transparent"}}>
                        <img
                          src={doctor?.profilePic ? doctor?.profilePic : `images/user.png`}
                          className="img-fluid p-0"
                          alt="..."
                        />
                        <div className="doctorImage" style={{backgroundColor: `rgba(${code})`}}>
                          <h5 className="card-title pb-2 mb-0 p-1" style={{color: back2.color, fontWeight: `bold`}}>
                            {doctor?.name}
                            <br />
                            <p>
                              {doctor?.speciality?.name}
                              <br />
                              {doctor?.registrationNo && (
                                <>

                              Reg No: {doctor?.registrationNo}
                                </>
                              )}
                            </p>
                          </h5>
                        </div>
                      </div>

                      {/* {Menu} */}

                      <Mobilebutton
                        doctor={doctor}
                        AuthService={AuthService}
                        docid={doctor?.slug}
                      />

                      <h5 className="text-start my-3 px-3">
                        {doctor?.shortTitle}
                      </h5>
                      <hr style={{ borderTop: "dotted 1px" }} />
                      <div
                        className="d-grid gap-3 mt-2 mx-2 mb-2 px-3"
                        style={back3}
                      >
                        <div
                          className="p-2 pt-2 pb-4 rounded-3 row"
                          style={back3}
                        >
                          {doctor?.bio}
                        </div>
                      </div>

                      {drTImings.length>0 && (

                      <h5 className="text-start my-3 px-3">
                        Doctor’s Availability
                      </h5>
                      )}
                      <hr style={{ borderTop: "dotted 1px" }} />
                      <div
                        className="d-grid gap-3 mt-2 mx-2 mb-2 px-3"
                        style={back3}
                      >
                        <div
                          className="p-2 pt-2 pb-4 rounded-3 row"
                          style={back3}
                        >
                          {drTImings.map((item, index) => {
                            let display = false;

                            {
                              appointmentTypes.map((item1, index2) => {
                                if (item1._id == item.type) {
                                  if (item1.status == "Active") {
                                    item.name = item1.title;
                                    display = true;
                                  }
                                }
                              });
                            }

                            return (
                              <>
                                {display && (
                                  <>
                                    <div className=" col-12 text-start fs-5">
                                      {index != 0 ? (
                                        <>
                                          <hr></hr>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <h6>
                                        <span className="float-start">
                                          {item.name}
                                        </span>{" "}
                                        <span className="float-end">
                                          {" "}
                                          ₹ {item.fee}
                                        </span>
                                      </h6>
                                    </div>
                                    <div className=" col-12 fs-6">
                                      {" "}
                                      <p className="m-0">
                                        {item.days}, {item.timings}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>

                      {/* <div className="d-grid gap-2 my-3">
                                <button className="btn btn-primary fs-5 my-2 mx-auto" style={{ position: "fixed", width:"100%",bottom:"0px" }} type="button">Book Appointment</button>

                            </div> */}
                      {doctor?.notes && (
                        <>
                          <h5 className="text-start px-3 my-3 mt-4">
                            Notices & Announcements
                          </h5>
                          <hr style={{ borderTop: "dotted 1px" }} />
                          <div
                            className="d-grid gap-3 mx-2 my-2 px-3"
                            style={back1}
                          >
                            <div className="p-2 rounded-3 row">
                              <p className="text-start pt-2">{doctor?.notes}</p>
                            </div>
                          </div>
                        </>
                      )}


                      {doctor?.lat?.length !== 0 &&
                        doctor?.lat?.length !== undefined &&
                        doctor?.long?.length !== 0 &&
                        doctor?.long?.length !== undefined &&(

                      <h5 className="text-start my-3 px-3 mt-4"> Location</h5>
                        )}


                      {doctor?.lat?.length !== 0 &&
                        doctor?.lat?.length !== undefined &&
                        doctor?.long?.length !== 0 &&
                        doctor?.long?.length !== undefined && (
                          <MapSection
                            location={location}
                            zoomLevel={17}
                            mapKey={tenant?.google_api_key}
                            style={{ width: "100%", height: "100%" }}
                          />
                        )}

                      <img
                        src="images/map.png"
                        className="img-fluid mb-3 text-center"
                        alt=""
                      />
                      <div className="d-flex px-4" style={back1}>
                        {(doctor?.address || doctor?.address2 ||  doctor?.city || doctor?.state || doctor?.country || doctor?.pincode)
                        
                        &&
                        (
                        <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill pt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        </>
                        )
                        }
                        <p className="ps-4">
                          {doctor?.address && <>{doctor?.address}, &nbsp;</>}
                          {doctor?.address2 && <>{doctor?.address2}, &nbsp;</>}
                          {doctor?.city && <>{doctor?.city}, &nbsp;</>}
                          {doctor?.state && <>{doctor?.state}, &nbsp;</>}
                          {doctor?.country && <>{doctor?.country}, &nbsp;</>}
                          {doctor?.pincode && <>{doctor?.pincode} &nbsp;</>}
                          <br />
                        </p>
                      </div>
                      <div className="d-flex px-4" style={back1}>
                      {doctor?.email && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          fill="currentColor"
                          className="bi bi-envelope pt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                        </svg>
                      )}
                        <p className="ps-3" style={back1}>
                          {doctor?.email && (
                            <>
                              <a
                                href={`mailto:${doctor?.email}`}
                                style={back1}
                                className="link-tag"
                              >
                                {doctor?.email}
                              </a>
                              <br />
                            </>
                          )}
                        </p>
                      </div>
                      <div className="d-flex px-4" style={back1}>
                      {doctor?.booking_number && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-telephone "
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>)}
                        <p className="ps-3 ">
                          {doctor?.booking_number && (
                            <>
                              <a
                                href={`tel:${doctor?.booking_number}`}
                                style={back1}
                                className="link-tag"
                              >
                                {doctor?.booking_number}
                              </a>
                            </>
                          )}
                        </p>
                      </div>

                    </main>
                  </div>
                </div>
              </section>
              <footer
                className="mb-5 d-lg-none d-md-none d-block"
                style={back1}
              >
                {doctor?.social_links && (
                  <>
                    <h5 className="text-start my-3 px-3 mt-4">Social links</h5>

                    <div className="p-2 mx-3 row">
                      {doctor.social_links
                        .filter((item) => item.link !== "")
                        .map((platform, indx) => {
                          return (
                            <a className="me-3 fs-3 col-1" href={platform.url} style={back1}>
                              {getSocialIcon(platform.link)}
                            </a>
                          );
                        })}
                    </div>
                  </>
                )}
              </footer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DoctorProfile;
