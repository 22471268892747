import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useQuery } from "../utils/app-util";
import AuthService from "../utils/services/auth-service";
import Routes from "../routes/routes";
import { useHistory } from "react-router-dom";

const Callback = () => {
  const query = useQuery();
  let history = useHistory();
  const token = query.get("access-token");
  AuthService.setToken(token);

  console.log(token);

  AuthService.validateToken().subscribe(
    data => {
      console.log(data);
      history.replace(Routes.HOME);
    },
    error => {
      AuthService.clearLocalStorage();
    }
  );
  return (
    <Box
      display="flex"
      justifyContent="center"
      height={"100vh"}
      alignItems="center"
    >
      <Typography variant="h6">Logging in ... </Typography>
    </Box>
  );
};

export default Callback;
