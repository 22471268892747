import React from "react";

import { Box, Typography } from "@material-ui/core";

import noChatImg from "../../assets/no-past-chat.svg";

const NoChat = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      style={{ flex: "1" }}
    >
      <img
        src={noChatImg}
        alt="booking suceess"
        style={{ marginTop: "-150px" }}
      />
      <Typography variant="h6" style={{ marginTop: "20px" }}>
        No Past chat found
      </Typography>
    </Box>
  );
};

export default NoChat;
