import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ScrollToTop } from "../utils/app-util";
import { NotificationContainer } from "react-notifications";
import Routes from "./routes";
import PrivateRoute from "./private-route";
import Main from "../screens/main/main";
import HomeContainer from "../screens/home/container";
import LoginPage from "../screens/login/login-page";
import Logout from "../screens/logout"

const AllRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <React.Fragment>
        <Switch>
          <PrivateRoute path={Routes.MAIN} component={Main} />
          <Route path={Routes.HOME} component={HomeContainer} />
          <Route exact path={Routes.LOGIN} component={LoginPage} />
          <Route exact path={"/logout"} component={Logout} />
          
        </Switch>
        <NotificationContainer />
      </React.Fragment>
    </Router>
  );
};

export default AllRoutes;
