import React from "react";
import { Box, makeStyles, IconButton } from "@material-ui/core";
import { Form } from "informed";
import { TextArea } from "informed";
import useGlobalStyles from "../../utils/global-style2";
import SendIcon from "@material-ui/icons/SendOutlined";
import AttachIcon from "@material-ui/icons/AttachFile";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  containerInput: {
    margin: theme.spacing(1, 1),
    padding: theme.spacing(0, 2),
    height: "4rem",
  },

  attachIcon: {
    height: "4rem",
  },
}));

const ChatTextBox = ({
  handleSubmit,
  uploadImage,
  formApi,
  handleKeyPress,
}) => {
  const classes = useStyles();
  const gClasses = useGlobalStyles();

  return (
    <Box width={{ xs: "100%", sm: "100%", md: "100%" }}>
      <Form
        style={{ width: "100%" }}
        apiRef={formApi}
        onSubmit={handleSubmit}
        onKeyPress={handleKeyPress}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            display="flex"
            flexGrow="1"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.containerInput, gClasses.roundWhiteBg)}
          >
            <TextArea
              style={{
                borderRadius: "10px",
                width: "100%",
                border: "none",
                outline: "none"
              }}
              field="msg"
              placeholder="Please type your message here"
            />

            <IconButton type="submit" color="primary">
              <SendIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box
            mx={1}
            display="flex"
            className={clsx(classes.attachIcon, gClasses.roundWhiteBg)}
            alignItems="center"
          >
            <input
              accept="image/*"
              className={gClasses.fileInput}
              id="contained-button-file"
              type="file"
              multiple
              onChange={uploadImage}
            />
            <label htmlFor="contained-button-file">
              <IconButton color="primary" component="span">
                <AttachIcon fontSize="small" />
              </IconButton>
            </label>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default ChatTextBox;
