import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SampleContext } from "../../contexts/SampleContext";
import Api2 from "../../utils/api2";
import "./receipt.css";
import moment from "moment";

const Receipt = () => {
  const { back1, back2 } = useContext(SampleContext);
  const [invoice, setInvoice] = useState();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const slug = searchParams.get("id");

  useEffect(() => {
    Api2.get(`appointment/receipt/print/${slug}`).subscribe((resp) => {
      setInvoice(resp.data.data);
    });
  }, []);
  var today = new Date(),
    currentdate =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
  var currenttime = today.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return (
    <div style={{ marginTop: "90px", fontSize: "15px" }}>
      <div style={back1}>
        <table className="report-container container">
          <thead
            className="report-header"
            style={{ position: "sticky", top: "0px" }}
          >
            <tr>
              <th className="report-header-cell"></th>
            </tr>
          </thead>
          <tfoot className="report-footer">
            <tr>
              <td className="report-footer-cell"></td>
            </tr>
          </tfoot>
          <tbody className="report-content">
            <tr>
              <td className="report-content-cell">
                <div className="main main_1">
                  <div className="head_part d-flex justify-content-between mb-2">
                    <div>
                      <h1
                        className="heading_invoice"
                        style={{ fontSize: "3.5rem", fontWeight: "300" }}
                      >
                        INVOICE
                      </h1>
                    </div>
                    <div className="">
                      <div className="d-flex align-items-center justify-content-end img_name">
                        <div>
                          <img
                            className="rounded-circle me-3"
                            src="./images/dr.png"
                            alt=""
                            style={{ width: "83px" }}
                          />
                        </div>
                        <div>
                          <h1
                            className="jyoti_name"
                            style={{ fontSize: "3.5rem" }}
                          >
                            {" "}
                            <b>{invoice?.tenant?.name}</b>{" "}
                          </h1>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end flex-column ">
                        <small className="text-end address_1">
                          {" "}
                          <b>
                            {invoice?.tenant?.contactInfo?.addressLine1}
                          </b>{" "}
                        </small>
                        <small className="text-end address_1">
                          <b>{invoice?.tenant?.contactInfo?.addressLine2}</b>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="contact_portion mb-3 d-flex justify-content-end">
                    <span
                      className=" contact_portion_1 pe-2 ps-2"
                      style={{ borderRight: `2px solid ${back1.color}` }}
                    >
                      {" "}
                      <b>Phone:</b>{" "}
                      {invoice?.appointment?.doctor?.booking_number}
                    </span>
                    <span
                      className=" contact_portion_1 pe-2 ps-2"
                      style={{ borderRight: `2px solid ${back1.color}` }}
                    >
                      {" "}
                      <b>Mail:</b> {invoice?.appointment?.doctor?.email}
                    </span>
                    <span className="contact_portion_1 pe-2 ps-2">
                      {" "}
                      <b>Web:</b> {invoice?.appointment?.doctor?.appointmentUrl}
                    </span>
                  </div>
                  <div className="second_part d-flex justify-content-between align-items-center ps-2">
                    <div className="patient_name">
                      <h5>INVOICE TO</h5>
                      <p>
                        {" "}
                        <b>{invoice?.patient?.name} </b> <br />
                        <small style={{ fontWeight: "500" }}>
                          UHID: &nbsp;{invoice?.patient?.uhid}
                        </small>
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        Payment Method: {invoice?.appointment?.mode}
                      </p>
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <span className="ps-2 pe-2">
                          {" "}
                          <b>NO.</b> <br /> #{invoice?.appointment?.slug}
                        </span>
                        <span className="ps-2 pe-2">
                          {" "}
                          <b>Date:</b> <br />
                          {moment(invoice?.appointment?.date).format(
                            "D/MM/YYYY"
                          )}
                        </span>
                        {/* <span className="ps-2 pe-2">
                          {" "}
                          <b>DUE DATE:</b> <br /> 10/01/2021
                        </span> */}
                      </div>
                      <h6
                        className="pt-2 pb-2 bg-dark text-white text-center total_price"
                        style={{ fontSize: "1.25rem" }}
                      >
                        TOTAL INR: {invoice?.totalAmount}
                      </h6>
                    </div>
                  </div>
                  <table class="table " style={back1}>
                    <thead>
                      <tr>
                        <th scope="col">ITEM DESCRIPTION</th>
                        <th scope="col">QTY</th>
                        <th scope="col">PRICE</th>
                        <th scope="col">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={back1}>
                        <th scope="row">
                          Consultation charges <br />
                          {/* <small style={{ fontWeight: "lighter" }}>
                            Appointment Type:Audio
                          </small> */}
                        </th>
                        <td>
                          <b>1</b>
                        </td>
                        <td>INR {invoice?.appointment?.fees}</td>
                        <td>INR {invoice?.appointment?.fees}</td>
                      </tr>

                      { invoice?.appointment?.discount&& (<tr >
                        <th scope="row">
                           Discount
                           <br />
                        </th>
                        <td>
                          <b>1</b>
                        </td>
                        <td>INR {invoice?.appointment?.discount}</td>
                        <td>INR {invoice?.appointment?.discount}</td>
                      </tr>)}
                      <tr>
                        <th scope="row">Grand Total</th>
                        <td colspan="2"></td>
                        <td>
                          {" "}
                          <b>INR {invoice?.totalAmount}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <small>
                      This is computer generated receipt no signature required |
                      Print Date and time:{currentdate} | {currenttime}
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          class="btn btn-primary"
          onClick={window.print}
          id="printPageButton"
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default Receipt;
