import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, makeStyles, fade, Container } from "@material-ui/core";
// import { SearchInput } from "sixsprints-react-ui";
import CardTile from "../../components/layout/card-tile";
import useGlobalStyles from "../../utils/global-style";
import { Link } from "react-router-dom";
import Routes from "../../routes/routes";
import clsx from "clsx";
import Api2 from "../../utils/api";
import SearchInput from "../../components/form/search-input";

import FetchProgress from "./fetch-progress";
import NoResult from "./no-result";
import { SampleContext } from "../../contexts/SampleContext";
import "./global.css";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  search: {
    border: "hidden",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.6),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  inputInput: {
    fontSize: 18,
    border: "hidden",
    "&:focus": {
      outline: "none",
    },
    backgroundColor: "transparent",
    padding: theme.spacing(2, 1, 2, 2),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const Category = () => {
  const [specialities, setSpecialities] = useState([]);
  const [tempSpecialities, setTempSpecialities] = useState();
  const classes = useStyles();
  const gClasses = useGlobalStyles();
  const [save, setSave] = useState();

  const {back1, back2 } = useContext(SampleContext)


  useEffect(() => {
    Api2.get("/getspeciality?status=Active").subscribe((resp) => {
      const arr = resp.data || {} || [];
      console.log(arr);
      setSpecialities(arr);
      setTempSpecialities(arr);
    });
  }, []);



    const handleSearch = ( search ) => {
      const filteredList = search
        ? specialities.filter((item) => {
            return (item.name || "").toLowerCase().includes(search);
          })
        : specialities;
  
      setTempSpecialities(filteredList);
    };


  return (
    <div className="row" style={back1}>
      <div className="col-md-10 mx-auto pt-5" >
        <div className="row mb-5">
          <div className="col-md-8 mx-auto ">
            <div className="input-group container">
              <input
                type="search"
                className="form-control search-box"
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="search-addon"
                style={{ outline: "none"}}
                onChange={(e)=>handleSearch(e.target.value)}
              />
              <button type="button" className="btn btn-outline-primary"    onClick={()=>handleSearch(save)} style={{"color":"white !important"}}
           >
                search
              </button>
            </div>
          </div>
        </div>

        {!tempSpecialities ? (
          <FetchProgress />
        ) : (
          <>
            {tempSpecialities && tempSpecialities.length === 0 ? (
              <NoResult />
            ) : (
              <>
                <div className="row mb-4 p-4 ">
                  {tempSpecialities.map((item, index) => (
                    <div
                      className="card mb-2 bg-secondary text-white col-md-3 col-6 mx-auto ps-2 text-center"
                      style={{
                        width: "11rem",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={()=>window.location.replace(Routes.DOCTOR +`?category=${item.slug}`)}
                    >
                      <img
                        src={item.pic}
                        className="card-img-top img-fluid rounded-start"
                        alt="..."
                        style={{ width: "69px", margin: "10px auto" }}
                      />
                      <div className="card-body text-center">
                        <span className="card-title">{item.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Category;
