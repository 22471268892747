import React, { useEffect, useContext } from "react";
import AuthService from "../../utils/services/auth-service";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION
} from "../../utils/app-util";
import { useState } from "react";
import Api from "../../utils/api2";
import Api1 from "../../utils/api";

import AppConstants from "../../utils/app-constants";
import axios from "axios";
import moment from "moment";
import { SampleContext } from "../../contexts/SampleContext";
import { useHistory } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(AuthService.getUser());
  const [name, setName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [dob, setDob] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [gender, setGender] = useState();
  const [occupation, setOccupation] = useState();
  const [maritalstatus, setMaritalStatus] = useState();
  const [bmi, setBmi] = useState();
  const [info, setInfo] = useState();
  const [signatureDataImg, setSignatureDataImg] = useState([]);
  const [signatureImg, setSignatureImg] = useState("");
  const [loader, setLoader] = useState(true);
  const { back2, back1, back3, back4, button1 } = useContext(SampleContext);
  const [loadbutton, setLoadButton] = useState(false);
  let history = useHistory();

  const redirectap = () =>{
    if(!AuthService.isAuthenticated){
      window.location.replace('/')
    }
  }
  redirectap();

  useEffect(() => {
    setName(user?.name);
    setMobileNumber(user?.mobileNumber);
    setEmail(user?.email);
    setAddress(user?.address);
    setHeight(user?.height);
    setWeight(user?.weight);
    setDob(user?.dob);
    setGender(user?.gender);
    setOccupation(user?.occupation);
    setMaritalStatus(user?.maritalStatus);
  }, [user]);
  
  useEffect(() => {
    if(user?.weight && user?.height){
      let cal = (
        [Number(user?.weight) / Number(user?.height) / Number(user?.height)] *
        10000
      ).toFixed(1);
      setBmi(cal);
      if (cal < 18.5) {
        setInfo("UnderWeight");
      } else if (cal >= 18.5 && cal <= 24.9) {
        setInfo("Normal");
      } else {
        setInfo("Obese")
      }
    }
    else{
      setBmi('');
      setInfo('');
    }

  }, [user]);

  const handleSubmit = (val) => {
    var val1 = {}
    val1.id = user?.id;
    val1.mobileNumber = mobileNumber;
    val1.uhid = user?.uhid;
    val1.name = name;
    val1.roleName = "PATIENT";
    val1.bmi = bmi;
    val1.height = height;
    val1.weight = weight;
    val1.email = email;
    val1.address = address;
    val1.gender = gender;
    val1.maritalStatus = maritalstatus;
    val1.occupation = occupation;
    val1.dob = dob;
    setLoadButton(true);



    Api.put("patient/profile", val1).subscribe(
      
      (resp) => {     
        setUser(val);
        AuthService.setUser(resp.data.data);
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
        setLoadButton(false);
        window.location.replace('/profile');

      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setLoadButton(false);
      }
    );
  };

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    e.preventDefault();
    if (e.target.files[0]) {
      setSignatureImg(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSignatureDataImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    const handleSaveImage = async (type) => {

      if (signatureImg?.size != undefined) {
        setLoader(true);

        const formData = new FormData();
        formData.append("id", user?.id);
        formData.append("file", signatureImg);

        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_BASE_URL}/update_profile_image`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (response) {
            SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
            setLoader(false);
          }
        } catch (error) {
          DEFAULT_ERROR_CALLBACK(error);
        }
      }
    };
    handleSaveImage();
  }, [signatureImg]);

  useEffect(() => {
    Api1.get(`getprofile?id=${user?.id}`).subscribe(
      (resp) => {
        setSignatureDataImg(resp.data.data.profilePic);
        setLoader(false);
      },
      (error) => {
        // DEFAULT_ERROR_CALLBACK(error);
      }
    );
  }, []);

  // const handleProfile = () =>{
  //   console.log("hhh");
  //   document.getElementById('exampleModal').style.display="block";
  // }

  return (
    <>
      <div class="container" style={back1}>
        <div class="row overflow-hidden" style={{"marginTop":"60px"}}>
          
          <main class=" col-md-12 ">
            <div class="text-center mx-auto">
              <input
                type="file"
                name="signature"
                class="form-control"
                id="signature"
                autocomplete="off"
                style={{ visibility: "hidden" }}
                onChange={handleInputs}
                field="image"
              />

              {loader ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <div>
                  <img
                    src={
                      signatureDataImg ? signatureDataImg : "images/user.png"
                    }
                    class="img-fluid"
                    alt=""
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
                <label htmlFor="signature" style={{cursor: "pointer"}}>
              <div role="button">
                <span
                  style={{
                    background: "#508AE2",
                    position: "relative",
                    bottom: "20px",
                    left: "40px",
                    padding: "8px 9px 8px 10px",
                    "border-radius": "30px",
                  }}
                >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      fill="#000000"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <path
                        d="M208,208H48a16,16,0,0,1-16-16V80A16,16,0,0,1,48,64H80L96,40h64l16,24h32a16,16,0,0,1,16,16V192A16,16,0,0,1,208,208Z"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="16"
                      />

                      <circle
                        cx="128"
                        cy="132"
                        r="36"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="16"
                      />
                    </svg>
                </span>
              </div>
                  </label>

              <h5>{user.name}</h5>
              <p> UHID: {user.uhid}</p>
              {/* <button type="button" class="btn btn-primary "></button> */}
              <a
                class="btn btn-primary px-4"
                role="button"
                href="/"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={button1}
              >
                Edit Profile
              </a>

              <div
                class="modal"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={back3}
              >
                <div class=" modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content" style={{backgroundColor: back2?.backgroundColor}}>
                    <div class="modal-body" >
                      <form>
                        <div class="row text-start">
                          <div class="col-12 my-1">
                            <label for="firstname" class="form-label pt-2">
                              Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              placeholder = "Name"
                            />
                          </div>

                          <div class="col-6">
                            <label
                              for="formGroupExampleInput"
                              class="form-label pt-2"
                            >
                              Height (in cm)
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="formGroupExampleInput"
                              value={height}
                              onChange={(e) => setHeight(e.target.value)}
                              placeholder = "Height"
                            />
                          </div>
                          <div class="col-6">
                            <label
                              for="formGroupExampleInput2"
                              class="form-label pt-2"
                            >
                              Weight (in kg)
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="formGroupExampleInput2"
                              value={weight}
                              onChange={(e) => setWeight(e.target.value)}
                              placeholder = "Weight"
                            />
                          </div>
                          <div class="col-6 my-1">
                            <label
                              for="formGroupExampleInput"
                              class="form-label pt-2"
                            >
                              Date of Birth
                            </label>
                            <div class="input-group date" id="datepicker">
                              <input
                                type="date"
                                class="form-control"
                                id="date"
                                value={moment(dob).format("YYYY-MM-DD")}
                                onChange={(e) => setDob(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="col-6 my-1">
                            <label
                              for="formGroupExampleInput"
                              class="form-label pt-2"
                            >
                              Gender
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => setGender(e.target.value)}
                              value={gender}
                            >
                              <option selected>Select Gender</option>
                              <option value="MALE">Male</option>
                              <option value="FEMALE">Female</option>
                              <option value="OTHER">other</option>
                            </select>
                          </div>

                          <div class="col-6 my-1">
                            <label
                              for="formGroupExampleInput"
                              class="form-label pt-2"
                            >
                              Occupation
                            </label>
                            <div class="input-group date" id="datepicker">
                              <input
                                type="text"
                                class="form-control"
                                id="date"
                                value={occupation}
                                onChange={(e) => setOccupation(e.target.value)}
                                placeholder = "Occupation"
                              />
                            </div>
                          </div>
                          <div class="col-6 my-1">
                            <label
                              for="formGroupExampleInput"
                              class="form-label pt-2"
                            >
                              Marital status
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => setMaritalStatus(e.target.value)}
                              value={maritalstatus}
                            >
                              <option selected value="">Select Marital status</option>
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          <div class="col-12">
                            <label for="inputEmail4" class="form-label pt-2">
                              Email
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="inputEmail4"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder = "Email"
                            />
                          </div>

                          <div class="col-12 my-1">
                            <label for="inputAddress" class="form-label pt-2">
                              Address
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputAddress"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder = "Address"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer" style={back4}>
                      <button
                        type="button"
                        class="btn btn-secondary px-4"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>

                      {loadbutton ? 
                      
                      <button class="btn btn-primary" type="button" disabled>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                      
                      :

                      <button
                        type="button"
                        class="btn btn-primary px-4"
                        onClick={handleSubmit}
                        style={button1}
                      >
                        Update
                      </button>
                      
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section class="function mt-5 mx-auto" style={back3}>
              <div class="row mx-auto">
                    {dob?.length > 0 && 
                    <>
                <div class="col-lg-3 col-sm-3 col-xs-12 col-md-3 col-12">
                  <div class="row">
                    <div class="col-2">
                      {/* <img src="images/icon1.png" class="img-fluid" alt=""> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-calendar4-event my-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                        <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                    </div>
                    
                    <div class="col-10">
                      <p>
                        Date of Birth
                        <br />
                        {moment(dob).format("DD, MMM YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                    </>
                    }
                 
                <div class="col-lg-3 col-sm-3 col-xs-12 col-md-3 col-12 ">
                  <div class="row">
                    <div class="col-2">
                      {/* <img src="images/icon2.png" class="img-fluid " alt="">  */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-gender-male my-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                        />
                      </svg>
                    </div>
                    <div class="col-10">
                      <p>
                        Gender
                        <br />
                        {gender}
                      </p>
                    </div>
                  </div>
                </div>

                {height && (
                  <div class="col-lg-3 col-sm-3 col-xs-12 col-md-3 col-12 ">
                    <div class="row">
                      <div class="col-2">
                        {/* <img src="images/icon2.png" class="img-fluid " alt="">  */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-rulers my-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z" />
                        </svg>
                      </div>
                      <div class="col-10">
                        <p>
                          Height
                          <br />
                          {height}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {weight && (
                  <div class="col-lg-3 col-sm-3 col-xs-12 col-md-3 col-12 ">
                    <div class="row">
                      <div class="col-2">
                        {/* <img src="images/icon2.png" class="img-fluid " alt="">  */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="my-2"
                          width="22"
                          height="22"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M384 176c0 70.7-57.3 128-128 128s-128-57.3-128-128s57.3-128 128-128s128 57.3 128 128zm7.8-112C359.5 24.9 310.7 0 256 0S152.5 24.9 120.2 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H391.8zM296 224c0-10.6-4.1-20.2-10.9-27.4l33.6-78.3c3.5-8.1-.3-17.5-8.4-21s-17.5 .3-21 8.4L255.7 184c-22 .1-39.7 18-39.7 40c0 22.1 17.9 40 40 40s40-17.9 40-40z" />
                        </svg>
                      </div>
                      <div class="col-10">
                        <p>
                          Weight
                          <br />
                          {weight}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section class="my-4 mx-auto">
              <h5 class="text-center text-lg-start">Body Mass Index(BMI)</h5>

              <div class="text-center">
                <h5>{bmi}</h5>
                <p>{info}</p>
              </div>

              <div class="progress">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  aria-label="Segment one"
                  style={{ width: "33%" }}
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>

                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  aria-label="Segment two"
                  style={{ width: "33%" }}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  aria-label="Segment three"
                  style={{ width: "34%" }}
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="row   text-center ">
                <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 col-3 text-start">
                  <small>16.0</small>
                </div>
                <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 col-3">
                  <small>18.5</small>
                </div>
                <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 col-3">
                  <small>25.0</small>
                </div>
                <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 col-3">
                  <small>40.0</small>
                </div>
              </div>

              <div class="row   text-center">
                <div class="col-lg-4 col-sm-4 col-xs-4 col-md-4 col-4 ">
                  <p>Underweight</p>
                </div>
                <div class="col-lg-4 col-sm-4 col-xs-4 col-md-4 col-4 ">
                  <p>Normal</p>
                </div>
                <div class="col-lg-4 col-sm-4 col-xs-4 col-md-4 col-4 ">
                  <p>Obese</p>
                </div>
              </div>
            </section>
            <section class="mt-4 mx-auto">
              <div class="row">
                <div class="col-lg-6 col-sm-6 col-xs-12 col-md-6 col-12">
                  <div class="row">
                    <div class="col-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-telephone mt-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </div>
                    <div class="col-10">
                      <p>
                        Mobile Number
                        <br />
                        {mobileNumber}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-xs-12 col-md-6 col-12">
                  <div class="row">
                    <div class="col-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-envelope-open  mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                      </svg>
                    </div>
                    <div class="col-10">
                      <p>
                        Email
                        <br /> {email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {user?.address && (
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-xs-12 col-md-6 col-12 my-2">
                    <div class="row">
                      <div class="col-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-geo-alt mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </div>
                      <div class="col-10">
                        <p class="">
                          Address
                          <br class="fs-5" />
                          {address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {user?.maritalStatus && (
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-xs-12 col-md-6 col-12 my-2">
                    <div class="row">
                      <div class="col-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="my-2"
                          width="22"
                          height="22"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M64 208c0 7.8 4.4 18.7 17.1 30.3C126.5 214.1 188.9 200 256 200s129.5 14.1 174.9 38.3C443.6 226.7 448 215.8 448 208c0-12.3-10.8-32-47.9-50.6C364.9 139.8 314 128 256 128s-108.9 11.8-144.1 29.4C74.8 176 64 195.7 64 208zm192 40c-47 0-89.3 7.6-122.9 19.7C166.3 280.2 208.8 288 256 288s89.7-7.8 122.9-20.3C345.3 255.6 303 248 256 248zM0 208c0-49.6 39.4-85.8 83.3-107.8C129.1 77.3 190.3 64 256 64s126.9 13.3 172.7 36.2c43.9 22 83.3 58.2 83.3 107.8v96c0 49.6-39.4 85.8-83.3 107.8C382.9 434.7 321.7 448 256 448s-126.9-13.3-172.7-36.2C39.4 389.8 0 353.6 0 304V208z" />
                        </svg>
                      </div>
                      <div class="col-10">
                        <p class="">
                          Marital Status
                          <br class="fs-5" />
                          {maritalstatus}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {user?.occupation && (
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-xs-12 col-md-6 col-12 my-2">
                    <div class="row">
                      <div class="col-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="currentColor"
                          class="bi bi-bag-plus"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                          />
                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                        </svg>
                      </div>
                      <div class="col-10">
                        <p class="">
                          Occupation
                          <br class="fs-5" />
                          {occupation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default Profile;
