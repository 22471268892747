import Api from "../api";
import Api2 from "../api2";
import { map, concatMap } from "rxjs/operators";
import { timer } from "rxjs";

const DataService = {
  fetchList(baseUrl, page, size, filter, sort) {
    const obj = {
      size: size,
      page: page,
      filterModel: filter,
      sortModel: sort,
    };
    return Api.post(`${baseUrl}`, obj).pipe(
      map((response) => response["data"]["data"])
    );
  },
  fetchList2(baseUrl, page, size, filter, sort) {
    const obj = {
      size: size,
      page: page,
      filterModel: filter,
      sortModel: sort,
    };
    return Api2.post(`${baseUrl}`, obj).pipe(
      map((response) => response["data"]["data"])
    );
  },

  polledFetchList(baseUrl, page, size, filter, sort, interval) {
    const obj = {
      size: size,
      page: page,
      filterModel: filter,
      sortModel: sort,
    };
    return timer(0, interval).pipe(
      concatMap((_) => Api.post(`${baseUrl}`, obj)),
      map((response) => response["data"]["data"])
    );
  },

  polledFetchList2(baseUrl, page, size, filter, sort, interval) {
    const obj = {
      size: size,
      page: page,
      filterModel: filter,
      sortModel: sort,
    };
    return timer(0, interval).pipe(
      concatMap((_) => Api2.post(`${baseUrl}`, obj)),
      map((response) => response["data"]["data"])
    );
  },


  polledChatSession(baseUrl, interval) {
    return timer(0, interval).pipe(
      concatMap((_) => Api.get(`${baseUrl}`)),
      map((response) => response["data"]["data"])
    );
  },

  getChatSessions(baseUrl) {
    return Api.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getList(baseUrl) {
    return Api.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getList2(baseUrl) {
    return Api2.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },
  get(baseUrl) {
    return Api.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },
  searchData(data, baseUrl) {
    return Api.get(`${baseUrl}?search=${data}`).pipe(
      map((response) => response["data"])
    );
  },

  updateData(baseUrl, data) {
    return Api.put(`${baseUrl}`, data).pipe(
      map((response) => response["data"]["data"])
    );
  },

  // uploadImage(dir, slug, formData) {
  //   return Api.post(`file/upload/secure?dir=${dir}/${slug}`, formData).pipe(
  //     map((response) => response["data"]["data"])
  //   );
  // },

  uploadImage( formData) { 
    return Api2.post(`upload_temp_image`, formData).pipe(
      map((response) => (response["data"]["data"][0]))
      ); 
    },
    
  createData(data, baseUrl) {
    return Api.post(`${baseUrl}`, data).pipe(
      map((response) => response["data"])
    );
  },

  printLetterHead(appointmentSlug) {
    return Api.get(`appointment/generate-letterhead/${appointmentSlug}`).pipe(
      map((response)=> response["data"])
    )
  },

  printDoc(appointSlug) {
    return Api.get(`appointment/generate-pdf/by-doctor/${appointSlug}`).pipe(
      map((response) => response["data"])
    );
  },

  updateAppointment(baseUrl, data) {
    return Api.put(`${baseUrl}`, data).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getPatientAppointment(appointSlug) {
    return Api.get(`appointment/patient-appointment/${appointSlug}`).pipe(
      map((response) => (response["data"]))
    );
  },


  getprofile(appointSlug) {
    return Api2.get(`getprofile?id=${appointSlug}`).pipe(
      map((response) => response["data"]))
  },

  getspec() {
    return Api2.get(`getspeciality`).pipe(
      map((response) => response["data"]["data"])
    )
},

  printReceipt(appointSlug) {
    return Api.get(`appointment/generate-pdf/receipt/${appointSlug}`).pipe(
      map((response) => response["data"])
    );
  },

  previousPrescription(appointSlug) {
    return Api.get(`appointment/previous-prescription/${appointSlug}`).pipe(
      map((response) => response["data"])
    );
  },

  updateDoc(baseUrl, data) {
    return Api.put(`${baseUrl}`, data).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getAppointmentType(baseUrl) {
    return Api.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getPaymentModes(baseUrl) {
    return Api.get(`${baseUrl}`).pipe(
      map((response) => response["data"]["data"])
    );
  },

  loadMoreMessages(sessionSlug, currentPage) {
    return Api2.get(`/all/messages/${sessionSlug}?page=${currentPage}`).pipe(
      map((response) => response.data)
    );
  },
};

export default DataService;
