import React, { useState, useEffect, useRef } from 'react'
import Api2 from '../../utils/api2'
import { useHistory, useLocation } from "react-router-dom"
import Routes from '../../routes/routes'
import boypic from "../../../src/assets/userpic.png"

const CallingStaff = () => {
  const [patientData, setPatientData] = useState()
  const [countdown, setCountdown] = useState(15);


  const apiCallMade = useRef(false);



  let history = useHistory();

  useEffect(() => {
    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)
    const patientSlug = userObject?.slug;

    // Only make the API call if it hasn't been made before
    if (!apiCallMade.current) {
      Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
        if (resp) {
          console.log("test_rooms", resp?.data?.data);
          setPatientData(resp?.data?.data);
        }
      });

      // Mark that the API call has been made
      apiCallMade.current = true;
    }




    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);


    if (countdown === 0) {
      clearInterval(timer);
      
      history.push(Routes.ROOM)
    }

    return () => {
      clearInterval(timer);
    };


  }, [countdown])



  return (
    <section style={{ marginTop: "40px" }}>
      <div className='container'>
        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            {/* <div class="circle1">
              {
                patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
                ) : (
                  <img className='circle1pic' src={boypic} alt="" />
                )
              }



            </div> */}
            {/* <div class="text-white" style="border: 1px solid white; width: 60px; height: 60px; padding: 15px; border-radius: 50%; cursor: pointer;"> */}
            <div onClick={() => history.push(Routes.ROOM)} class="text-white" style={{ border: "1px solid white", width: "60px", height: "60px", padding: "15px", borderRadius: "50%", cursor: 'pointer' }}>
              <i class="fa fa-arrow-left"></i>
            </div>
          </div>
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <div class=" text-white text-end  ">
              <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
              <h6>{patientData?.patient?.uhid}</h6>
            </div>
          </div>
        </div>


        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
              patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                <>
                  {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                </>
              ) : null
            }
              <br />

              {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
              <br />{patientData?.patient?.allergies ? (<>
                Allergies:{patientData?.patient?.allergies}
              </>) : null}
            </p>
          </div>

        </div>


        <div class="row mt-4">
          <div class="col-12">
            <h1 class="text-center text-light calling m-0" style={{ fontSize: "5rem" }}>Calling <br /> Staff

            </h1>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <p class="text-center text-light ">Our staff will shortly be <br /> at your service</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <button onClick={() => history.push(Routes.ROOM)} class="btn btn-primary mt-3 w-40  text-white">
              Dashboard
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="text-center text-light " style={{ marginTop: "62px" }}>
              Returning automatically to dashboard in <br />{countdown} secs
            </p>
          </div>
        </div>


      </div>
    </section>
  )
}

export default CallingStaff