import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DataService from "../../utils/services/data-service-doctor";
import { Container, Box, Typography } from "@material-ui/core";
import AuthService from "../../utils/services/auth-service";
import { useLocation } from "react-router-dom";
import useGlobalStyles from "../../utils/global-style";
import Routes from "../../routes/routes";

import successIcon from "../../assets/green-check.svg";

const PymntCnfrm = () => {
  const gClasses = useGlobalStyles();
  const tenant = AuthService.getTenant();
  const location = useLocation();
  const history = useHistory();

  const fetchPaymentStatus = () => {
    const paymentSubscription = DataService.polledFetchPaymentStatus(
      `/chat-session/doc/my?docSlug=${location?.state}`,
      1000
    ).subscribe((resp) => {
      if (resp && resp.status === "ACTIVE") {

        history.replace(
          {
            pathname: `${Routes.CHAT}`,
            state: {
              docSlug: location?.state,
            }
          });          
      }
    });

    return () => paymentSubscription.unsubscribe();
  };

  useEffect(fetchPaymentStatus, []);

  return (
    <div
      className={clsx(gClasses.minHeightRoot, gClasses.width)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Container
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          style={{ flex: "1" }}
        >
          <img
            src={successIcon}
            alt="suceess icon"
            style={{ marginTop: "-100px" }}
          />
          <Typography variant="h5" style={{ marginTop: "27px", color: "#FFFFFF" }}>
            Thank You
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "18px", marginTop: "8px", color: "#FFFFFF" }}
          >
            Your chat request has been sent successfully
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#FFFFFF", marginTop: "10px" }}
          >
            You will receive an SMS shortly to confirm your booking after the
            doctor has accepted your request.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default PymntCnfrm;
