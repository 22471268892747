import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import AppConstants from '../../utils/app-constants';
import AuthService from '../../utils/services/auth-service';
import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util';

const Qrcode = (props) => {
  const [data, setData] = useState('');
  const location = useLocation();
  const token = AuthService.getToken();
  const history = useHistory();
  const [data1, setData1] = useState(false);
  const datacheck = location?.state?.checkIn;
 

  const redirectap = () =>{
    if(!AuthService.isAuthenticated){
      window.location.replace('/')
    }
  }
  redirectap();

  useEffect(()=>{

    const readit = async() =>{  
  
      
          if(data === datacheck){
            try {
              const response = await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_BASE_URL1}/appointment/status/${location.state.apSlug}/CHECKED_IN`,
                headers: {
                  "Content-Type": "multipart/form-data",
                  "X-AUTH-TOKEN": token,
                },
              });
              if(response) {
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CHECKEDIN);
                window.location.replace('/appointments');
              }
            } catch (error) {}
          }
  
    }

    readit();
  },[data])

  return (
    <>
    
      <div style={{marginTop: "90px"}}>
        <QrReader
          className='w-50 h-75 shadow rounded img-thumbnail mx-auto mt-5'
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
              // readit();
            }

            if (!!error) {
              // console.info(error);
            }
          }}
          constraints={{
            facingMode: 'environment'
          }}  
        />
       <div style={{
        marginTop: "25px",
        marginBottom: "25px",
        color: "white",
        fontWeight: "600",
        textAlign: "center"
        }}> Scan QR Code </div>
      </div>
      {/* <p className='text-center fs-5 bg-warning text-muted '>{data}</p> */}
    </>
  );
};

export default Qrcode;