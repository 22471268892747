import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style2";
import  ChatBubble  from "./chatbubble";
import clsx from "clsx";
import Loader from "../../components/layout/loader";
import ChatDisclaimer from "./chat-disclaimer";
import "./chat-c.css"
import { grey } from "@material-ui/core/colors";

const ChatBox = ({
  messages,
  currentSession,
  messagesEndRef,
  handleChatAction,
  user,
  msgLoaded,
  className,
  onAgreed,
  isAgreed,
}) => {
  const globalClass = useGlobalStyles();

  const handleImageClick =(val)=>{

  }

  return (
    <Paper
    style={{
      marginTop: "-1px"
    }}
      ref={messagesEndRef}
      className={clsx(
        messages.length === 0 && !isAgreed
          ? globalClass.inActiveBg
          : globalClass.whiteBg,
        globalClass.srollBar,
        className
      )}
      id = "chatbox1"
    >
      {messages.length === 0 && !isAgreed && (
        <ChatDisclaimer onAgreed={onAgreed} />
      )}
      {msgLoaded ? (
        messages.map((item, index) => (

        
            <ChatBubble
              left={user.slug !== item.fromUserSlug}
              user={currentSession && currentSession.doctor?.name}
              key={index}
              handleClick={handleChatAction}
              img={item?.attachment?.content ? item?.attachment?.content : item?.attachment?.contenttype && ` data:${item?.attachment?.contenttype};base64,${item?.attachment?.data?.data}`}
              messageType={item.type}
              contenttype={ item?.attachment?.contenttype }
              actions={item.actions}
              text={item.text}
              time={item.dateCreated}
              handleImageClick={handleImageClick}
            />

          

          ))
        ) : (
          null
        )}
    </Paper>
  );
};

export default ChatBox;
