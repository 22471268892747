import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import moment from "moment";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Routes from "../../routes/routes";
import { useEffect } from "react";
import ReportItem from "./report-item";
import Loader from "../../components/layout/loader";
import DataService from "../../utils/services/data-service-doctor";
import AuthService from "../../utils/services/auth-service";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { SampleContext } from "../../contexts/SampleContext";

const Report = () => {
  const gClasses = useGlobalStyles();
  let history = useHistory();
  const [reports, setReports] = useState(null);
  const [page, setPage] = useState(0);
  const user = AuthService.getUser();
  const [totalItems, setTotalItems] = useState(0);
  const { back1, back2, button1 } = useContext(SampleContext);
  const [loader, setloader] = useState(false);



  const fetchReports = () => {
    document.body.classList.add('container');
    setloader(true);
    const sho = [{
      "colId": "reportDate",
      "sort": "DESC"
    }];
    DataService.fetchList("report/search", page, 10, {
      patientSlug: { values: [user.slug], filterType: "set" },
    }, sho).subscribe((resp) => {
      setReports((prev) => [...(prev || []), ...resp.content]);
      setTotalItems(resp.totalElements);
      setloader(false);
    });
  };

  useEffect(fetchReports, [page]);

  return (
    <div className="container" style={back1}>
      <div className="row overflow-hidden mx-auto " style={{ "marginTop": "90px" }}>
        <main className="col-md-12 mx-auto " style={back1}>
          <div className="d-grid gap-3 mb-4">
            <div className="d-flex justify-content-between mb-4 p-2">
              <div style={{ cursor: "pointer" }}>
                <div style={back1} onClick={() => { history.goBack(); }}>
                  <span className="me-2">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  <span>Report</span>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    history.push(Routes.ADD_REPORT);
                  }}
                  style={button1}
                >
                  Add
                </button>
              </div>
            </div>

            {reports ? (
              reports.length === 0 ? (
                <Typography
                  variant="body2"
                  align="center"
                  style={{ margin: "1rem", marginTop: "2rem" }}
                >
                  No reports to show.
                </Typography>
              ) : (
                reports.map((item, index) => {
                  return (
                    <>
                    <div className="p-2 rounded-3  back2 d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="d-flex align-items-center m-1">
                          {/* <svg className='logo_img' height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" ><path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z" /></svg> */}
                          <svg
                            className="logo_img"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                          >
                            <path d="M152 352C138.7 352 128 341.3 128 328C128 314.7 138.7 304 152 304H296C309.3 304 320 314.7 320 328C320 341.3 309.3 352 296 352H152zM128 0C141.3 0 152 10.75 152 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0zM400 192H48V448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192z" />
                          </svg>
                        </div>

                        <div className="ps-2">
                          {item.reportName}
                          <br />{" "}
                          {moment(item.reportDate).format("dddd Do, MMMM YYYY")}
                        </div>
                      </div>
                      <div className="my-auto">
                        <button
                          type="button"
                          className="btn btn-secondary  btn-sm  "
                          onClick={() => history.push(Routes.VIEW_REPORT, item)}
                          style={button1}
                        >
                          View
                        </button>
                      </div>
                    </div>
                      <hr style={{ borderTop: "dotted 1px" }} />
                      </>
                  );
                })
              )
            ) : (
              <Loader />
            )}
          </div>

          {reports && reports?.length !== 0 && (
            <>
              {loader ? (
                <div className="text-center mb-2">
                  <button class="btn btn-primary" type="button" disabled style={button1}>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                </div>
              ) : (
                <div className="text-center mb-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setPage((page) => page + 1);
                    }}
                    style={button1}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Report;
