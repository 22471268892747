import React, { createContext, useState } from 'react';
import AuthService from '../utils/auth-service';
export const SampleContext = createContext()


const SampleContextProvider = (props) => {

  const tenant = AuthService.getTenant();

  const type = "primary";
  if(tenant?.current_theme == type){
    var back1 = {
      color: tenant?.theme_light_2,
      backgroundColor: tenant?.theme_light_1,
    };
    var back2 = {
      color: tenant?.theme_light_4,
      backgroundColor: tenant?.theme_light_3,
    };
    var button1 = {
      color: tenant?.theme_light_6,
      backgroundColor: tenant?.theme_light_5,
      border: "none"
    };
  }
  else{
    var back1 = {
      color: tenant?.theme_dark_2,
      backgroundColor: tenant?.theme_dark_1,
    };
    var back2 = {
      color: tenant?.theme_dark_4,
      backgroundColor: tenant?.theme_dark_3,
    };
    var button1 = {
      color: tenant?.theme_dark_6,
      backgroundColor: tenant?.theme_dark_5,
      border: "none"
    }
  }

  if(tenant?.current_theme == type){
    var back3 = {
      color: tenant?.theme_light_2,
      backgroundColor: tenant?.theme_light_1
    };
    var back4 = {
      color: tenant?.theme_light_4,
      backgroundColor: tenant?.theme_light_3
    };
  }
  else{
    var back1 = {
      color: tenant?.theme_dark_2,
      backgroundColor: tenant?.theme_dark_1,
    };
    var back2 = {
      color: tenant?.theme_dark_4,
      backgroundColor: tenant?.theme_dark_3,
    };
  }


    return (
         <SampleContext.Provider 
            value={{
                back1,
                back2,
                back3,
                back4,
                button1
             }}>
               {props.children}
         </SampleContext.Provider>
    )
}
export default SampleContextProvider;