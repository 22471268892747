import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import axios from "axios";
import { Form } from "informed";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  makeStyles,
  Container
} from "@material-ui/core";

import { SampleContext } from "../../contexts/SampleContext";
import Api2 from "../../utils/api2";
import AuthService from "../../utils/services/auth-service";
import useGlobalStyles from "../../utils/global-style";
import AppConstants from "../../utils/app-constants";
import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import Routes from "../../routes/routes";
import { QRCodeSVG } from "qrcode.react";
import { isRequired } from "../../utils/validations";
import DataService from "../../utils/services/data-service-doctor";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";

const useStyles = makeStyles((theme) => ({
  pckgDetailsCard: {
    padding: "15px",
    borderRadius: "8px",
  },

  pymntCard: {
    padding: "8px 15px",
    marginBottom: "8px",
    borderRadius: "8px",
  },

  pymtnRadioInp: {
    position: "absolute",
    left: "-999999px",

    "& + label > div": {
      border: ".5px solid transparent",
    },

    "&:checked + label": {
      "& > div": {
        borderColor: "blue",
        boxShadow:
          "0px 3px 3px -2px rgba(81, 203, 238,1), 0px 3px 4px 0px rgba(81, 203, 238,1), 0px 1px 8px 0px rgba(81, 203, 238,0.6)",
      },

      "& .checkmark": {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  pymntRadioGrp: {
    position: "relative",

    "& .checkmark": {
      display: "inline-block",
      height: "15px",
      width: "15px",
      borderRadius: "30px",
      backgroundColor: "#fff",
      border: "1px solid #333",
    },
  },

  pymntIconGrp: {
    marginLeft: "12px",
    "& img": {
      maxWidth: "70px",
      marginBottom: "5px",
    },
  },
}));

const PackageDetails = ({ location }) => {
  const { orderId, docSlug, chatPackage, razorpayOrderId } = (location || {}).state || {};
  const { back1, back2, button1 } = useContext(SampleContext);

  const classes = useStyles();
  const gClasses = useGlobalStyles();

  const history = useHistory();
  const [paymentModes, setPaymentModes] = useState(null);
  const [paymentId, setPaymentId] = useState();

  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const tenant = AuthService.getTenant();
  const user = AuthService.getUser();
  const handlePaymentModeChange = (paymentMode) => {
    setSelectedPaymentMode(paymentMode);
  };

  const ur1 = `${process.env.REACT_APP_API_BASE_URL}/get_doctor_payment_options?id=${docSlug}`;
  const getdata1 = () => {
    axios.get(`${ur1}`).then((resp2) => {
      setPaymentModes(resp2.data.paymentOptions);
      // setLoad(false);
    });
  };

  const payit = (val) =>{
    setPaymentId(val);
  }

  useEffect(getdata1, []);
  const formApi = useRef();

  const RazorPay = async () => {
    console.log('razorpay key', process.env.REACT_APP_RAZOR_PAY_KEY)
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: `${chatPackage?.amount * 100}`,
      currency: "INR",
      name: tenant?.title ?? '',
      order_id: razorpayOrderId,
      handler: function (response) {
        handleSubmit(response.razorpay_payment_id);
      },
      theme: {
        color: back1.backgroundColor,
      },
      prefill: {
        name: `${user?.name}`,
        email: `${user?.email}`,
        contact: `${user?.mobileNumber}`,
      },
      notes: {
        name: tenant?.title,
        customer: {
          name: `${user?.name}`,
          email: `${user?.email}`,
          contact: `${user?.mobileNumber}`,
        }
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();

    razor.on("payment.failed", function (response) {
      SHOW_ERROR_NOTIFICATION(response.error.description);
    });
  };

  const [signatureDataImg, setSignatureDataImg] = useState([]);
  const [signatureImg, setSignatureImg] = useState("");
  const handleInputs = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setSignatureImg(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSignatureDataImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = (values) => {
    let formData = new FormData();
    formData.append("files", signatureImg);
    if ( selectedPaymentMode === null){
      SHOW_ERROR_NOTIFICATION("Choose Payment Mode");
    }
    else if(selectedPaymentMode?.paymentModeName === "UPI" && (signatureImg.length === 0 || signatureImg === "")) {
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.PAYMENT_SCREENSHOT);
    }
    else{
      if(selectedPaymentMode?.paymentModeName === "UPI") {
        DataService.uploadImage(formData).subscribe(
          (resp) => {
            const paymentDto = {
              mode: selectedPaymentMode?.paymentMode,
              amount: chatPackage.amount,
              imageId: resp[0]?.imageId
            };
    
            Api2.put(`/order/payment/${orderId}`, paymentDto).subscribe((resp) => {
              const resObj = resp.data || {};
        
              if (resp) {
                formApi.current.reset();
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.PAYMENT_DONE);
                history.push({
                  pathname: Routes.PYMNT_CNFRM,
                  state: docSlug
                });
              }
            });
  
          },
          (error) => {
            DEFAULT_ERROR_CALLBACK(error);
          }
        );
      } else {
        const paymentDto = {
          mode: selectedPaymentMode?.paymentMode,
          amount: chatPackage.amount,
        };

        Api2.put(`/order/payment/${orderId}`, paymentDto).subscribe((resp) => {
          const resObj = resp.data || {};
    
          if (resp) {
            formApi.current.reset();
            SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.PAYMENT_DONE);
            history.push({
              pathname: Routes.PYMNT_CNFRM,
              state: docSlug
            });
          }
        });
      }
    }
  };

  return (
    <div
      className={clsx(gClasses.minHeightRoot, gClasses.width)}
      style={{ marginTop: "90px" }}
    >
      <Container>
        <Box my={2}>
          <Typography component="h4" variant="h6" style={back1}>
            Package Details
          </Typography>
        </Box>
        <Paper elevation={3} className={classes.pckgDetailsCard} style={back2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="subtitle1" style={back2}>
              <strong>{chatPackage.packageName}</strong>
            </Typography>

            <Typography variant="body2" style={back2}>
              INR <strong>{chatPackage.amount}</strong>
            </Typography>
          </Box>
          <Typography variant="body2">
            Total Questions: {chatPackage.totalQuestions}
          </Typography>
          <Typography variant="body2">
            Total Days: {chatPackage.totalDays}
          </Typography>
        </Paper>
        {/* 660575cd04b77146ca19bae8 -> payment type id for Online (Razorpay) */}
        <Form
          onSubmit={selectedPaymentMode?.paymentModeName === "Online" ? RazorPay : handleSubmit}
          apiRef={formApi}
        >
          {({ formState }) => (
            <>
              <div className="text-start my-2 mt-5" style={back1}>
                Choose Payment Mode
              </div>
              <hr style={{ borderTop: "1px dotted" }} />
              <div className="d-grid gap-3 mb-4 mx-3">
                <div className="ps-3  rounded-3 row" style={back1}>
                  <div
                    className="accordion accordion-flush p-0"
                    id="accordionFlushExample"
                    style={back1}
                  >
                    {paymentModes
                      ?.filter(
                        (paymentMode) =>
                          paymentMode?.status === true ||
                          paymentMode?.status === undefined
                      )
                      .map((paymentMode, index) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            justifyContent="center"
                            key={index}
                            direction="column"
                          >
                            <Grid item>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item direction="row">
                                  <input
                                    type="radio"
                                    checked={
                                      paymentMode?._id ===
                                      selectedPaymentMode?._id
                                    }
                                    onChange={(event) => {        
                                      handlePaymentModeChange(
                                        paymentModes.find(pMode => pMode?._id === event.currentTarget.value)
                                      );
                                      payit(paymentModes.find(pMode => pMode?._id === event.currentTarget.value));
                                    }}
                                    name="paymentMode"
                                    value={paymentMode?._id}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography variant="h6">
                                        {paymentMode?.paymentModeName === 'UPI' ? `${paymentMode?.paymentModeName} - ${paymentMode?.description}` : paymentMode?.paymentModeName}
                                    </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* TO SHOW QR CODE FOR UPI */}
                            {/* Payment Mode ID for UPI ->635ba3683682b440056a3506 */}
                            {(paymentMode?.paymentModeName === 'UPI' && paymentMode?._id ===
                                    selectedPaymentMode?._id) ? <Grid item>
                              <Grid container justifyContent="center">
                                <Grid item>
                                  <Grid item>
                                    <QRCodeSVG
                                      id="upi-id-qr"
                                      value={`upi://pay?pa=${paymentMode?.description}&am=${chatPackage?.amount}&cu=INR`}
                                      size={200}
                                      width={"100%"}
                                      level={"H"}
                                      includeMargin={false}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid> : null}
                          </Grid>
                        );
                      })}
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              {selectedPaymentMode?.paymentModeName === "UPI" ? <div className="d-grid gap-2 back1" id="screenshot">
                  <div class="col-12">
                    <input
                      type="file"
                      name="signature"
                      class="form-control"
                      id="signature"
                      autocomplete="off"
                      style={{ visibility: "hidden" }}
                      onChange={handleInputs}
                      field="paym"
                      validate={isRequired}
                      validateOnMount
                      validateOnChange
                    />
                    <div>
                      <img
                        src={signatureDataImg}
                        style={{ width: "160px", marginBottom: "20px" }}
                      />
                    </div>
                    <label htmlFor="signature" id="screenbutn">
                      <div class="file btn btn-primary" style={button1}>
                        Upload Payment Screenshot
                      </div>
                    </label>
                  </div>
                  <br />
                  &nbsp;
                </div> : null}

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={button1}
                  >
                    {selectedPaymentMode?.paymentModeName === "Online"
                      ? "Proceed to Payment"
                      : "Submit"}
                  </Button>
                </Box>
            </>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default PackageDetails;
