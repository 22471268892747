const Routes = {
  REGISTER: "/register",
  LOGIN: "/login",
  LOGOUT: "/logout",
  CALLBACK: "/home/callback",
  HOME: "/",
  CATEGORY: "/category",
  DOCTOR: "/doctors",
  DOC_PROFILE: "/:id",
  MAIN: "/app",
  RESET_PASSWORD: "/reset-password",
  APPOINTMENT: "/app/appointment",
  APPOINTMENT_LIST: "/appointments",
  ROOM: "/room" ,
  CALLINGSTAFF:"/callingStaff",
  CALLHOUSEKEEPIN:"/callinghousekeepin",
  MYMEDICATION:"/myMedication",
  REQUESTDOCTORVISIT:"/requestdoctorvisit",
  VITALS_MEASUREMENT:"/vitalsmeasurement",
  INPUT_OUTPUT_MONITORING:"/inputOutputMonitoring",
  VIEW_IO_CHART:"/viewIochart",
  FEEDBACK:"/feedback",
  BOOK_APPOINTMENT: "/app/appointment/book",
  CHAT_HISTORY: "/app/chat-history",
  CHAT: "/app/chat",
  PROFILE: "/profile",
  DOC_APPOINTMENT: "/appointment/:id",
  BOOK_APPOINTMENT_PYMNT: "/payment/:id",
  BOOK_APPOINTMENT_SUCCESS: "/app/appointment/success",
  REPORT: "/report",
  ADD_REPORT: "/report/add",
  VIEW_REPORT: "/report/view",
  PRES: "/pres",
  CHAT_SUB: "/home/chat/subscription",
  RECEIPT: "/receipt",
  PCKG_DTLS: "/home/pckg-details",
  PYMNT_CNFRM: "/app/pymnt-cnfrm"
};

export default Routes;
