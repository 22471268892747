import React, { useContext,useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import { SampleContext } from "../../contexts/SampleContext";
import AgreeDialog from "../../screens/home/agree";
import AuthService from "../../utils/services/auth-service";

const Sidebutton = ({ doctor, AuthService, profile, docid, docterm }) => {
  const {back1, back2, button1} = useContext(SampleContext)
  const history = useHistory();
  const [open, setOpen] = useState(false);


  var back5 = {};
  back5.cursor = "pointer";
  back5.color = back1.color;
  back5.backgroundColor = back1.backgroundColor;

  const onDisclaimerAgreed = () => {
    setOpen(false);
    const val = doctor?.username ? doctor?.username : doctor?.slug
    if(AuthService.isAuthenticated) {
      history.replace(`/appointment` + `/${val}`);
    } else {
      history.replace(`/login`);
    }
  };

  const clickLogin = () => {
    const val = doctor?.username ? doctor?.username : doctor?.slug
    history.push({
      pathname: Routes.LOGIN,
      state: val
    });
  };

  const clickLog = () => {
    history.push(Routes.LOGOUT);
  };

  const clickChat = () => {
    history.push({
      pathname: Routes.CHAT,
      state: {
        docSlug: docid,
      },
    });
  };

  const clickAppointment = () => {
    history.push(Routes.APPOINTMENT_LIST);
  };

  const clickReport = () => {
    history.push(Routes.REPORT);
  };

  const clickProfile = () => {
    history.push(Routes.PROFILE);
  };

  return (
    <nav
      id="sidebarMenu"
      className="col-lg-3 col-md-9 col-sm-3 d-sm-none d-md-block bg-transparent  sidebar collapse"
    >
      <div className="position-sticky">
        <ul className="nav flex-column" >
          {profile && (
            <li className="nav-item text-center" >
              <img
                src={doctor?.profilePic ? doctor?.profilePic : `images/user.png`}
                className="img-fluid"
                alt="image"
                style={{ height: "160px", width: "160px", borderRadius: "50%" }}
              />
              <br />
              <br />
            </li>
          )}

          {!AuthService.isAuthenticated && (
            <>
            <li className="nav-item" >
              <div
                className="nav-link"
                aria-current="page"
                onClick={clickLogin}
                style={back5}
              >
                <i
                  className="fa fa-sign-in"
                  style={{ color: "gray", "margin-right": "12px" }}
                  aria-hidden="true"
                ></i>
                Login
              </div>
            </li>
             <li className="nav-item" >
             <div
               className="nav-link "
               aria-current="page"
               style={back5}
             >
               <div className="my-2">
                 <button
                   type="button"
                   className="btn btn-primary"
                   style={button1}
                   onClick={clickLogin}
                 >
                   Book Appointment
                 </button>
               </div>
             </div>
           </li>
           </>
          )}
          
          {AuthService.isAuthenticated && (
            <>
             {profile && (
              <>
              <li className="nav-item" >
                <div
                  className="nav-link "
                  aria-current="page"
                  style={back5}
                >
                  <div className="my-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={button1}
                      onClick={e=>setOpen(true)}
                    >
                      Book Appointment
                    </button>
                  </div>
                </div>
              </li>

                <AgreeDialog
                  open={open}
                  onDisclaimerAgreed={onDisclaimerAgreed}
                  disclaimer={docterm}
                />

              <li className="nav-item" >
                <div
                  className="nav-link "
                  aria-current="page"
                  onClick={clickChat}
                  style={back5}
                >
                  <img
                    src="images/chat.png"
                    className="img-fluid px-2"
                    alt="image"
                  />
                  Chat
                </div>
              </li>
                      </>  )}

              <li className="nav-item py-1">
                <div className="nav-link" onClick={clickReport} style={back5}>
                  <img
                    src="images/report.png"
                    className="img-fluid px-2"
                    alt="image"
                  />
                  Reports
                </div>
              </li>
              <li className="nav-item active py-1">
                <div className="nav-link" onClick={clickAppointment} style={back5}>
                  <img
                    src="images/appoint.png"
                    className="img-fluid px-2"
                    alt="image"
                  />
                  My Appointments
                </div>
              </li>

              {/* COMMENTED as it was falling back to appointment only, no functionality linked to it. */}
              {/* <li className="nav-item active py-1">
                <div className="nav-link" onClick={clickAppointment} style={back5}>
                <img
                        src="/images/Vector1.png"
                        className="img-fluid px-2"
                        alt="image"
                      />
                  Prescription
                </div>
              </li> */}

              <li className="nav-item py-1">
                <a className="nav-link" onClick={clickProfile} style={back5}>
                  <img
                    src="images/Group.png"
                    className="img-fluid px-2"
                    alt="image"
                  />
                  My Profile
                </a>
              </li>

              {doctor?.feedback && (
                <li className="nav-item py-1">
                  <div className="nav-link" style={back5}>
                    <img
                      src="images/feed.png"
                      className="img-fluid px-2"
                      alt="image"
                    />
                    <a href={`${doctor?.feedback}`} style={{textDecoration: "none", color: "inherit"}} target="_blank">
                      Feedback   
                    </a>
                  </div>
                </li>
              )}

              {doctor?.custommenu?.map((m, index) => {
                return (
                  <>
                    <li className="nav-item">
                      <div
                        className="nav-link"
                        aria-current="page"
                        style={back5}
                      >
                        <img
                          src="images/chat.png"
                          className="img-fluid px-2"
                          alt="image"
                        />
                        <a href={`${m.url}`} style={{textDecoration: "none", color: "inherit"}} target="_blank">
                          {m.link}
                        </a>
                      </div>
                    </li>
                  </>
                );
              })}

              <li className="nav-item py-1">
                <div className="nav-link" onClick={clickLog} style={back5}>
                  <img
                    src="images/log.png"
                    className="img-fluid px-2"
                    alt="image"
                  />
                  Logout
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebutton;
