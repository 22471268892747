import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import Routes from "../../routes/routes";
import Sidebar from "../../components/layout/sidebar";
import { SidebarContext } from "sixsprints-react-ui";
import Category from "./category";
import Home from "./home";
import Doctor from "./doctor";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import AuthService from "../../utils/services/auth-service";
import Callback from "../../components/callback";
import DoctorProfile from "./doctor-profile";
import DoctorAppointment from "./doctor-appointment";
import Login from "../login/login-page"
import Logout from "../logout"
import PurchaseAppointment from "./purchase-appointment"
import Profile from "../home/profile"
import Report from "../report/report"
import AddReport from "../report/add-report"
import EditReport from "../report/edit-report"
import Appointments from "../home/appointments"
import Chat from "../chat/chat";
import Qrcode from "./qrreader";
import Pres from "./pres";
import ChatSubscription from "../chat/chat-subscription";
import Receipt from "./receipt";
import PackageDetails from "../package-details/package-details";
import room from "../room/room";
import CallingStaff from "../room/callingStaff";
import Callhousekeepin from "../room/callhousekeepin";
import MyMedication from "../room/myMedication";
import RequestDoctorvisit from "../room/requestDoctorvisit";
import Room from "../room/room";
import InputOutputMonitor from "../room/inputOutputMonitor";
import ViewIochart from "../room/viewIochart";
import Feedback from "../room/feedback";
import VitalsMeasurement from "../room/vitalsMeasurement";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    backgroundColor: theme.palette.bg,
  },

  content: {
    height: "100%",
  },
}));

const HomeContainer = () => {
  const tenant = AuthService.getTenant();
  const classes = useStyles();

  return (
    <>



      <Route
        render={({ location }) =>
          location.pathname == `${Routes.LOGIN}` || location.pathname == `/pres` || location.pathname == `/receipt` ? <></> : <><Header tenant={tenant} title="Home" />

          </>
        }
      />

      <main className={classes.content}>
        <Switch>

          <Route exact path={Routes.HOME} component={Home} />
          <Route exact path={Routes.CATEGORY} component={Category} />
          <Route exact path={Routes.RECEIPT} component={Receipt} />
          <Route exact path={Routes.PRES} component={Pres} />
          <Route exact path={Routes.DOCTOR} component={Doctor} />
          <Route exact path={Routes.REPORT} component={Report} />
          <Route exact path={"/reader"} component={Qrcode} />
          <Route exact path={"/profile"} component={Profile} />
          <Route exact path={Routes.ROOM} component={Room} />
          <Route exact path={Routes.CALLINGSTAFF} component={CallingStaff} />
          <Route exact path={Routes.CALLHOUSEKEEPIN} component={Callhousekeepin} />
          <Route exact path={Routes.MYMEDICATION} component={MyMedication} />
          <Route exact path={Routes.REQUESTDOCTORVISIT} component={RequestDoctorvisit} />
          <Route exact path={Routes.VITALS_MEASUREMENT} component={VitalsMeasurement} />
          <Route exact path={Routes.INPUT_OUTPUT_MONITORING} component={InputOutputMonitor} />
          <Route exact path={Routes.VIEW_IO_CHART} component={ViewIochart} />
          <Route exact path={Routes.FEEDBACK} component={Feedback} />
          
          <Route exact path={Routes.ADD_REPORT} component={AddReport} />
          <Route exact path={Routes.VIEW_REPORT} component={EditReport} />
          <Route exact path={Routes.APPOINTMENT_LIST} component={Appointments} />
          <Route exact path={Routes.CALLBACK} component={Callback} />
          <Route exact path={Routes.LOGIN} component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path={Routes.DOC_PROFILE} component={DoctorProfile} />
          <Route exact path={Routes.BOOK_APPOINTMENT_PYMNT} component={PurchaseAppointment} />
          <Route exact path={Routes.DOC_APPOINTMENT} component={DoctorAppointment} />
          <Route exact path={Routes.CHAT_SUB} component={ChatSubscription} />
          <Route exact path={Routes.PCKG_DTLS} component={PackageDetails} />


          <Redirect to={Routes.HOME} />
        </Switch>
      </main>
      <Route
        render={({ location }) =>
          location.pathname == `${Routes.LOGIN}` || location.pathname == `${Routes.PRES}` || location.pathname == `/receipt` ? <></> : <Footer />
        }
      />


    </>
  );
};

export default HomeContainer;
