import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

import noResultImg from "../../assets/no-result-found.svg";

const useStyles = makeStyles((theme) => ({
  notFound: {
    width: "60px",
    margin: "80px auto 0 auto",
  },
}));

const NoResult = () => {
  const classes = useStyles();

  return (
    <Box mt={5} margin="0 auto" textAlign="center">
      <img alt="not found" className={classes.notFound} src={noResultImg} />
      <Typography variant="body1" align="center">
        No Results Found
      </Typography>
    </Box>
  );
};

export default NoResult;
