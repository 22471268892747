import React, { useState, useEffect, useRef } from 'react'
import Api2 from '../../utils/api2'
// import boypic from "../../../src/assets/boy.png"
import boypic from "../../../src/assets/userpic.png"

import { useHistory, useLocation } from "react-router-dom"
import Routes from '../../routes/routes'

const MyMedication = () => {

  const [patientData, setPatientData] = useState()
  const [medicationsdata, setmedicationdata] = useState([])




  let history = useHistory();







  useEffect(() => {

    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)


    const patientSlug = userObject?.slug;

    // Only make the API call if it hasn't been made before

    Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
      if (resp) {
        console.log("test_rooms", resp?.data?.data?.medications);
        setPatientData(resp?.data?.data);
        setmedicationdata(resp?.data?.data?.medications)
      }
    });







  }, [])




  return (
    <section style={{ marginTop: "40px" }}>
      <div className='container'>
        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            {/* <div class="circle1">
            {
              patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
              ) : (
                <img className='circle1pic' src={boypic} alt="" />
              )
            }



          </div> */}
            {/* <div class="text-white" style="border: 1px solid white; width: 60px; height: 60px; padding: 15px; border-radius: 50%; cursor: pointer;"> */}
            <div onClick={() => history.push(Routes.ROOM)} class="text-white" style={{ border: "1px solid white", width: "60px", height: "60px", padding: "15px", borderRadius: "50%", cursor: 'pointer' }}>
              <i class="fa fa-arrow-left"></i>
            </div>
          </div>
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <div class=" text-white text-end  ">
              <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
              <h6>{patientData?.patient?.uhid}</h6>
            </div>
          </div>
        </div>


        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
              patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                <>
                  {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                </>
              ) : null
            }
              <br />

              {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
              <br />{patientData?.patient?.allergies ? (<>
                Allergies:{patientData?.patient?.allergies}
              </>) : null}
            </p>
          </div>

        </div>


        <div class="row">
          <h1 class="mt-4 text-light">
            My Medications
          </h1>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12">
            <table class="table table-bordered text-light">
              <thead>
                <tr>

                  <th scope="col">S.N</th>
                  <th scope="col">Name</th>


                  <th scope="col">Interval</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Remarks</th>
                </tr>
              </thead>

              <tbody>
                {
                  Array.isArray(medicationsdata) && medicationsdata?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item?.name}&nbsp; {item?.dose}</td>
                        <td>{item?.frequency}</td>
                        <td>{`${item?.num} ${item?.interval}`}</td>
                        <td>{item?.remarks}</td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
        </div>





      </div>
    </section>
  )
}

export default MyMedication