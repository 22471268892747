import React, { useState, useRef, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import Api from "../../utils/api";
import AuthService from "../../utils/services/auth-service";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import AppConstants from "../../utils/app-constants";
import { isRequired } from "../../utils/validations";

import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { SampleContext } from "../../contexts/SampleContext";

const AddReport = () => {
  const gClasses = useGlobalStyles();
  const user = AuthService.getUser();
  const [isLoading, setIsLoading] = useState(false);
  const reportName = useRef(null);

  const formApi = useRef();
  let history = useHistory();
  const [uimages, setImages] = useState([]);

  const [buttontext, setbuttontext] = useState("");
  const [fuimages, setfImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);

  const [report, setReport] = useState();
  const [value, setValue] = useState();
  const [date, setDate] = useState();
  const [fileu, setFileU] = useState();
  const [doctorReport, setDoctorReport] = useState(true);
  const [allDoc, setAllDoc] = useState();
  const [docslug, setDocSlug] = useState();
  const { back1, back2, button1 } = useContext(SampleContext);
  const [reportD, setReportD] = useState();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (
      report?.length > 0 &&
      value?.length > 0 &&
      uimages?.length > 0 &&
      reportD != undefined
    ) {
      setDoctorReport(false);
    } else {
      setDoctorReport(true);
    }
  });

  useEffect(() => {
    if (uimages.length < 1) return;

    // const newImageUrls: any = [];

    // uimages.forEach((image: any) =>
    //   newImageUrls.push(URL.createObjectURL(image))
    // );

    var hashImageUrls = [];

    uimages.forEach((image) => {
      var hash1 = { image: URL.createObjectURL(image), type: image.type };

      hashImageUrls.push(hash1);
    });

    setImageURLs(hashImageUrls);
  }, [uimages]);

  // function onImageChange(e) {
  //   handleUploadFiles(Array.prototype.slice.call(e.target.files));
  //   setImages([...e.target.files]);
  // }

  function onImageChange(e) {
    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only PDF, JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    handleUploadFiles(files);
    setImages([...e.target.files]);
  }

  const handleUploadFiles = (files) => {
    const uploaded = [...fuimages];
    files.some((file) => {
      uploaded.push(file);
    });
    setfImages(uploaded);
  };

  const onTypeSelected = (e) => {
    setDocSlug(e.target.value);
  };

  useEffect(() => {
    Api.get(`getdoctor?forPatient=${user.slug}`).subscribe(
      (resp) => {
        setAllDoc(resp?.data);
      },
      (error) => {}
    );
  }, []);

  const handleSubmit = async (val) => {
    setloader(true);
    setDocSlug();
    val.preventDefault();

    if (!reportD || isNaN(reportD)) {
      SHOW_ERROR_NOTIFICATION("Add a valid date");
      setloader(false);
      return;
    }

    const formData = new FormData();
    if (uimages.length > 0) {
      for (let i = 0; i < uimages.length; i++) {
        formData.append("files", uimages[i]);
      }
    } else {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FILE_MSG);
      setloader(false);
      return;
    }
    formData.append("action", "SAVE");
    formData.append("patientSlug", user.slug);
    formData.append("reportDate", reportD);
    formData.append("reportName", report);
    formData.append("value", value);
    if (docslug?.length > 0) {
      formData.append("withMessage", true);
      formData.append("doctorSlug", docslug);
    }

    const token = AuthService.getToken();
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL1}/report/add`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response) {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.REPORT_ADD_SUCESS);
        window.location.replace("/report");
        setloader(false);
      }
    } catch (error) {
      SHOW_ERROR_NOTIFICATION("Can not add report");
      setloader(false);
    }
  };

  return (
    <div className="row w-100" style={back1}>
      <div className="col-md-12 mx-auto" style={{ marginTop: "80px" }}>
        <div className="">
          <div className="d-grid gap-2 back1 ps-5">
            <div style={{ cursor: "pointer" }}>
              <div
                style={back1}
                onClick={() => {
                  history.goBack();
                }}
              >
                <span className="me-2">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                <span>Report</span>
              </div>
            </div>
            <div className="col-12">
              <input
                type="file"
                className="form-control"
                id="signature"
                autocomplete="off"
                name="paym[]"
                validate={isRequired}
                validateOnMount
                validateOnChange
                multiple
                onChange={onImageChange}
                style={{ visibility: "hidden" }}
              />

              <Box mt={2}>
                <Grid container spacing={3}>
                  {imageURLS.map((imageSrc, index) => (
                    <Grid key={index} item xs={12} sm={3}>
                      <Box
                        mb={2}
                        p={2}
                        textAlign="center"
                        style={{ backgroundColor: "white" }}
                      >
                        <a
                          href={imageSrc.image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {imageSrc.type == "application/pdf" ? (
                            <>
                              <img
                                alt="report"
                                height={150}
                                width={150}
                                src="/pdf.png"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                alt="report"
                                height={150}
                                width={150}
                                src={imageSrc.image}
                              />
                            </>
                          )}
                        </a>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <label htmlFor="signature">
                <div className=" btn btn-primary fileupbutton" style={button1}>
                  Select files to Upload
                </div>
              </label>
              <div className="mt-2">*Supported formats png/jpg/pdf.</div>
            </div>
          </div>

          <div className="delete  row mb-2">
            <div
              className="col-md-3 d-flex align-items-center mb-2"
              style={{ gap: "50px" }}
            >
              <img
                className="  ps-5"
                src="./images/hos.png"
                alt=""
                style={{ maxWidth: "223px" }}
              />
            </div>
          </div>

          <form className="mb-2 p-5">
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Report Name*
              </label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                onChange={(e) => setReport(e.target.value.trim())}
              />
            </div>
            <div className="mb-3">
              <label for="exampleInputPassword1" className="form-label">
                Value*
              </label>
              <input
                type="text"
                onChange={(e) => setValue(e.target.value.trim())}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label for="exampleInputPassword1" className="form-label">
                Report Date*
              </label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => setReportD(new Date(e.target.value).getTime())}
              />
            </div>

            {loader ? (
              <button class="btn btn-dark" type="button" disabled>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <div className=" mb-2">
                <button
                  disabled={doctorReport}
                  className="btn btn-dark me-2"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
                <button
                  className="btn btn-dark"
                  disabled={doctorReport}
                  onClick={(e) => e.preventDefault()}
                  data-bs-toggle="modal"
                  data-bs-target="#reportModal"
                >
                  Send Doctor on Chat
                </button>
              </div>
            )}
          </form>

          <div
            className="modal fade"
            id="reportModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" style={back2}>
              <div className="modal-content" style={back2}>
                <div className="modal-header" style={back2}>
                  <h5
                    className="modal-title"
                    id="exampleModalLabel"
                    style={back2}
                  >
                    Send to Doctor
                  </h5>
                  <span className="fs-5" data-bs-dismiss="modal" style={back2}>
                    <i class="fa fa-times"></i>
                  </span>
                </div>
                <div className="modal-body" style={back2}>
                  <select
                    className="form-select form-control-lg back1"
                    aria-label="Default select example"
                    onChange={onTypeSelected}
                  >
                    <option value="">Select Doctor</option>
                    {allDoc?.map((item, index) => {
                      return <option value={item?.slug}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                <div className="modal-footer" style={back2}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    style={back2}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                    className="btn btn-success"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReport;
