import React, { useState, useEffect, useCallback, useContext } from "react";

import { useSetRecoilState } from "recoil";
import PhoneInput from "react-phone-input-2";

import AuthService from "../../../utils/auth-service";
import {
  DEFAULT_ERROR_CALLBACK,
  getFirstName,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  validateEmail,
} from "../../../utils/app-util";
import AppConstants from "../../../utils/app-constants";
import { LoginCred } from "../../../states";

import "react-phone-input-2/lib/style.css";
import "../login-page.scss";
import { CircleLoader } from "../../../components/loader";
import { SampleContext } from "../../../contexts/SampleContext";
import AuthTenant from "../../../utils/services/auth-service";



const PhoneNumberPage = ({ setOpenOtpPage, logo }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [inValidEmail, setInvalidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { back1, back2, button1 } = useContext(SampleContext);
  const[tenant,setTenant]=useState([])  

  useEffect(()=>{
    const tenantsss = AuthTenant?.getTenant();
    setTenant(tenantsss)
    
  },[])
  
  const setLoginCreds = useSetRecoilState(LoginCred);

  useEffect(() => {
    document.body.style.maxWidth = "100%";
    document.body.style.margin = "0px";
    document.body.style.padding = "0px 0px 0px 0px";
    document.body.style.paddingRight = "0px";
    console.log("email check", email)
    if (email) {
      validateEmail(email) ? setInvalidEmail(false) : setInvalidEmail(true);
    }
  }, [email]);


  const handleSendOTPToUser = useCallback(
    (payload) => {
      setIsLoading(true);
      AuthService.sendOTP(payload).subscribe(
        (resp) => {
          setLoginCreds((prevState) => {
            return {
              ...prevState,
              name: getFirstName(resp.data.name),
            };
          });
          setIsLoading(false);
          console.log("before notification")
          //SHOW_SUCCESS_NOTIFICATION(AppConstants.MESSAGE.SUCCESS_OTP);
          //SHOW_SUCCESS_NOTIFICATION("hello");
          console.log("after notification")
          setTimeout(() => {
            setOpenOtpPage(true);
          }, 2000);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
          setIsLoading(false);
        }
      );
    },
    [setLoginCreds, setOpenOtpPage]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const payload = {
        countryCode,
        email,
        mobileNumber,
        password: "",
      };
      if(mobileNumber.length !== 10){
        SHOW_ERROR_NOTIFICATION("Enter a valid 10 digit number");
        return;
      }
      setLoginCreds((prevState) => {
        return {
          ...prevState,
          mobileNumber,
          countryCode,
          email,
        };
      });

      if (countryCode !== "+91") {
        console.log("this is working 1")
        if (mobileNumber && email) {

          handleSendOTPToUser(payload);

        } else {

          !mobileNumber && setIsInvalidPhone(true);
          !email && setInvalidEmail(true);
        }
      } else {
        if (mobileNumber) {
          console.log("this is else ", mobileNumber)
          console.log('this is payload before function call', payload)
          handleSendOTPToUser(payload);
          console.log("this is after function call")

        } else {
          setIsInvalidPhone(true);
        }
      }
    },
    [countryCode, email, handleSendOTPToUser, mobileNumber, setLoginCreds]
  );

  const handleChangePhoneNumber = useCallback((value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setIsInvalidPhone(false);
  }, []);

  const handleChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  return (
    <>
      <div className='login-page__login-box'>
        <center >
          <img
            className='login-page__logo'
            src={logo}
            alt=''
            style={{ cursor: "pointer" }}
            onClick={() => window.location.replace('/')}
          />
        </center>
      </div>
      <div className='login-page__login-box'>
        <div className='login-page__headline' id="logintext">Log in to your account</div>
        <div className='login-page__text-content' id="logintext1">
          Continue with your mobile number as you proceed to login.
        </div>
      </div>
      <form
        className='login-page__login-box login-page__login-form'
        onSubmit={handleSubmit}
      >
        <div className='login-page__form-row'>
          <label className='login-page__label' htmlFor='phone-input' id="logintext2" style={back1}>
            Mobile Number (Required)
          </label>
          <PhoneInput
            country={"in"}
            enableSearch
            inputClass='login-page__phone-input'
            id='phone-input'
            countryCodeEditable={false}
            placeholder='Enter Mobile'
            onChange={handleChangePhoneNumber}
          />
          {isInvalidPhone && (
            <span className='login-page__validation-error' id="logintext3" style={back1}>
              Phone Number is required
            </span>
          )}
        </div>
        <div className='login-page__form-row'>
          <label className='login-page__label' htmlFor='email-field' style={back1}>
            {`Email (for otp)  ${countryCode === "+91" ? "" : "Required"} `}
          </label>
          <input
            id='email-field'
            type='email'
            className='login-page__password-input'
            placeholder='Enter Email'
            onChange={handleChangeEmail}
            value={email}
            autoComplete="off"
          />
          {inValidEmail && (
            <span className='login-page__validation-error' id="logintext4">
              Email is required
            </span>
          )}
        </div>
        <button
          className='login-page__login-btn login-page__form-row'
          type='submit'
          style={button1}
        >
          {isLoading ? <CircleLoader dimension={20} /> : "Send OTP"}
        </button>
      </form>
       
           <div className="container">
         <div className="text-center">
           <h6 className="text-light"  dangerouslySetInnerHTML={{
                  __html: tenant?.loginPageHelp,
                }} style={{marginTop:"7rem"}}>
             
                </h6>
         </div>
       </div>
  
         
    </>
  );
};
export default PhoneNumberPage;
