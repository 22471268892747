import React, { useContext } from "react";
import AuthService from "../../utils/services/auth-service";
import { SampleContext } from "../../contexts/SampleContext";
import "./footer.css"


const Footer = () => {
  const tenant = AuthService.getTenant();
  const {back1, back4} = useContext(SampleContext);


  return (
    < >

      <div className="container">
        <div className="foot" style={back1}>
          © {tenant.name} 2024 All rights reserved.
        </div>
      </div>

    </>
  );
};

export default Footer;
