import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { SampleContext } from "../../contexts/SampleContext";

const DisclaimerDialog = ({ open, onDisclaimerAgreed, disclaimer }) => {
  const {back1, back2} = useContext(SampleContext);
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Disclaimer</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {disclaimer}
        </DialogContentText>
      </DialogContent>
      <DialogActions id="popup-modal">
        <Button
          variant="contained"
          onClick={onDisclaimerAgreed}
          style = {back1}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DisclaimerDialog;
