import React, { useState, useRef, useEffect, useContext } from "react";
import { Grid, makeStyles, Button, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useGlobalStyles from "../../utils/global-style2";
import clsx from "clsx";
import ChatHeader from "./chat-header";
import AuthService from "../../utils/services/auth-service";
import Api from "../../utils/api2";
import Api1 from "../../utils/api";
import Routes from "../../routes/routes";
import DataService from "../../utils/services/data-service-doctor";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import ChatBox from "./chat-box";
import ChatTextBox from "./chat-textbox";
import "./chat-frontend.css";
import { useLocation } from "react-router-dom";
import useSocket from "./socket";
import "./chatnew.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { SampleContext } from "../../contexts/SampleContext";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    height: "90vh",
    marginBottom: "3rem",
  },
  chatHeader: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.chatBg,
    zIndex: "9999"
  },
  chatBox: {
    boxShadow: "0px 5px 20px #00000008",
    borderBottomLeftRadius: "14px",
    borderBottomRightRadius: "14px",
    height: "74vh",
    width: "100%",
    overflowY: "auto",
    margin: "-35px 8px 0px 8px"
  },

  loadMoreBtn: {
    position: "absolute",
    top: "160px",
    color: "white",
    display: "flex",
    alignSelf: "center",
    top: "31%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "9999",
  },
}));

const Chat = () => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const user = AuthService.getUser();
  const tenant = AuthService.getTenant();
  let history = useHistory();
  const messagesEndRef = useRef(null);
  const formApi = useRef();
  const [currentSession, setCurrentSession] = useState(null);
  const socket = useSocket();
  const [isAgreed, setIsAgreed] = useState(false);
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isscrolldown, setIsScrollDown] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const {back1, back2, button1} = useContext(SampleContext);
  // console.log(document.getElementsByClassName("makeStyles-textBubbleRightBottom-266"));

  const scrollToBottom = () => {
    const scrollHeight = messagesEndRef.current.scrollHeight;
    const height = messagesEndRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const fetchChatSession = () => {
    Api.get(
      `/chat-session/doc/my?docSlug=${location?.state?.docSlug}`
    ).subscribe((resp) => {
      if (resp.data.data) {
        setCurrentSession(resp.data.data);
        socket.setSessionSlug(resp.data.data.slug);
        socket.startSession(resp.data.data.slug);
        setCurrentPage(currentPage + 1);
      } else {
        history.replace(Routes.CHAT_SUB);
      }
    });
  };

  const handleMoreMessages = () => {
    if (socket.currentPage <= socket.totalPages) {
      socket.loadMoreMessages(currentSession?.slug);
      setIsScrollDown(false);
    }
  };

  
  React.useEffect(() => {
    if (document.getElementById("chatbox1")) {
      document.getElementById("chatbox1").addEventListener(
        "scroll",
        function (e) {
          socket.isMsgLoaded &&
          document.getElementById("chatbox1").scrollTop < 500
            ? setVisibility(true)
            : setVisibility(false);
        },
        false
      );
    }
  });

  useEffect(() => {
    Api1.get(
      `is_blocked?doctorSlug=${currentSession?.doctorSlug}&patientSlug=${currentSession?.patientSlug}`
    ).subscribe((resp) => {
      setShow(resp.data.data);
    });
  });

  console.log(show);

  // useEffect(scrollToBottom, [socket.messageHistory]);
  useEffect(() => {
    if (isscrolldown) {
      scrollToBottom();
    } else {
      setIsScrollDown(true);
    }
  }, [socket.messageHistory]);

  useEffect(fetchChatSession, []);
  useEffect(() => {
    const polledSub = DataService.getList(
      `/chat-session/doc/my?docSlug=${location?.state?.docSlug}`,
      5000
    ).subscribe((resp) => {
      setCurrentSession(resp);
      // console.log(resp);
    });

    return function () {
      polledSub.unsubscribe();
    };
  }, []);

  const handleSubmit = (val) => {
    if (!val.msg) {
      return;
    }
    socket.sendMessage(val);
    formApi.current.reset();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        handleSubmit(formApi.current.getState().values);
      }
    }
  };

  const handleChatAction = (val) => {
    history.push({
      pathname: val,
      state: location?.state?.docSlug,
    });
  };

  const uploadImage = (evt) => {
    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();
    formData.append("files", fileArray[0]);
    DataService.uploadImage(formData).subscribe(
      (resp) => {
        socket.sendMessage({ imgUrl: resp[0]?.imageId });
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const onAgreed = () => {
    setIsAgreed(true);
  };

  // const oldmessage = currentSession?.latestMessagePreview;

  // if(oldmessage != currentSession?.latestMessagePreview){
  //   socket.messageHistory.append(currentSession?.latestMessagePreview);
  // }

  // console.log(socket);

  return (
    <div
      className={clsx(globalClass.minHeightRoot, globalClass.width)}
      style={{ marginTop: "80px" }}
      id= "ch"
    >
      <Grid container>
        <Grid container item className={clsx(classes.chatContainer)}>
          {currentSession && (
            <ChatHeader
              itemStyle={classes.chatHeader}
              name={currentSession && currentSession.doctor?.name}
              image={
                currentSession && currentSession.doctor?.profilePic?.content
              }
              date="Remaining"
              question={`${currentSession.totalQuestions} question(s)`}
              day={`or ${currentSession.totalDays} Days`}
            />
          )}

          {visibility && socket.currentPage <= socket.totalPages && (
          <Grid container>
            <div className="" style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                className={`${classes.button} ${classes.loadMoreBtn}`}
                startIcon={<ArrowUpwardIcon />}
                onClick={handleMoreMessages}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={button1}
              >
                Load More Messages
              </Button>
            </div>
          </Grid>
          )}
          {/*  */}

          <ChatBox
            user={user}
            onAgreed={onAgreed}
            isAgreed={isAgreed}
            msgLoaded={socket.isMsgLoaded}
            currentSession={currentSession}
            className={classes.chatBox}
            handleChatAction={handleChatAction}
            messages={socket.messageHistory}
            messagesEndRef={messagesEndRef}
            
          />

          {currentSession &&
            currentSession.status === "ACTIVE" && !show &&
            (isAgreed || socket.messageHistory.length > 0) && (
              <ChatTextBox
                handleSubmit={handleSubmit}
                formApi={formApi}
                uploadImage={uploadImage}
                handleKeyPress={handleKeyPress}
              />
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
