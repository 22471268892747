import React, { useEffect } from "react";
import { CircularProgress, Box } from "@material-ui/core";
import AuthService from "../utils/auth-service";
import { useQuery } from "../utils/app-util";

const Logout = () => {
  const query = useQuery();
  const redirectUrl = query.get("redirectUrl");

  useEffect(() => {
    AuthService.logout().subscribe(
      (resp) => {
        console.log(resp);
        AuthService.clearLocalStorage();
        window.location.replace("/");
      },
      (error) => {
        AuthService.clearLocalStorage();
        window.location.replace("/ab");
      }
    );
  }, [redirectUrl]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      height={"100vh"}
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default Logout;
