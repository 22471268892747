import React, { useState, useEffect, useContext } from "react";
import useGlobalStyles from "../../utils/global-style";
import moment from "moment";
import './global.css';
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
  makeStyles,
} from "@material-ui/core";
import Api from "../../utils/api2";
import AppointmentItem from "./item";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import clsx from "clsx";
import { format } from "date-fns";

import AppConstants from "../../utils/app-constants";
import AuthService from "../../utils/services/auth-service";
import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";

import noUpcomingAppnmtImg from "../../assets/no-upcoming-appointments.svg";
import noPastAppnmtImg from "../../assets/no-past-appointments.svg";
import Sidebutton from "../../components/layout/sidebutton";
import axios from "axios";
import { SampleContext } from "../../contexts/SampleContext";

const useStyles = makeStyles((theme) => ({
  bookAppmntBtnContainer: {
    position: "relative",
    textAlign: "center",
    marginTop: theme.spacing(6),
    "& button": {
      whiteSpace: "nowrap",
    },
  },
  notFound: {
    width: 65,
  },
}));


const Appointments = () => {
  const classes = useStyles();
  const gClasses = useGlobalStyles();

  const { slug: docSlug = "" } =
    (AuthService.getTenant() || {}).selectedDoctor || {};

  const history = useHistory();
  const [appointments, setAppointments] = useState();
  const [pastAppointments, setPastAppointments] = useState();
  const [slugAppo, setSlugAppo] = useState();
  const [remark, setRemark] = useState();
  const { back1, back2, button1 } = useContext(SampleContext);
  const imagePerRow = 2;
  const [next, setNext] = useState(imagePerRow);
  const [n, setN] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const handlePastImage = () => {
    setN(n + imagePerRow);
  }

  var back6 = {};
  back6.color = back1.color;
  back6.marginTop = "10px";

  useEffect(() => {
    document.body.classList.add('container');
    Api.get("appointment/patient/upcoming").subscribe((resp) => {
      const arr = (resp.data || {}).data || [];
      setAppointments(arr);
    });
  }, []);

  useEffect(() => {
    Api.get("appointment/patient/past").subscribe((resp) => {
      const arr = ((resp.data || {}).data || {}).content || [];
      setPastAppointments(arr);
    });
  }, []);

  const slugcall = () => {

    const req = {
      remark: remark
    }
    Api.put(`appointment/status/${slugAppo}/CANCELLED`, req).subscribe(
      (resp) => {
        if (remark?.length > 0) {
          SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CANCEL_SUCCESS);
          window.location.replace("/appointments");
        }
        else {
          SHOW_ERROR_NOTIFICATION(AppConstants.MSG.CANCEL_REMARK);
        }

      },
      (error) => {

      }
    );

  }

  const onItemClicked = (slug) => {
    history.push(Routes.APPOINTMENT + `?slug=${slug}`);
  };

  const handlescan = (slug, checkIn) => {
    // window.location.replace('/reader');

    history.push({
      pathname: '/reader',
      state: {
        apSlug: slug,
        checkIn: checkIn
      },
    });
  }

  const Loader = () => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"20vh"}
      flexGrow="1"
    >
      <CircularProgress size={30} />
      <span style={back6}>Please Wait!</span>
    </Box>
  );

  const redirectap = () => {
    if (!AuthService.isAuthenticated) {
      window.location.replace('/')
    }
  }
  redirectap();

  var dayDate = format(new Date(), "yyyy-MM-dd");

  return (
    <>
      <div className="container" style={back1}>
        <div className="row overflow-hidden" style={{ "marginTop": "115px" }}>
          <Sidebutton AuthService={AuthService} profile={false} />

          <main className="col-lg-9 col-md-9 col-sm-9 ">
            <div className="d-grid gap-3 mb-4 container">
              <h5 className="text-center text-lg-start">
                Upcoming Appointments
              </h5>

              {appointments ? (
                appointments.length === 0 ? (
                  <div className="text-center">
                    <img src="images/myappointment.png" className="img-fluid" alt="" style={{ width: "183px" }} />
                  </div>
                ) : (
                  appointments.slice(0, next).map((item, index) => (
                    <div className="p-2 border rounded-3 row" >
                      <div style={{ cursor: "pointer" }}>
                        <div className="row " >
                          <div className="col-12">
                            <div className="col-6">{item?.doctor?.name}</div>
                            <div className="col-6">{item?.doctor?.specialityName}</div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-8 col-10 col-sm-8 col-md-8 text-start pt-1">
                          <span className="me-2">
                            <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;&nbsp;

                            {moment(item?.date).format("dddd Do, MMMM YYYY")}
                          </span>{" "}
                          <span>
                            <i class="fa-solid fa-clock " aria-hidden="true"></i>&nbsp;&nbsp;
                            {moment(item?.time, "H:mm").format("H:mm A")}
                          </span>{" "}
                        </div>
                        <div className=""
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <button
                            className="btn btn-secondary rounded-pill mx-1 d-none d-md-block d-lg-block d-sm-none "
                            onClick={() => setSlugAppo(item?.slug)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>


                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content" style={back2}>
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Cancel</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ color: back2.color }}></button>
                            </div>
                            <div class="modal-body">
                              <div class="form-group">
                                <input type="text" value={remark} class="form-control" placeholder="Enter Reason For Cancellation" onChange={e => setRemark(e.target.value)} />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" required="true" onClick={() => slugcall()} style={button1}>Cancel Appointment</button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="mt-2" />
                      <div className="   d-flex justify-content-between  mb-2">
                        <button
                          type="button"
                          className="btn   btn-warning btn-sm rounded-pill opacity-75 m-1 "
                          style={{ "fontSize": "1.2vmax" }}

                        >
                          {item?.status}
                        </button>


                        {item?.prescription && (

                          <button
                            className="  btn btn-primary rounded-pill m-1"
                            style={{ "fontSize": "1.2vmax", "backgroundColor": "rgb(44, 105, 178)" }}

                            onClick={() => window.location.replace(`/pres?id=${item?.slug}`)}
                          >
                            View Prescription
                          </button>
                        )}

                      </div>
                      <div className="d-flex justify-content-between">



                        <button
                          type="button"
                          className="  btn btn-secondary rounded-pill opacity-75 d-lg-none d-md-none d-block  m-1 me-3"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setSlugAppo(item?.slug)}
                          style={{ "fontSize": "1.2vmax" }}


                        >
                          Cancel
                        </button>
                        {format(item?.date, "yyyy-MM-dd") == dayDate && (item?.status == "CONFIRMED") && (
                          <button
                            type="button"
                            className="btn btn-success rounded-pill    m-1"
                            onClick={() => handlescan(item?.slug, item?.doctor?.checkIn)} 
                            style={{ "fontSize": "1.2vmax" }}
                          >
                            Check In
                          </button>
                        )}



                      </div>

                    </div>
                  ))
                )
              ) : (
                <Loader />
              )}
            </div>

            {appointments?.length > 2 && (
              <div class="text-center mb-4" onClick={handleMoreImage}> <span class="btn btn-primary" style={button1}>Load More</span></div>
            )}

            <div className="d-grid gap-3 mb-4 container" style={back1}>
              <h5 className="text-center text-lg-start">Past Appointments</h5>

              {pastAppointments ? (
                pastAppointments.length === 0 ? (
                  <div className="text-center">
                    <img src="images/myappointment.png" className="img-fluid" alt="" style={{ width: "183px" }} />
                  </div>
                ) : (
                  pastAppointments.slice(0, n).map((item, index) => (
                    <div className="p-2 border rounded-3  row" style={back1}>
                      <div className="row " style={{ cursor: "pointer" }}>
                        <div className="col-12">

                          <div className="col-6">{item?.doctor?.name}</div>
                          <div className="col-6">{item?.doctor?.specialityName}</div>

                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="col-lg-8 col-12 col-sm-8 col-md-8 text-start pt-1">
                        <span className="me-2">
                          <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;&nbsp;

                          {moment(item?.date).format("dddd Do, MMMM YYYY")}
                        </span>{" "}
                        <span>
                          <i class="fa-solid fa-clock " aria-hidden="true"></i>&nbsp;&nbsp;
                          {moment(item?.time, "H:mm").format("H:mm A")}
                        </span>{" "}
                      </div>

                      <hr className="mt-2" />
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-warning rounded-pill opacity-75 m-1"
                            style={{ "fontSize": "1.2vmax" }}
                          >
                            {item?.status}
                          </button>
                          {item?.prescription && (
                            <button className="  btn btn-primary rounded-pill m-1" style={{ "backgroundColor": "rgb(44, 105, 178)", "fontSize": "1.2vmax" }}
                              onClick={
                                () =>

                                  window.location.replace(`/pres?id=${item?.slug}`)
                              }
                            >
                              View Prescription
                            </button>
                          )}

                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <Loader />
              )}
            </div>
            {
              pastAppointments?.length > 2 && (
                <div class="text-center mb-4" onClick={handlePastImage}> <span class="btn btn-primary" style={button1}>Load More</span></div>
              )
            }

          </main>
        </div>


      </div>
    </>
  );
};

export default Appointments;
