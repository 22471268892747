import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form, RadioGroup, Radio } from "informed";
import useGlobalStyles from "../../utils/global-style2";
import clsx from "clsx";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
  Paper,
} from "@material-ui/core";
import Api from "../../utils/api2";
import { useLocation } from "react-router-dom";
import { isRequired } from "../../utils/validations";
import Routes from "../../routes/routes";
import AuthService from "../../utils/services/auth-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SampleContext } from "../../contexts/SampleContext";
import Api2 from "../../utils/api2";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  pckgCard: {
    padding: "12px 15px",
    marginBottom: "10px",
    borderRadius: "8px",
  },

  pckgRadioGrp: {
    position: "relative",

    "& .checkmark": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "18px",
      width: "18px",
      borderRadius: "30px",
      backgroundColor: "#fff",
      border: "1px solid #333",
    },

    "& input:checked + .checkmark": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },

  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },

  greetingText: {
    margin: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  greeting: {
    borderRadius: 14,
    height: "100",
    background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, #F6F7FC 100%) 0% 0% no-repeat padding-box`,
    boxShadow: "0px 5px 20px #00000008",
  },

  containerBox: {
    padding: "1rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },

  gridPadding: {
    padding: theme.spacing(1),
  },
  topPadding: {
    paddingTop: theme.spacing(3),
  },
}));

const ChatSubscription = () => {
  const history = useHistory();
  const classes = useStyles();
  const gClasses = useGlobalStyles();
  const [btnText, setBtnText] = useState("Continue to Payment");
  const location = useLocation().pathname + useLocation().search || "/";
  const [loading, setLoading] = useState(false);
  const docSlug = useLocation().state;
  const {back1, back2, button1} =useContext(SampleContext);

  // const { slug: docSlug = "" } =
  //   (AuthService.getTenant() || {}).selectedDoctor || {};

  const [packages, setPackages] = useState([]);


  const fetchChatPackages = () => {
    Api.get(`/chat-package/package-list/${docSlug}`).subscribe((resp) => {
      const pckgs = (resp.data || {}).data || [];
      setPackages(pckgs);
    });
  };

  useEffect(fetchChatPackages, []);

  const handleSubmit = (values) => {
    if (AuthService.isAuthenticated) {
      setLoading(true);
      console.log(values);
      const { packageSlug } = values;
      const slug = packageSlug.slug;
      const docSlug = packageSlug.doctorSlug;
      const chatPackage = packages.find(obj => {
        return obj.slug === slug;
      });
      const url = `/order/initiate/chat-package?doctorSlug=${docSlug}&chatPackageSlug=${slug}`;

      Api.post(url).subscribe((resp) => {
        const { razorpayOrderId, slug: orderId } = (resp.data || {}).data || {};
    
        if (orderId) {
          if (packageSlug.amount !== 0) {
            setLoading(false);
            history.push({
              pathname: Routes.PCKG_DTLS,
              state: {
                docSlug,
                chatPackage,
                orderId,
                razorpayOrderId
              },
            });
          } else {

            const paymentDto = {
              mode: "636b7f3dbbcfe621af621891",
              amount: packageSlug.amount,
            };
    
            Api2.put(`/order/payment/${orderId}`, paymentDto).subscribe((resp) => {
              const resObj = resp.data || {};
        
              if (resp) {
                
                setLoading(false);
                history.push({
                  pathname: Routes.CHAT,
                  state: {
                    docSlug
                  }
                });
              }
            });


          }
        }
      });
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL}/login?redirectUrl=${window.location.origin}/home/callback&redirectPath=${location}`
      );
    }
  };

  return (
    <div className={clsx(gClasses.minHeightRoot, gClasses.width)} style={{marginTop: "90px"}}>
      <Container>
        <Form onSubmit={handleSubmit}>
          {({ formState }) => (
            <>
              <Box my={2}>
                <Typography component="h4" variant="h6" style={back1}>
                  Chat with doctor
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.greeting}
              >
                <Box p={1}>
                  <Typography
                    className={classes.greetingText}
                    component="h4"
                    variant="body2"
                  >
                    Private & Secure
                  </Typography>
                </Box>
                <Box mr={4} my="auto">
                  <img
                    height={36}
                    alt="unlock-icon"
                    src={
                      "https://storage.googleapis.com/eclinic_assets/unlock.svg"
                    }
                  />
                </Box>
              </Box>
              <Box mt={4} mb={2}>
                <Typography component="h4" variant="h6" style={back1}>
                  Packs
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <RadioGroup
                    field="packageSlug"
                    validate={isRequired}
                    validateOnMount
                    validateOnChange
                  >
                    {packages.map((pckg, indx) => {
                      const {
                        packageName = "",
                        totalQuestions = "",
                        totalDays = "",
                        amount = "",
                      } = pckg || {};

                      return (
                        <Grid item xs={12} sm={6} key={indx}>
                          <label>
                            <Paper elevation={3} className={classes.pckgCard} style={back2}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box display="flex" alignItems="center">
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mr={2}
                                  >
                                    <div
                                      className={classes.pckgRadioGrp}
                                      
                                      onClick={() => {
                                        setBtnText(
                                          amount !== 0
                                            ? "Continue to Payment"
                                            : "Continue"
                                        );
                                      }}
                                    >
                                      <Radio value={pckg} />
                                      <span className="checkmark" ></span>
                                    </div>
                                  </Box>
                                  <div>
                                    <Box mb={0.8}>
                                      <Typography variant="subtitle1" style={{color: back2.color}}>
                                        <strong>{packageName}</strong>
                                      </Typography>
                                    </Box>
                                    <Typography variant="body2" style={{color: back2.color}}>
                                      Total Questions: {totalQuestions}
                                    </Typography>
                                    <Typography variant="body2" style={{color: back2.color}}>
                                      Total Days: {totalDays}
                                    </Typography>
                                  </div>
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <Typography variant="body2" style={{marginLeft: "16px", color: back2.color}}>
                                    INR <strong>{amount}</strong>
                                  </Typography>
                                </Box>
                              </Box>
                            </Paper>
                          </label>
                        </Grid>
                      );
                    })}
                  </RadioGroup>
                </Grid>
              </Box>

              <Box mt={4} display="flex" justifyContent="space-between">
                {/* <Razorpay btnText="Continue to Payment" amount={"200"} /> */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formState.invalid}
                  style={
                    formState.invalid ? { display: "none" } : { color: button1.color, backgroundColor: button1.backgroundColor }
                  }
                >
                  {loading ? (
                    <CircularProgress
                      variant="indeterminate"
                      size={20}
                      color="inherit"
                      thickness={4}
                    />
                  ) : (
                    btnText
                  )}
                </Button>

                {/* <Button variant="contained" color="primary">
                  Past Chat
                </Button> */}
              </Box>
            </>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ChatSubscription;
