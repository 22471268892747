import { useState, useEffect } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import AppConstants from "../../utils/app-constants";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service-admin";
import { DEFAULT_ERROR_CALLBACK} from "../../utils/app-util";

let stompClient;
const useSocket = () => {
  const user = AuthService.getUser();
  const [messageHistory, setMessageHistory] = useState([]);
  const [sessionSlug, setSessionSlug] = useState(null);
  const [isMsgLoaded, setIsMsgLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  let chatSub;
  const startSession = (sessionSlug) => {
    setSessionSlug(sessionSlug);

    var sock = new SockJS(`${process.env.REACT_APP_API_BASE_URL1}/ws`);

    stompClient = Stomp.over(sock);
    sock.onopen = function () {};
    let header = {
      "X-AUTH-TOKEN": AuthService.getToken(),
    };

    stompClient.connect(header, function (frame) {
      chatSub = stompClient.subscribe(
        `/app/topic/messages/${sessionSlug}`,
        function (msg) {
          const msgBody = JSON.parse(msg.body) || {};
          setTotalPages(msgBody.totalPages);

          const newMessage = msgBody.slug ? msgBody : msgBody.content;
          if (newMessage) {
            setIsMsgLoaded(true);
            setMessageHistory((prev) => prev.concat(newMessage));
          }
        },
        { id: sessionSlug }
      );
    });
  };

  useEffect(() => {
    return function () {
      if (chatSub) {
        chatSub.unsubscribe(sessionSlug);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = (val) => {
    stompClient.send(
      `/app/chat/${sessionSlug}`,
      {},
      JSON.stringify({
        fromUserSlug: user.slug,
        text: val["msg"],
        attachmentUrl: null,
        chatSessionSlug: sessionSlug,
        attachmentId: val["imgUrl"],

      })
    );
  };

  const loadMoreMessages = (sessionSlug) => {
    if (totalPages >= currentPage) {
      DataService.loadMoreMessages(sessionSlug, currentPage).subscribe(
        (resp) => {
          setIsMsgLoaded(true);
          setMessageHistory((prev) =>
            Array.isArray(resp.content) ? resp.content.concat(prev) : prev
          );
          setCurrentPage(currentPage + 1);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    }
  };
   

  return {
    messageHistory,
    sendMessage,
    setSessionSlug,
    startSession,
    isMsgLoaded,
    totalPages,
    currentPage,
    setCurrentPage,
    loadMoreMessages,
  };
};

export default useSocket;
