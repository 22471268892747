import React, { useState, useEffect } from "react";

import moment from "moment";
import clsx from "clsx";
import {
  Container,
  Box,
  Typography,
  Paper,
  makeStyles,
  Avatar,
  CircularProgress,
} from "@material-ui/core";

import useGlobalStyles from "../../utils/global-style";

import NoChat from "./no-chat";

import Api from "../../utils/api";
import AuthService from "../../utils/services/auth-service";

const useStyles = makeStyles((theme) => ({
  chatCard: {
    padding: "17px 20px",
    borderRadius: "8px",
    marginBottom: "20px",
    [theme.breakpoints.down(480)]: {
      padding: "15px",
    },
  },

  docAvatar: {
    width: 80,
    height: 80,
    marginRight: "20px",
    [theme.breakpoints.down(480)]: {
      width: 55,
      height: 55,
      marginRight: "15px",
    },
    boxShadow: " 0px 3px 6px #0000001A",
  },

  docName: {
    font: "bold 16px/20px Comfortaa",
    marginBottom: "10px",
  },

  docSpeciality: {
    color: "#6D6D6D",
    letterSpacing: "0px",
    font: "14px/15px Comfortaa",
  },
}));

const ChatHistory = () => {
  const gClasses = useGlobalStyles();
  const classes = useStyles();

  const [chatHistory, setChatHistory] = useState();

  const fetchChatHistory = () => {
    Api.get("/chat-session/my?page=0&size=20").subscribe((resp) => {
      const chatData = ((resp.data || {}).data || {}).content || [];

      setChatHistory(chatData);
    });
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const handleChatClick = (docWebsiteUrl = "") => {
    if (AuthService.isAuthenticated) {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL}/login?redirectUrl=${docWebsiteUrl}/home/callback&redirectPath=/app/chat`
      );
    } else {
      window.location.replace(`${docWebsiteUrl}`);
    }
  };

  return (
    <div
      className={clsx(gClasses.minHeightRoot, gClasses.width)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Container
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <Box pt={2} mb={3}>
          <Typography component="h4" variant="h6">
            Chat History
          </Typography>
        </Box>

        {chatHistory ? (
          chatHistory.length === 0 ? (
            <NoChat />
          ) : (
            chatHistory.map((chat = {}, indx) => (
              <Paper
                elevation={3}
                className={classes.chatCard}
                key={indx}
                onClick={() => handleChatClick(chat.doctor.websiteUrl)}
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={(chat.doctor && chat.doctor.profilePic) || ""}
                    className={classes.docAvatar}
                  ></Avatar>

                  <Box flexGrow={1}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" className={classes.docName}>
                        {chat.doctor && chat.doctor.name}
                      </Typography>
                      <Typography variant="body2">
                        {chat.dateModified
                          ? moment(chat.dateModified).format("DD, MMM YYYY")
                          : ""}
                      </Typography>
                    </Box>

                    <Typography
                      variant="subtitle1"
                      className={classes.docSpeciality}
                    >
                      {chat.doctor && chat.doctor.speciality}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            ))
          )
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={"30vh"}
          >
            <CircularProgress />
            <span style={{ marginTop: "10px" }}>Please Wait!</span>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ChatHistory;
