import React, { useState, useEffect, useCallback, useContext } from "react";
import { phone } from "phone";
import PhoneNumberPage from "./components/phone-number-page";
import OtpPage from "./components/otp-page";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  useQuery,
} from "../../utils/app-util";
import AuthService from "../../utils/auth-service";
import AppConstants from "../../utils/app-constants";
import "react-phone-input-2/lib/style.css";
import "./login-page.scss";
import { ThemeContext } from "@emotion/react";
import "./loginpage.css";
import { SampleContext } from "../../contexts/SampleContext";
import { useLocation } from "react-router-dom";
import Api from "../../utils/api";

const LoginPage = () => {
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const query = useQuery();
  const mobileNumber = query.get("mobileNumber");
  const redirectUrl = query.get("redirectUrl");
  const redirectPath = query.get("redirectPath");
  const date = query.get("date");
  const time = query.get("time");
  const type = query.get("type");
  const { back1, back2 } = useContext(SampleContext);
  const location = useLocation();
  const [image, setImage] = useState();

  const handleSendOtp = useCallback((payload) => {
    AuthService.sendOTP(payload).subscribe(
      () => {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MESSAGE.SUCCESS_OTP);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  }, []);

  useEffect(() => {
    document.body.style.maxWidth = "100%";
    document.body.style.margin = "0px";
    document.body.style.padding = "0px 0px 0px 0px";
    document.body.style.paddingRight = "0px";
    // document.getElementsByClassName("login-page__login").style.backgroundColor = "black";
    document.getElementById("login").style.backgroundColor =
      back1.backgroundColor;
    document.getElementById("logintext").style.color = back1.color;
    document.getElementById("logintext1").style.color = back1.color;

    // document.getElementById("logintext2").style.color = back1.color;
    // document.getElementById("logintext3").style.color = back1.color;
    // document.getElementById("logintext4").style.color = back1.color;

    if (!AuthService.isAuthenticated) {
      if ("caches" in window) {
        caches
          .keys()
          .then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          })
          .catch((error) => {
            console.error("Error deleting caches:", error);
          });
      } else {
        console.warn("Caches API not supported in this environment.");
      }
      localStorage.clear();
    }
    

    if (AuthService.isAuthenticated) {
      AuthService.validateToken().subscribe(
        () => {
          const token = AuthService.getToken();
          if (date) {
            window.location.replace(
              redirectUrl +
              "?access-token=" +
              token +
              "&redirectPath=" +
              redirectPath +
              "&date=" +
              date +
              "&time=" +
              time +
              "&type=" +
              type
            );
          } else {
            window.location.replace("/");
          }
        },
        () => {
          AuthService.clearLocalStorage();
        }
      );
    } else {
      if (mobileNumber) {
        setOpenOtpPage(true);
        const { isValid, phoneNumber, countryCode } = phone(mobileNumber);
        if (isValid) {
          handleSendOtp({
            mobileNumber: phoneNumber,
            countryCode,
          });
        } else {
          handleSendOtp({
            mobileNumber,
            countryCode: "+91",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    Api.get("/get_bg_images?type=frontEnd").subscribe((resp) => {
      setImage(resp?.data?.data);
    });
  }, []);

  const tenant = AuthService.getTenant();

  return (
    <div className="login-page col-md-12 fix">
      <div
        className="login-page__poster"
        style={{ backgroundImage: `url(${image?.loginBgFrontEnd})` }}
      ></div>
      <div className="login-page__login" id="login">
        {!openOtpPage ? (
          <PhoneNumberPage setOpenOtpPage={setOpenOtpPage} logo={image?.logo} />
        ) : (
          <OtpPage
            setOpenOtpPage={setOpenOtpPage}
            phoneNumber={mobileNumber}
            slug={location?.state}
            logo={image?.logo}
          />
        )}
      </div>
    </div>
  );
};
export default LoginPage;
