import React,{useContext} from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { SampleContext } from "../../contexts/SampleContext";

const FetchProgress = () => {
  const {back1, back2} = useContext(SampleContext)
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"30vh"}
    >
      <CircularProgress />
      <span style={{ marginTop: "10px",color: back1.color }}>Please Wait!</span>
    </Box>
  );
};

export default FetchProgress;
