import { NotificationManager } from "react-notifications";
import Bro from "brototype";
import AppConstants from "./app-constants";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";

export const DEFAULT_ERROR_CALLBACK = error => {
  NotificationManager.error(
    Bro(error).iCanHaz("response.data.message") ||
    AppConstants.MSG.GENERIC_ERROR,
    "",
    8000
  );
};
export const getFormattedDate = (date, format) => {
  return moment(date).format(format || "DD MMM YY");
};

export const getFormattedTime = (time, format) => {
  return moment(time, "HH:mm").format(format || "hh:mm a");
};

export const getIncrementedTime = (time, value) => {
  return moment(time, "HH:mm")
    .add(value, "milliseconds")
    .format("hh:mm a");
};

export const getStringFromArray = array => {
  return array.join("||");
};

export const convertToSlug = text => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const SHOW_ERROR_NOTIFICATION = error => {
  NotificationManager.error(error, "", 8000);
};

export const SHOW_INFO_NOTIFICATION = (msg, time = 8000) => {
  NotificationManager.info(msg, "", time);
};

export const SHOW_SUCCESS_NOTIFICATION = msg => {
  NotificationManager.success(msg, "", 8000);
};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const convertIntoReadableString = (data) => {
  return toUpper(data);
}

export const toUpper = (str) => {
  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(' ');
};

export const transformAppointmentObj = (arr) => {
  let transformArr = arr.map(item => {
    item.value = item.title;
    return item;
  });
  return transformArr;
};

export function getFirstName(value) {
  if (value) {
    const [firstName] = value.split(' ');
    return firstName;
  }
  return '';
}

export const validateEmail = (email) => {
  const isValid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return isValid
}
