import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Grid,
  makeStyles,
  fade,
  Container,
  Typography,
} from "@material-ui/core";
import { ListItem } from "sixsprints-react-ui";
import useGlobalStyles from "../../utils/global-style";
import { useQuery } from "../../utils/app-util";
import Api from "../../utils/api";
import AuthService from "../../utils/services/auth-service";
import SearchInput from "../../components/form/search-input";
import Routes from "../../routes/routes";
import { Link } from "react-router-dom";

import FetchProgress from "./fetch-progress";
import NoResult from "./no-result";
import { LaptopWindows } from "@material-ui/icons";
import clsx from "clsx";
import "./doctor.css";
import { SampleContext } from "../../contexts/SampleContext";

const Doctors = () => {
  const [doctors, setDoctors] = useState(null);
  const [tempDoctors, setTempDoctors] = useState([]);
  let query = useQuery();
  const category = query.get("category");
  const categoryLabel = query.get("label") || category;
  const [save, setSave] = useState();
  const {back1, back2, button1} = useContext(SampleContext)


  const onItemClicked = (val) => {
    window.location.href = `/` + val;
  };

  useEffect(() => {
    Api.get(`getdoctor/?slug=${category}`).subscribe((resp) => {
      const arr = resp.data || {} || {} || [];
      setDoctors(arr);
      setTempDoctors(arr);
    });
  }, [category]);

  const handleSearch = ( search ) => {
    const filteredList = search
      ? doctors.filter((item) => {
          return (item.name || "").toLowerCase().includes(search);
        })
      : doctors;

    setTempDoctors(filteredList);
  };

  return (
    <div className="row" style={back1}>
      <div className="col-md-12 mx-auto pt-5" style={{marginTop: "60px"}}>
        <div className="row mb-5">
          <div className="col-md-8 mx-auto">
            <div className="input-group container">
              <input
                type="search"
                className="form-control  search-box "
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="search-addon"
                style={{ outline: "none" }}
                onChange={(e)=>handleSearch(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={()=>handleSearch(save)}
                style={button1}
              >
                search
              </button>
            </div>
          </div>
        </div>

    
          <div className="row container">
              
             <h5 className="text-centeSampleContextr"> {tempDoctors[0]?.speciality.name}</h5>

            {!doctors ? (
              <FetchProgress />
            ) : (
      
                  tempDoctors.map((item, index) => (
                    <div
                      className="card mb-3 col-md-4 mx-2 "
                      style={{
                        padding: "10px 0px",
                        "box-shadow": "0px 10px 20px #00000008",
                        border: "none",
                        outline: "none",
                        back2, 
                        width: "366px",
                        maxWidth: "100%"
                      }}
                    >
                      <div className="row g-0" onClick={onItemClicked.bind(this, item.username ? item.username : item.slug )} style={{cursor: "pointer"}}>
                        <div className="col-md-4 col-4" style={{margin: "auto", top: "0", bottom: "0", left: "0", right: "0", position: "relative"}}>
                          <img
                            src={item.profilePic}
                            className="card-img-top img-fluid rounded-circle"
                            alt="..."
                            style={{ width: "72px", height: "72px", margin: "auto", top: "0", bottom: "0", left: "0", right: "0", position: "absolute"}}
                          />
                        </div>
                        <div className="col-md-8 col-8">
                          <div className="card-body">
                            <h5 className="card-title text-black">{item.name} </h5>
                            <p className="card-text text-black">{item.speciality.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                
            
            )}
          </div>

      </div>
    </div>
  );
};

export default Doctors;
