import React, { useContext } from "react";

import {
  Grid,
  makeStyles,
  Paper,
  Divider,
  Button,
  fade,
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Avatar,
} from "@material-ui/core";

import { SampleContext } from "../../contexts/SampleContext";

const useStyle = makeStyles(function (theme) {
  return {
    roundBorder: {
      borderRadius: 14,
    },

    textBubbleLeftTop: {
      maxWidth: "70%",
      borderRadius: "1rem",
      borderTopLeftRadius: 0,
      padding: "0.5rem",
      color: theme.palette.common.black,
      font: "12px",

      marginLeft: "9px",
    },

    leftColor: {
      backgroundColor: "#E6E6E6",
    },

    textBubble: {
      maxWidth: "70%",
      borderRadius: "1rem",
      padding: theme.spacing(3),
      backgroundColor: "#F6F7FC",
    },

    textBubbleRightBottom: {
      maxWidth: "70%",
      borderRadius: "1rem",
      borderBottomRightRadius: 0,
      padding: "0.5rem",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      marginRight: "9px",
    },

    bg: {
      backgroundColor: theme.palette.primary.main,
    },
    img: {
      objectFit: "contain",
      maxWidth: 150,
      maxHeight: 150,
    },
  };
});

const ChatBubble = ({
  handleClick,
  messageType,
  contenttype,
  actions,
  img,
  time,
  sameUser,
  user,
  left,
  text,
  hideActions,
  textVariant,
  handleImageClick,
}) => {
  //   let _ref$textVariant = textVariant;
  //   let textVariant =
  //     _ref$textVariant === undefined ? "subtitle2" : _ref$textVariant;

  const { back1, back2, button1 } = useContext(SampleContext);
  var classes = useStyle();

  const handleiClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  const handlePdfClick = (val) => {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src=${val}></iframe>`
    );
  };

  const handledownload = (val) => {
    var a = document.createElement("a");
    a.href = val;
    a.download = "Image";
    a.click();
  };

  var renderAvatar = function renderAvatar(user) {
    return (
      <Box style={{ visibility: sameUser ? "hidden" : "visible", mr: 2 }}>
        <Avatar>{user}</Avatar>
      </Box>
    );
  };

  var renderTextActions = function renderTextActions(actionArr, text) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "1rem 0 1rem 0",
        }}
      >
        <Box className={classes.textBubble}>
          <Box style={{ textAlign: "center" }}>
            <Typography variant={textVariant}>{text}</Typography>
          </Box>

          {!hideActions && (
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              {actionArr.map((item, index) => {
                return (
                  <Box key={index} style={{ marginRight: 4 }}>
                    <Button
                      onClick={handleClick.bind(this, item.actionUrl)}
                      disableElevation={true}
                      variant={index === 0 ? "contained" : "outlined"}
                      color="primary"
                      size="small"
                      style={
                        item.name == "Renew now"
                          ? button1
                          : {
                              color: button1.backgroundColor,
                              border: `1px solid ${button1.backgroundColor}`,
                            }
                      }
                    >
                      {item.name}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          )}

          {hideActions && (
            <Box style={{ margin: "1rem", textAlign: "center" }}>
              <Typography variant={textVariant}>
                "Above system message sent to patient."
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  var renderDownloadIcon = function renderDownloadIcon() {
    return (
      <Box style={{ alignSelf: "center" }}>
        <IconButton size="medium" onClick={(e) => handledownload(img)}>
          <img
            src="/downloadIcon.png"
            alt="download-icon"
            style={{width: "20px"}}
          />
        </IconButton>
      </Box>
    );
  };

  var linkDecorator = function linkDecorator(href, text, key) {
    return (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
  };

  var renderTextBubble = function renderTextBubble() {
    return (
      <div>
        <Box
          style={{
            display: "flex",
            justifyContent: left ? "flex-start" : "flex-end",
          }}
        >
          {!left && img && renderDownloadIcon()}
          <Box
            className={
              left ? classes.textBubbleLeftTop : classes.textBubbleRightBottom
            }
            style={left ? { backgroundColor: "#E6E6E6" } : back2}
          >
            {text && <Typography variant={textVariant}>{text}</Typography>}
            {img &&
            (contenttype === "image/png" ||
              contenttype === "image/jpeg" ||
              contenttype === "image/jpg") ? (
              <img
                className={classes.img}
                width="100%"
                alt="chat-img"
                src={img}
                onClick={() => handleiClick(img)}
                style={{ cursor: "pointer" }}
              />
            ) : null}

            {img && contenttype === "application/pdf" ? (
              <img
                className={classes.img}
                width="100%"
                alt="chat"
                src="/pdf.png"
                onClick={() => handlePdfClick(img)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </Box>
          {left && img && renderDownloadIcon()}
        </Box>
        <Box
          style={{
            marginLeft: "3.5rem",
            display: "flex",
            justifyContent: left ? "flex-start" : "flex-end",
          }}
        >
          <Typography variant="caption">{time}</Typography>
        </Box>
      </div>
    );
  };

  return (
    <Box style={{ margin: " 2rem , 1rem" }}>
      {messageType === "AUTOMATED"
        ? renderTextActions(actions, text)
        : renderTextBubble(img, time, sameUser, user, left, text)}
    </Box>
  );
};

export default ChatBubble;
