import Axios from "axios-observable";
import AppConstants from "./app-constants";
import AuthService from "./services/auth-service";

const Api2 = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL1,
});


Api2.interceptors.request.use(function (config) {
  if (config.headers.hasOwnProperty('useStaffToken') &&
    config.headers.useStaffToken != null) {
    const token = AuthService.getStaffToken();
    if (token) {
      config.headers.common[AppConstants.AUTH_TOKEN_KEY] = token;
    }
    delete config.headers.useStaffToken;
  } else {
    const token = AuthService.getToken();
    if (token) {
      config.headers.common[AppConstants.AUTH_TOKEN_KEY] = token;
    }
  }

  return config;
});

export default Api2;
