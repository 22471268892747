import { makeStyles } from "@material-ui/core";

const useGlobalStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.bg,
  },
  inActiveBg: {
    backgroundColor: "#D8D8D8",
  },
  inActiveBgText: {
    color: "#6D6D6D",
  },
  width: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "70%",
    },
  },
  fileInput: {
    display: "none",
  },
  link: {
    textDecoration: "none",
  },

  minHeightRoot: {
    flexGrow: 1,
    minHeight: "calc(100vh - 64px)",
    backgroundColor: theme.palette.bg,
  },

  whiteBox: {
    // margin: theme.spacing(1),
    padding: "0 1rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },
  greyBg: {
    background: "#F6F7FC 0% 0% no-repeat padding-box",
  },
  m: {
    margin: theme.spacing(1),
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  minHeight: {
    minHeight: "80vh",
  },
  roundBorder: {
    borderRadius: 14,
  },

  roundWhiteBg: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },
  whiteBg: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
  },

  srollBar: {
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "1px",
      background: "rgba(0, 0, 0, 0.2)",
    },
  },
}));

export default useGlobalStyles;
