import React from "react";
import { CardActionArea, Box, Typography, makeStyles } from "@material-ui/core";
import { getFormattedDate } from "../../utils/app-util";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ReportItem = ({ handleClick, image, name, date, itemStyle }) => {
  const classes = useStyles();
  return (
    <CardActionArea onClick={handleClick} className={itemStyle}>
      <Box my={2} mx={1} display="flex" alignItems="center">
        {image && (
          <img
            style={{ objectFit: "contain" }}
            alt="report"
            height="80"
            width="100"
            src={image}
          />
        )}

        <Box width="60%" mx={1}>
          <Typography className={classes.ellipsis} variant="subtitle1">
            {name}
          </Typography>
          <Typography variant="caption">{getFormattedDate(date)}</Typography>
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default ReportItem;
