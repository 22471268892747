import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "informed";
import {
  Typography, Grid
} from "@material-ui/core";

import moment from "moment";

import Api from "../../utils/api";
import { isRequired } from "../../utils/validations";
import AppConstants from "../../utils/app-constants";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Routes from "../../routes/routes";
import { useParams } from "react-router-dom";
import axios from "axios";
import AuthService from "../../utils/services/auth-service";
import { SampleContext } from "../../contexts/SampleContext";
import { QRCodeSVG } from 'qrcode.react';
import "./purchase.css";

const PurchaseAppointment = ({ location }) => {
  const { orderId, appointment, appoSlug, AddAppointmentorderId } =
    (location || {}).state || {};
  const history = useHistory();
  const [doctor, setDoctor] = useState();
  const [paymentModes, setPaymentModes] = useState();
  const [signatureDataImg, setSignatureDataImg] = useState([]);
  const [signatureImg, setSignatureImg] = useState("");
  const [appointmentData, setAppointmentData] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [remark, setRemark] = useState("");

  const [buttonpayhospital, setbuttonpayhospital] = useState(false);

  const [load, setLoad] = useState(true);
  const { back1, back2, button1 } = useContext(SampleContext);
  const [status, setstatus] = useState();
  const [submit, setSubmit] = useState(true);
  const [docslug, setDocSlug] = useState();
  const tenant = AuthService.getTenant();
  const user = JSON.parse(localStorage.getItem("USER"));
  const params = useParams();

  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);

  const handlePaymentModeChange = (paymentMode) => {
    setSelectedPaymentMode(paymentMode);
  };

  const redirectap = () => {
    if (!AuthService.isAuthenticated) {
      window.location.replace("/");
    }
  };
  redirectap();

  var docSlug = docslug;

  useEffect(() => {
    const getAppointment = async (type) => {
      const req = {
        filterModel: {
          id: params.id,
        },
      };

      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/get_appointment`,
          data: req,
          headers: { "Content-Type": "application/json" },
        });
        if (response) {
          setAppointmentData(response.data.data[0]);
          let paitentslug = response.data.data[0].patientSlug;

          const ur = `${process.env.REACT_APP_API_BASE_URL}/getprofile?id=${response.data.data[0].doctorSlug}`;
          const getdata = () => {
            axios.get(`${ur}`).then((resp1) => {
              const ur1 = `${process.env.REACT_APP_API_BASE_URL}/get_appointment_payment_options?appointmentSlug=${appoSlug}`;

              const getdata1 = () => {
                axios.get(`${ur1}`).then((resp2) => {
                  setPaymentModes(resp2.data.data);
                  setLoad(false);
                });
              };
              getdata1();

              const ur2 = `${process.env.REACT_APP_API_BASE_URL}/get_pay_at_hospital?patientSlug=${paitentslug}&doctorSlug=${docSlug}`;

              const getdata2 = () => {
                axios.get(`${ur2}`).then((resp3) => {
                  setbuttonpayhospital(resp3?.data?.data?.show_button);
                });
              };
              getdata2();

              setDoctor(resp1.data.data);
              // if (resp1?.data?.data?.username?.length > 0) {
              //   setDocSlug(resp1?.data?.data?.username);
              // } else {
              //   setDocSlug(resp1?.data?.data?.slug);
              // }
              setDocSlug(resp1?.data?.data?.slug);
            });
          };
          getdata();
        }
      } catch (error) {}
    };
    getAppointment();
  }, [docSlug]);

  const formApi = useRef();

  const handleSubmit = (values) => {
    const paymentDto = {
      mode: values.mode,
      utr: values.utr,
      amount: appointment.fees,
    };

    Api.post(`/order/payment/${orderId}`, paymentDto).subscribe((resp) => {
      const resObj = resp.data || {};

      if (resObj.status) {
        formApi.current.reset();
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.PAYMENT_DONE);
        history.push(Routes.BOOK_APPOINTMENT_SUCCESS);
        localStorage.removeItem("appointment");
      }
    });
  };

  const handleInputs = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setSignatureImg(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSignatureDataImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSaveImage = async (razorPaymmentId) => {
    const formData = new FormData();
    if (razorPaymmentId?.length > 0) {
      formData.append("paymentId", razorPaymmentId);
    }
    formData.append("id", params.id);
    formData.append("status", status);
    formData.append("remark", remark);
    formData.append("paymentMode", paymentId);
    formData.append("image", signatureImg);
    const token = AuthService.getToken();

    if (status === "CONFIRMED") {
      setSubmit(false);
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/update_appointment`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-AUTH-TOKEN": token,
          },
        });
        if (response.data.status === true) {
          window.location.replace("/appointments");
          setSubmit(true);
        } else {
          SHOW_ERROR_NOTIFICATION(response.data.message);
          setSubmit(true);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          SHOW_ERROR_NOTIFICATION(
            error.response.data.message + AppConstants.MSG.SESSION_EXPIRED
          );
        } else if (error.response?.data?.status === false) {
          SHOW_ERROR_NOTIFICATION(error.response.data.message);
        } else {
          DEFAULT_ERROR_CALLBACK(error);
        }
        setSubmit(true);
      }
    } else {
      if (signatureImg?.length != 0 && paymentId?.length > 1) {
        setSubmit(false);
        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_BASE_URL}/update_appointment`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              "X-AUTH-TOKEN": token,
            },
          });
          if (response.data.status === true) {
            window.location.replace("/appointments");
            setSubmit(true);
          } else {
            SHOW_ERROR_NOTIFICATION(response.data.message);
            setSubmit(true);
          }
        } catch (error) {
          if (error.response?.status === 403) {
            SHOW_ERROR_NOTIFICATION(
              error.response.data.message + AppConstants.MSG.SESSION_EXPIRED
            );
          } else if (error.response?.data?.status === false) {
            SHOW_ERROR_NOTIFICATION(error.response.data.message);
          } else {
            DEFAULT_ERROR_CALLBACK(error);
          }
          setSubmit(true);
        }
      } else if (paymentId?.length === 0 || paymentId?.length === undefined) {
        SHOW_ERROR_NOTIFICATION("Choose Payment Mode");
      } else {
        SHOW_ERROR_NOTIFICATION(AppConstants.MSG.PAYMENT_SCREENSHOT);
      }
    }
  };

  const payit = (val) => {
    if(val?.paymentModeName === "UPI") {
      setstatus("PENDING_APPROVAL");
    } else {
      setstatus("CONFIRMED");
    }
    setPaymentId(val?.paymentMode);
  };

  const RazorPay = async () => {
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: `${appointmentData?.fees * 100}`,
      currency: "INR",
      name: tenant?.title ?? '',
      description: `${params.id}`,
      order_id: AddAppointmentorderId,
      handler: function (response) {
        handleSaveImage(response.razorpay_payment_id);
      },
      prefill: {
        name: `${user?.name}`,
        email: `${user?.email}`,
        contact: `${user?.mobileNumber}`,
      },
      notes: {
        name: tenant?.title,
        customer: {
          name: `${user?.name}`,
          email: `${user?.email}`,
          contact: `${user?.mobileNumber}`,
        }
      },
      theme: {
        color: back1.backgroundColor,
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();

    razor.on("payment.failed", function (response) {
      SHOW_ERROR_NOTIFICATION(response.error.description);
    });
  };

  const submitPayment = () => {
    // payment id for RAZORPAY -> 660575cd04b77146ca19bae8
    if (paymentId === "660575cd04b77146ca19bae8") {
      RazorPay();
    } else {
      handleSaveImage("");
    }
  };

  return (
    <>
      {load ? (
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          style={{ margin: "90px auto" }}
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <Form onSubmit={handleSaveImage} apiRef={formApi}>
            {({ formState }) => (
              <div className="container" style={back1}>
                <div
                  className="row overflow-hidden"
                  style={{ marginTop: "80px" }}
                >
                  <main className="col-lg-12 col-md-12 col-sm-12 ">
                    <div className="text-start pt-3">
                      <h5>Appointment Booking</h5>
                      <hr
                        className="mt-3 "
                        style={{ borderTop: "1px dotted" }}
                      />
                    </div>
                    <div className="text-start my-2">Appointment Detail</div>
                    <hr style={{ borderTop: "1px dotted" }} />

                    <div
                      className="d-grid gap-3 mb-4 mx-2 rounded-3"
                      style={back2}
                    >
                      <div className="p-2 row ms-2">
                        <div className=" text-end">
                          {" "}
                          ₹ <b>{appointmentData?.fees} </b>
                        </div>
                        <div className="col-lg-6 col-8 text-start">
                          <h5>{doctor?.name}</h5>
                          {doctor?.speciality?.name}
                        </div>
                        <div className="row my-2">
                          <div className="col-sm-12 col-md-8">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <span className="mx-2">
                              {moment(appointmentData?.date).format(
                                "dddd Do, MMMM YYYY"
                              )}{" "}
                            </span>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <i class="fa-solid fa-clock "></i>
                            <span className="mx-2">
                              {moment(
                                appointmentData?.startTime,
                                "H:mm"
                              ).format("H:mm A")}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-start my-2">Choose Payment Mode</div>
                    <hr style={{ borderTop: "1px dotted" }} />
                    {paymentModes
                      ?.filter(
                        (paymentMode) =>
                          paymentMode?.status === true ||
                          paymentMode?.status === undefined
                      )
                      .map((paymentMode, index) => {
                        return (    
                          <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          key={index}
                          direction="column"
                        >
                          <Grid item>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item direction="row">
                                <input
                                  type="radio"
                                  checked={
                                    paymentMode?._id ===
                                    selectedPaymentMode?._id
                                  }
                                  onChange={(event) => {        
                                    handlePaymentModeChange(
                                      paymentModes.find(pMode => pMode?._id === event.currentTarget.value)
                                    );
                                    payit(paymentModes.find(pMode => pMode?._id === event.currentTarget.value));
                                  }}
                                  name="paymentMode"
                                  value={paymentMode?._id}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                      {paymentMode?.paymentModeName === 'UPI' ? `${paymentMode?.paymentModeName} - ${paymentMode?.description}` : paymentMode?.paymentModeName}
                                  </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* TO SHOW QR CODE FOR UPI */}
                          {/* Payment Mode ID for UPI ->635ba3683682b440056a3506 */}
                          {(paymentMode?.paymentModeName === 'UPI' && paymentMode?._id ===
                                  selectedPaymentMode?._id) ? <Grid item>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Grid item>
                                  <QRCodeSVG
                                    id="upi-id-qr"
                                    value={`upi://pay?pa=${paymentMode?.description}&am=${appointmentData?.fees}&cu=INR`}
                                    size={200}
                                    width={"100%"}
                                    level={"H"}
                                    includeMargin={false}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid> : null}
                        </Grid>
                        );
                      })}
                  </main>
                </div>

                <div className="d-grid gap-2 back1 mt-4">
                  <div className="form-group back1">
                    <label
                      className="text-start my-2"
                      for="formGroupExampleInput"
                    >
                      Enter Remark for Doctor (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg mt-2 back1"
                      id="formGroupExampleInput"
                      onChange={(e) => setRemark(e.target.value)}
                      style={back2}
                    />
                  </div>
                </div>

                {selectedPaymentMode?.paymentModeName === "UPI" ? <div className="d-grid gap-2 back1" id="screenshot">
                  <div class="col-12">
                    <input
                      type="file"
                      name="signature"
                      class="form-control"
                      id="signature"
                      autocomplete="off"
                      style={{ visibility: "hidden" }}
                      onChange={handleInputs}
                      field="paym"
                      validate={isRequired}
                      validateOnMount
                      validateOnChange
                    />
                    <div>
                      <img
                        src={signatureDataImg}
                        style={{ width: "160px", marginBottom: "20px" }}
                      />
                    </div>
                    <label htmlFor="signature" id="screenbutn">
                      <div class="file btn btn-primary" style={button1}>
                        Upload Payment Screenshot
                      </div>
                    </label>
                  </div>
                  <br />
                  &nbsp;
                </div> : null}

                <hr className="my-4 mx-auto" />

                <section className="col-12 mb-3">
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary px-4 mx-2"
                      onClick={() =>
                        history.replace(`/appointment` + `/${docslug}`)
                      }
                    >
                      Back
                    </button>

                    {submit ? (
                      <button
                        onClick={submitPayment}
                        type="button"
                        className="btn btn-primary px-4 buttonwidth mx-2"
                        style={button1}
                        id="otherPaymentBtn"
                      >
                        Confirm Payment
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary px-4 buttonwidth"
                        style={button1}
                        type="submit"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </button>
                    )}

                    <button
                      onClick={RazorPay}
                      type="button"
                      className="btn btn-primary px-4 buttonwidth mx-2"
                      style={{ button1, display: "none" }}
                      id="onlinePaymentBtn"
                    >
                      Confirm Payment
                    </button>
                  </div>
                </section>
              </div>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default PurchaseAppointment;
