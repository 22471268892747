import React, { useState, useEffect, useRef, useCallback } from 'react'

import Api2 from "../../utils/api2";
import Api from '../../utils/api';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";


import { useHistory } from "react-router-dom";

import "../room/room.css"
import { async } from 'rxjs/internal/scheduler/async';
import axios from 'axios';
import Loader from '../../components/layout/loader';
// import { CircleLoader as Loader } from '../../components/loader';
// import boy from "../../../src/assets/boy.png"
// import boy from "../../../src/assets/boynew.png"
import boypic from "../../../src/assets/userpic.png"

import OralCare from "../../../src/assets/OralCare.png"
import PassedMotion from "../../../src/assets/PassedMotion.png"
import BathtubSharp from '@material-ui/icons/BathtubSharp';
import Bed from "../../../src/assets/bedding.png"
import PassedFlatus from "../../../src/assets/passedflatus.png"
// import BedSharp from '@material-ui/icons/BedSharp';
// import AirSharp from '@material-ui/icons/AirSharp';
import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION, SHOW_INFO_NOTIFICATION } from '../../utils/app-util';
import AppConstants from '../../utils/app-constants';
import Routes from '../../routes/routes';
import { error } from 'jquery';
import { NotificationManager } from 'react-notifications';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, MenuItem, InputLabel, FormControl, Select } from '@mui/material';





const Room = () => {

    const [patientData, setPatientData] = useState()
    const [OTP, setOTP] = useState("");
    const [loginwithPassword, setLoginwithPassword] = useState(true)
    const [login, setLogin] = useState(false)
    const [logindata, setLogindata] = useState()
    const [username, setUserName] = useState('')
    const [loader, setloader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [doctorlist, setDoctorlist] = useState([])
    const [indicatorsdata, setIndicatorsdata] = useState()
    const [dataPostedSuccessfully, setDataPostedSuccessfully] = useState(false);
    let history = useHistory();
   console.log("sdasdasda",indicatorsdata?.indicators)
    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState("+91");

   console.log("doctorlist",doctorlist)

    // functionality for req doctor visit
    const [open, setOpen] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [admithistorydata, setAdmithistorydata] = useState()

    const handleOpen = () => {
        setOpen(true);
        setTextValue("")
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    };

    const handleChangeOtp = useCallback((enteredOtp) => {
        setOTP(enteredOtp);
        // setIsInvalidOTP(false);
    }, []);

    const handleSubmitReqDoctorVisit = () => {
        // Handle form submission here
        const obj = {
            admitId: patientData?.id,
            description: textValue,
            doctorSlug :patientData?.doctorSlug
            // doctorSlug: ""
            
        }
        console.log("obj",obj)
        Api2.post("/admit/visit/request/add", obj).subscribe((response) => {
            if (response?.data?.status) {
                // alert('saved successfully')
                SHOW_SUCCESS_NOTIFICATION("Successfully Updated")
            }
        }, (error) => {
            console.error(error)
            handleClose();
        }
        )

        console.log('Submitted:', textValue);
        handleClose();
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    // req doctor visit ends here

    console.log('patientdatainRoom', patientData?.doctorSlug)

    const handleLoginPassword = () => {
        setLoginwithPassword(false)
    }

    const handleLoginPin = () => {
        setLoginwithPassword(true)
    }


    const [formData, setFormData] = useState({
        mobileNumber: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChangePhoneNumber = useCallback((value, data) => {
        const number = value.slice(data.dialCode.length);
        setMobileNumber(number);
        setCountryCode(`+${data.dialCode}`);
        // setIsInvalidPhone(false);
    }, []);

    function formatTimestampToDDMMYYYY(timestamp) {
        const date = new Date(timestamp);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    }

    useEffect(() => {
        setloader(true)
        const UserString = localStorage.getItem("USER")
        const userObject = JSON.parse(UserString)


        const patientSlug = userObject?.slug;

        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');
         console.log("userToken&&",userToken)
        setUserName(userName)



        if (userToken && userName) {
            //    handleSubmitPin()
            setLogin(true)
        } else {
            setLogin(false)
        }

        Api2.get(`/admit/patient/${patientSlug}?withDoctorList=${true}`).subscribe((resp) => {
            if (resp) {
                console.log('doctors list', resp?.data?.data?.otherDoctorsList);
                setDoctorlist(resp?.data?.data?.otherDoctorsList)
                console.log("data**",resp?.data?.data)
            }
            setloader(false)
        })



        Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
            if (resp) {
                console.log("test_rooms", resp?.data?.data?.surgeryDateTime)

                setPatientData(resp?.data?.data)


            }

        })


        Api2.get(`/admit/id/${patientData?.id}?withIndicators=true`).subscribe((response) => {
            if (response) {
                console.log('indicators_data', response?.data?.data);
                setIndicatorsdata(response?.data?.data)    
            }
        }, (error) => {
            console.error(error)
        }
        )


    }, [login, patientData?.id, dataPostedSuccessfully])

    const handleImageClick = (imageType) => {
        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');

        if (!userToken && !userName) {

            // SHOW_ERROR_NOTIFICATION("Need to Staff login")
            SHOW_INFO_NOTIFICATION("Need to Staff Login", 5000)
            // alert('need to staff login')
            return;
        }

        console.log('imageType', imageType)
        let obj = {}
        if (imageType === "passedMotion") {
            obj.admittedId = patientData?.id;
            obj.passedMotion = "done";
        }
        if (imageType === "passedFlatus") {
            obj.admittedId = patientData?.id;
            obj.passedFlatus = "done";
        }
        if (imageType === "bedding") {
            obj.admittedId = patientData?.id;
            obj.beddingDone = "done";
        }
        if (imageType === "oralCare") {
            obj.admittedId = patientData?.id;
            obj.oralCareDone = "done";
        }
        if (imageType === "bathing") {
            obj.admittedId = patientData?.id;
            obj.bathingDone = "done";
        }

        Api2.post("/admit/history/add", obj ,{ headers: { useStaffToken: userToken } }).subscribe((response) => {

            if (response?.data?.status) {

                setAdmithistorydata(response?.data?.data)
                setDataPostedSuccessfully(true);

                // alert('saved successfully')
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS)
            }
        }, (error) => {
            console.error(error)
        }
        )

    }










    // const admissionDate = new Date("2023-09-18 15:00:00");
    const admissionDate = new Date(`${patientData?.surgeryDateTime}`);
    const currentDate = new Date(); // Current date

    const timeDifference = currentDate - admissionDate;


    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


    console.log(`Patient has been admitted for ${daysDifference} days.`);


    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handlePinChange = (event, index) => {
        const newPin = [...pin];
        newPin[index] = event.target.value;
        setPin(newPin);

        // Automatically focus on the next input box
        if (index < inputRefs.length - 1 && event.target.value !== '') {
            inputRefs[index + 1].current.focus();
        }
    };

    // const handlePinChange = (event, index) => {
    //     const newPin = [...pin];

    //     if (event.key === 'Backspace') {
    //         // Handle backspace key by removing the last digit
    //         if (index > 0) {
    //             newPin[index - 1] = '';
    //             inputRefs[index - 1].current.focus();
    //         }
    //     } else if (event.key.match(/[0-9]/) && index < inputRefs.length - 1) {
    //         // Update the PIN digit and focus on the next input box for valid digits
    //         newPin[index] = event.key;
    //         inputRefs[index + 1].current.focus();
    //     }

    //     setPin(newPin);
    // };



    const handleSubmitPin = async (event) => {

        event.preventDefault();
        setloader(true)
        const enteredPin = pin.join(''); // Combine the PIN digits
        let actualPin = {
            admitPin: OTP
        }


        try {


            await Api.post('/admit_pin_login', actualPin).subscribe((response) => {
                if (response?.data?.status) {

                    console.log("usersdatatoken",response?.data?.data)

                    setLogindata(response?.data?.data)


                    setLogin(true)
                    setShowModal(false);
                    localStorage.setItem("usersdatatoken", response?.data?.data?.token)
                    localStorage.setItem("userName", response?.data?.data?.data?.name)
                    localStorage.setItem("userSlug", response?.data?.data?.data?.username)

                    setloader(false)


                } else {
                    console.log("response after loginstaff fail");
                    setLogin(false)
                    setloader(false)
                }
            })


        } catch (error) {
            // Handle errors, e.g., display an error message
            console.error('Error:', error);
            setLogin(false)
            setloader(false)
        }
    };
    const handleSubmitPassword = async (event) => {
        event.preventDefault();
        let objTosent = {
            countryCode: "+91",
            // mobileNumber: formData.mobileNumber,
            mobileNumber: mobileNumber,
            password: formData.password
        }
        console.log('obj', objTosent);


        try {
            await Api.post('/admit_login', objTosent).subscribe((response) => {
                if (response?.data?.data !== null) {
                    setLogindata(response?.data?.data)
                    setLogin(true)
                    localStorage.setItem("usersdatatoken", response?.data?.data?.token)
                    localStorage.setItem("userName", response?.data?.data?.data?.name)
                    localStorage.setItem("userSlug", response?.data?.data?.data?.username)


                } else {
                    console.log("response after loginstaff fail");
                    SHOW_ERROR_NOTIFICATION("Invalid Credentials")
                    setLogin(false)
                }
            })
        } catch (error) {
            console.error('Error:', error);
            SHOW_ERROR_NOTIFICATION("Invalid Credentials")
            setLogin(false)
        }


    };

    const logouthandler = () => {
        setloader(true)
        localStorage.removeItem("usersdatatoken")
        localStorage.removeItem("userName")
        localStorage.removeItem("userSlug")



        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');


        if (userToken && userName) {

            setLogin(true)
        } else {
            setloader(false)
            setLogin(false)
        }

    }


    const handleCallingstaff = (reqType) => {
        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');
        let request = ""
        if (reqType == "Nurse") {
            request = reqType;
            history.push(Routes.CALLINGSTAFF)

        } else {
            request = reqType;
            history.push(Routes.CALLHOUSEKEEPIN)
        }

        history.push(Routes.CALLINGSTAFF)
         console.log("userToken",userToken)
        Api2.post(`/admit/staff-request/add`, {
            admitId: patientData?.id,
            description: "staff request",
            requestType: request
        // },{ headers: { useStaffToken: userToken } }).subscribe((response) => {
        }).subscribe((response) => {
            if (response) {
                // alert("successfully updated")
                SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS)
            }
        }, (error) => {
            console.error(error)
        }
        )

    }


  console.log("doctorlist",doctorlist)



    return (

        loader ? (<Loader />) : (
            <section style={{ marginTop: "40px" }}>

                <div className="container">
                    <div class="row  pt-5">
                        <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                            <div class="circle1">
                                {
                                    patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
                                    ) : (
                                        <img className='circle1pic' src={boypic} alt="" />
                                    )
                                }



                            </div>
                        </div>
                        <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                            <div class=" text-white text-end  ">
                                <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
                                <h6>{patientData?.patient?.uhid}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row  pt-5">
                        <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                            <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
                                patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                                    <>
                                        {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                                    </>
                                ) : null
                            }
                                <br />

                                {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
                                <br />{patientData?.patient?.allergies ? (<>
                                    Allergies:{patientData?.patient?.allergies}
                                </>) : null}
                            </p>
                        </div>
                        <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6 d-flex justify-content-end ">
                            <div class=" text-white">
                                {
                                    login ? (

                                        <div style={{ cursor: "pointer", textAlign: 'center' }}><br />
                                            {/* <label style={{ textAlign: 'center', fontSize: "18px", textTransform: "uppercase" }}>{logindata?.data?.name}</label><br /> */}
                                            <label style={{ textAlign: 'center', fontSize: "18px", textTransform: "uppercase" }}>{username}</label><br />
                                            <button className="btn btn-primary " onClick={logouthandler} style={{ backgroundColor: 'rgb(192, 195, 198)', border: "none" }}>Logout</button>
                                        </div>
                                    ) : (
                                        <a href="#" class="text-decoration-none " data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <div class="circle2">
                                                <i
                                                    class='bx bx-message-square-add justify-content-center  text-center d-flex pt-3 text-dark'></i>
                                                <div class="slogin justify-content-center  text-center d-flex pt-1 text-decoration-none text-dark"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal">Staff
                                                    <br />Login
                                                </div>
                                            </div>


                                        </a>
                                    )
                                }




                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true"> */}
                    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModal ? 'block' : 'none' }}>

                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Login with Pin</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    {
                                        loginwithPassword ? (
                                            <form onSubmit={handleSubmitPin}>



                                                <OtpInput
                                                    value={OTP}
                                                    onChange={handleChangeOtp}
                                                    numInputs={6}
                                                    inputStyle='login-page__otp'
                                                    containerStyle='login-page__otp-container'
                                                    isInputNum={true}
                                                    shouldAutoFocus
                                                />

                                                {/* Submit button that spans the full width */}
                                                <button type="submit" style={{ marginTop: '20px', backgroundColor: 'rgb(62, 88, 122)' }} className="submit-button">Submit</button>

                                                {/* Display "OR" */}
                                                <div className="or-text">OR</div>

                                                {/* "Login with Password" link */}
                                                <div className="login-link">
                                                    {/* <a href="#" onClick={handleLoginPassword}>Login with Password</a> */}
                                                    {/* <button class="w-100 text-center p-2"  onClick={()=>handleLoginPassword} id="button_login_with_password" style={{border:'none',backgroundColor:'white'}}>Login with Password</button> */}
                                                    <button type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={handleLoginPassword}>Login with Password</button>

                                                </div>

                                            </form>

                                        ) : (
                                            <form onSubmit={handleSubmitPassword}>
                                                {/* Input for mobile number with +91 */}
                                                {/* <input
                                                    type="tel"
                                                    className="input-box"
                                                    placeholder="+91 Mobile Number"
                                                    name="mobileNumber"
                                                    value={formData.mobileNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                    pattern="^\[1-9][0-9]{9}$"
                                                /> */}

                                                <PhoneInput
                                                    country={"in"}
                                                    enableSearch
                                                    inputClass='login-page__phone-input'
                                                    id='phone-input'
                                                    countryCodeEditable={false}
                                                    placeholder='Enter Mobile'
                                                    onChange={handleChangePhoneNumber}
                                                />
                                                {/* Input for password */}
                                                <input
                                                    type="password"
                                                    className="input-box"
                                                    style={{ marginTop: '20px' }}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    required
                                                    minLength="6"
                                                />

                                                {/* Submit button that spans the full width */}
                                                <button type="submit" style={{ backgroundColor: 'rgb(62, 88, 122)' }} className="submit-button">Submit</button>
                                                {/* Display "OR" */}
                                                <div className="or-text">OR</div>

                                                {/* "Login with Password" link */}
                                                <div className="login-link">
                                                    {/* <a href="#" onClick={handleLoginPin}>Login with Pin</a> */}
                                                    <button type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={handleLoginPin}>Login with Pin</button>

                                                </div>
                                            </form>
                                        )
                                    }




                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                {
                    !login && (
                        <div class="row pt-4 g-3">
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                {/* <button onClick={() => {
                                    history.push(Routes.CALLINGSTAFF)
                                    

                                }} class="btn btn-outline-light w-100 text-center">Call Nurse
                                </button> */}
                                <button onClick={() => handleCallingstaff("Nurse")} class="btn btn-outline-light w-100 text-center">Call Nurse
                                </button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => handleCallingstaff("Housekeeping")} class="btn btn-outline-light  w-100 text-center">Call Housekeepin</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.MYMEDICATION)
                                }} class="btn btn-outline-light  w-100 text-center">My Medication</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={handleOpen} class="btn btn-outline-light  w-100 text-center">Request Doctor
                                    visit</button>
                            </div>

                        </div>
                    )
                }

                {/* visible when login */}

                {
                    login && (
                        <div class="row pt-4 g-3">
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.VITALS_MEASUREMENT)
                                }} class="btn btn-outline-light w-100 text-center">Vitals Measurement</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.INPUT_OUTPUT_MONITORING)
                                }} class="btn btn-outline-light  w-100 text-center">Input Output Charting</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.VIEW_IO_CHART)
                                }} class="btn btn-outline-light  w-100 text-center">View IO Chart</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.FEEDBACK)
                                }} class="btn btn-outline-light  w-100 text-center">Feedback</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.MYMEDICATION)
                                }} class="btn btn-outline-light  w-100 text-center">My Medication</button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-6  col-xs-6 col-6">
                                <button onClick={() => {
                                    history.push(Routes.REQUESTDOCTORVISIT)
                                }} class="btn btn-outline-light  w-100 text-center">Request Doctor Visit</button>
                            </div>

                        </div>
                    )
                }

                {/* Modal for request doctor visit */}

                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm" // You can adjust the size (e.g., sm, md, lg, xl) as needed
                    PaperProps={{
                        style: {
                            minWidth: '800px', // Adjust the minimum width as needed
                        },
                    }}
                >
                    <DialogTitle>Doctor Visit Request</DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "20px" }}>
                            <div style={{ flex: '1' }}>
                                <p style={{ textAlign: 'left' }}>Select Doctor</p>
                            </div>
                            <div style={{ flex: '1', textAlign: 'right' }}>
                                {/* Dropdown or select component goes here */}
                                <FormControl fullWidth>
                                    <InputLabel>Select an Option</InputLabel>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        label="Select an Option"
                                    >
                                        {Array.isArray(doctorlist) && doctorlist?.map((doctor, index) => (
                                            <MenuItem key={index} value={doctor.id}>
                                                {doctor?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <TextField
                            label="Enter Text"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={textValue}
                            onChange={handleTextChange}
                        />
                    </DialogContent>
                    <div style={{ padding: '16px', display: 'flex', justifyContent: 'center' }}>
                        <Button variant="outlined" color="primary" onClick={handleSubmitReqDoctorVisit}>
                            Submit
                        </Button>
                    </div>
                </Dialog>

                {
                    !login ? (
                        <div class="row pt-5 text-white g-4">

                            <div class="col-lg-3 col-6 col-sm-6">
                                <div class="box1 p-3">
                                    <p class="text-dark fw-bold pt-3 text-center">Already <br /> {daysDifference} days in the <br /> Hospital</p>
                                </div>
                            </div>
                            <div class="col-lg-3  col-6 col-sm-6">

                                <div class="box1 p-3 ">
                                    <div class=" pt-3 ">
                                        <div class="col-12">
                                            <i class="bx bxs-calendar pt-2 ">
                                            </i>
                                            <p class="fw-bold text-dark ">Expected Discharge Today</p>
                                            <br />
                                        </div>
                                        <div class="col-12">
                                            <p class="fw-bold text-dark">
                                                {formatTimestampToDDMMYYYY(patientData?.expectedDischarge)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-12 col-sm-12">
                                <div class="box1 text-dark p-3 "

                                    style={{
                                        width: "100%",
                                        height: "150px",
                                        backgroundColor: "#c4c4c4",
                                        borderRadius: "15px"
                                    }}
                                >
                                    <div class="orderfood">

                                        <i class='bx bx-basket'></i>
                                        <p class=" fw-bold mx-2">You are allowed to have {patientData?.allowedFoods}</p>
                                    </div>
                                    <a href="#"> <button class="btn btn-outline-dark mx-5  w-50 text-center">ORDER FOOD</button></a>
                                </div>

                            </div>

                        </div>
                    ) : ("")
                }


                {/* <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="carousel-caption">

                                <p class="pt-3">Custom Notice and Declartion 1:This section will be displaying text from the
                                    admin settings with similar makeup as the OPD patient UI and multiple sliding messages
                                    that can be clicked to go to a hyperlink
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="carousel-caption">

                                <p class="pt-3">Custom Notice and Declartion 1:This section will be displaying text from the
                                    admin settings with similar makeup as the OPD patient UI and multiple sliding messages
                                    that can be clicked to go to a hyperlink
                                </p>

                            </div>
                        </div>

                    </div>

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

                <div class="tast mt-5">
                    <h2 class="text-light text-center">Whats Connected to me</h2>
                    <div class="row pt-3 ">
                        <div class="col-lg-4 col-md-6 py-2 peding-tesk">
                            <input type="text" placeholder="Injection Monocef" class="inputtext" />
                            <span class="text-light">Due at 6pm</span>

                        </div>
                        <div class="col-lg-4 col-md-6 py-2">
                            <input type="text" placeholder="Injection Monocef" class="inputtext" />
                            <span class="text-light">Due at 6pm</span>

                        </div>
                        <div class="col-lg-4 col-md-6 py-2">
                            <input type="text" placeholder="Dinner - Semisolid" class="inputtext" />
                            <span class="text-light">Due at 6pm</span>

                        </div>
                    </div>

                </div> */}

                <div class="row">
                    <div class="col-12">
                        {
                            ((indicatorsdata?.indicators !== null) && (indicatorsdata?.indicators !== undefined)) ? (
                                <div class="icon d-flex justify-content-center text-dark text-center pt-5 g-3">
                                    <div class="col-1"> </div>
                                    {/* rgb(196, 196, 196) */}
                                    <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 p-0">
                                        <img
                                            src={PassedMotion}
                                            className="img-fluid px-2 mb-1"
                                            onClick={() => handleImageClick("passedMotion")}
                                            alt="image"
                                            id='passedMotion'
                                            style={{
                                                backgroundColor: indicatorsdata?.indicators?.passedMotion == true ? "rgb(196, 196, 196)" : "yellow",

                                                padding: '11px',
                                                borderRadius: '10rem',
                                                color: 'black',
                                                width: '50px',
                                                height: '50px',
                                                cursor: "pointer",

                                                userSelect: 'none',
                                            }}
                                        />

                                        <p class="text-light text-center">Passed Motion</p>
                                    </div>
                                    <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 p-0"

                                    >
                                        <img
                                            src={PassedFlatus}
                                            onClick={() => handleImageClick("passedFlatus")}
                                            className="img-fluid px-2 mb-1"
                                            alt="image"
                                            id='passedFlatus'
                                            style={{
                                                backgroundColor: indicatorsdata?.indicators?.passedFlatus == true ? "rgb(196, 196, 196)" : "yellow",
                                                padding: '11px',
                                                borderRadius: '10rem',
                                                color: 'black',
                                                width: '50px',
                                                height: '50px',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }}
                                        />

                                        <p class="text-light text-center">Passed Flatus</p>
                                    </div>
                                    <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 p-0">
                                        <img
                                            src={Bed}
                                            onClick={() => handleImageClick("bedding")}
                                            className="img-fluid px-2 mb-1"
                                            alt="image"
                                            id='bedding'
                                            style={{
                                                backgroundColor: indicatorsdata?.indicators?.beddingDone == true ? "rgb(196, 196, 196)" : "yellow",
                                                padding: '11px',
                                                borderRadius: '10rem',
                                                color: 'black',
                                                width: '50px',
                                                height: '50px',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }}
                                        />


                                        <p class="text-light text-center">Bedding</p>
                                    </div>
                                    <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 p-0">
                                        <img
                                            src={OralCare}
                                            onClick={() => handleImageClick("oralCare")}
                                            className="img-fluid px-2 mb-1"
                                            alt="image"
                                            id='oralCare'
                                            style={{
                                                backgroundColor: indicatorsdata?.indicators?.oralCareDone == true ? "rgb(196, 196, 196)" : "yellow",
                                                padding: '11px',
                                                borderRadius: '10rem',
                                                color: 'black',
                                                width: '50px',
                                                height: '50px',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }}
                                        />

                                        <p class="text-light text-center">Oral Care</p>
                                    </div>
                                    <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 p-0">

                                        <BathtubSharp
                                            id='bathing'
                                            onClick={() => handleImageClick("bathing")}
                                            style={{
                                                backgroundColor: indicatorsdata?.indicators?.bathingDone == true ? "rgb(196, 196, 196)" : "yellow",
                                                padding: '11px',
                                                borderRadius: '10rem',
                                                color: 'black',
                                                width: '50px',
                                                height: '50px',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                // fontSize: "15px",
                                            }} />

                                        <p class="text-light text-center">Bathing</p>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                            ) : ("")
                        }

                    </div>

                </div>




            </section>
        )

    )
}

export default Room