import Api from "./api";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import AppConstants from "./app-constants";

const loginSubject = new BehaviorSubject(
  !!localStorage.getItem(AppConstants.AUTH_TOKEN_KEY)
);


const AuthService = {
  config: {},
  get isAuthenticated() {
    return loginSubject.value;
  },

  register(data) {
    return Api.post("api/v1/auth/patient/register", data).pipe(
      map((response) => {
        this.setToken(response.data.data.token);
        this.setUser(response.data.data.data);
        loginSubject.next(true);
        return response.data.data.token;
      })
    );
  },

  login(data) {
    return Api.post("api/v1/auth/patient/signup", data).pipe(
      map((response) => {
        this.setToken(response.data.data.token);
        this.setUser(response.data.data.data);
        loginSubject.next(true);
        return response.data.data.token;
      })
    );
  },

  sendOTP(data) {
    return Api.post("api/v1/auth/patient/send-otp-login", data).pipe(
      map((response) => response["data"])
    );
  },

  fetchConfig() {
    return Api.get("/config").pipe(map((response) => response["data"]));
  },

  // sendOtp(data) {
  //   return Api.post("api/v1/auth/patient/send-otp", data).pipe(
  //     map((response) => response["data"])
  //   );
  // },

  resetPassword(data) {
    return Api.post("api/v1/auth/patient/reset", data).pipe(
      map((response) => response["data"])
    );
  },

  validateToken() {
    return Api.post("api/v1/auth/patient/validate-token").pipe(
      map((response) => {
        this.setUser(response.data.data);
        loginSubject.next(true);
      })
    );
  },

  logout() {
    return Api.post("api/v1/auth/patient/logout").pipe(
      map((data) => {
        localStorage.clear();
        loginSubject.next(false);
      })
    );
  },

  clearLocalStorage() {
    localStorage.removeItem(AppConstants.USER_KEY);
    localStorage.removeItem(AppConstants.AUTH_TOKEN_KEY);
    loginSubject.next(false);
  },

  setUser(user) {
    localStorage.setItem(AppConstants.USER_KEY, JSON.stringify(user));
  },

  getUser() {
    return JSON.parse(localStorage.getItem(AppConstants.USER_KEY));
  },

  setConfig(data) {
    localStorage.setItem(AppConstants.CONFIG_KEY, JSON.stringify(data));
  },

  getConfig() {
    return JSON.parse(localStorage.getItem(AppConstants.CONFIG_KEY));
  },

  setTenant(data) {
    localStorage.setItem(AppConstants.TENANT_KEY, JSON.stringify(data));
  },

  getTenant() {
    return JSON.parse(localStorage.getItem(AppConstants.TENANT_KEY));
  },

  setToken(token) {
    localStorage.setItem(AppConstants.AUTH_TOKEN_KEY, token);
  },

  getToken() {
    return localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
  },

  hasToken() {
    return !!localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
  },
};

export default AuthService;
