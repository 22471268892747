import React, { useState, useEffect } from 'react'
import Api2 from '../../utils/api2'
// import boypic from "../../../src/assets/boy.png"
import boypic from "../../../src/assets/userpic.png"

import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'

const InputOutputMonitor = () => {

  const [patientData, setPatientData] = useState()

  const [inputValue, setInputValue] = useState('');

  const [selectedValue, setSelectedValue] = useState('input');

  const [selectedButton, setSelectedButton] = useState(null);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // Perform actions based on the selected value
    if (event.target.value === 'input') {
      // Do something for 'input'
    } else if (event.target.value === 'output') {
      // Do something for 'output'
    }
  };


  // Step 2: Create the onChange event handler
  const handleInputChange = (event) => {
    // Step 3: Update the state variable with the new input value
    setInputValue(event.target.value);
  };



  // Function to handle button click
  const handleButtonClick = (value) => {
    console.log('selected button', value);
    setSelectedButton(value);
  };



  useEffect(() => {

    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)


    const patientSlug = userObject?.slug;

    Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
      if (resp) {
        console.log("test_rooms", resp?.data?.data)

        setPatientData(resp?.data?.data)


      }

    })



  }, [])



  const handlesubmit = (e) => {
    e.preventDefault()
    const userToken = localStorage.getItem('usersdatatoken');
    const userName = localStorage.getItem('userName');
    let obj = {
      admitId: patientData?.id,
      inputOutputType: selectedValue,
      valueType: selectedButton,
      volume: inputValue
    }

    Api2.post(`/admit/inputoutput/add`, obj ,{ headers: { useStaffToken: userToken } }).subscribe((response) => {
      if (response?.data?.status) {
        alert('saved successfully')
        SHOW_SUCCESS_NOTIFICATION("Successfully Updated")
      }
    }, (error) => {
      console.error(error)
    }
    )



  }




  return (
    <section style={{ marginTop: "40px" }}>
      <div className='container'>
        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <div class="circle1">
              {
                patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
                ) : (
                  <img className='circle1pic' src={boypic} alt="" />
                )
              }



            </div>
          </div>
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <div class=" text-white text-end  ">
              <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
              <h6>{patientData?.patient?.uhid}</h6>
            </div>
          </div>
        </div>


        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
              patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                <>
                  {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                </>
              ) : null
            }
              <br />

              {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
              <br />{patientData?.patient?.allergies ? (<>
                Allergies:{patientData?.patient?.allergies}
              </>) : null}
            </p>
          </div>

        </div>

        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h1 className="text-light">
              Add
              <select


                value={selectedValue}
                onChange={handleChange}
                style={{

                  borderRadius: '15px',
                  padding: '5px 6px 5px 5px',
                  fontSize: '2.5rem',
                  background: 'transparent',
                  color: 'white',
                  border: '0px',
                  marginLeft: '25px',
                  outline: 'none',
                }}
              >
                <option value="input" style={{ color: 'grey' }}>
                  Input
                </option>
                <option value="output" style={{ color: 'grey' }}>
                  Output
                </option>
              </select>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-6 col-6 col-xs-6">
            <div className="d-flex text-light">
              <label style={{ fontSize: '20px' }}>Volume</label>
              <input
                type="text"
                name="volume"
                placeholder=""
                className="btn btn-outline-light"
                value={inputValue} // Bind the input value to the state variable
                onChange={handleInputChange} // Attach the event handler
                style={{ width: '65px', marginLeft: '25px' }}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-6 col-6 col-xs-6"></div>
        </div>

        {
          selectedValue == "input" && (
            <div className="row mt-5 text-center">
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Oral Intake' ? 'selected' : ''}`}

                  value="Oral Intake" id="oralintake"
                  onClick={() => handleButtonClick('Oral Intake')}
                  style={{
                    fontSize: '16px',
                    // backgroundColor: 'initial',
                    // color: 'white'
                    backgroundColor: selectedButton === 'Oral Intake' ? 'yellow' : 'initial',
                    color: selectedButton === 'Oral Intake' ? 'black' : 'white'
                  }}>

                  Oral Intake
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Normal Saline' ? 'selected' : ''}`}

                  value="Normal Saline" id="normalsaline"
                  onClick={() => handleButtonClick('Normal Saline')}
                  style={{
                    fontSize: '16px',
                    padding: '5px',
                    backgroundColor: selectedButton === 'Normal Saline' ? 'yellow' : 'initial',
                    color: selectedButton === 'Normal Saline' ? 'black' : 'white'
                  }}>
                  Normal Saline
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Ringer Lactate' ? 'selected' : ''}`}
                  value="Ringer Lactate"
                  onClick={() => handleButtonClick('Ringer Lactate')}
                  style={{
                    fontSize: '16px', padding: '5px',
                    backgroundColor: selectedButton === 'Ringer Lactate' ? 'yellow' : 'initial',
                    color: selectedButton === 'Ringer Lactate' ? 'black' : 'white'
                  }}>
                  Ringer Lactate
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Dextrose 5%' ? 'selected' : ''}`}
                  value="Dextrose 5%"
                  onClick={() => handleButtonClick('Dextrose 5%')}
                  id="Dextrose"
                  style={{
                    fontSize: '16px', padding: '5px',
                    backgroundColor: selectedButton === 'Dextrose 5%' ? 'yellow' : 'initial',
                    color: selectedButton === 'Dextrose 5%' ? 'black' : 'white'
                  }}>
                  Dextrose 5%
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-30 ${selectedButton === 'Blood' ? 'selected' : ''}`}
                  value="Blood"
                  onClick={() => handleButtonClick('Blood')}
                  id="blood"
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Blood' ? 'yellow' : 'initial',
                    color: selectedButton === 'Blood' ? 'black' : 'white'
                  }}>
                  Blood
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light w-30 ${selectedButton === 'Others' ? 'selected' : ''}`}
                  value="Other"
                  onClick={() => handleButtonClick('Others')}
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Others' ? 'yellow' : 'initial',
                    color: selectedButton === 'Others' ? 'black' : 'white'
                  }}>
                  Others
                </button>
              </div>
            </div>
          )
        }




        {
          selectedValue == "output" && (
            <div className="row mt-5 text-center">
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  // className="btn btn-outline-light" 
                  className={`btn btn-outline-light  ${selectedButton === 'Urine' ? 'selected' : ''}`}

                  value="Urine"
                  onClick={() => handleButtonClick('Urine')}
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Urine' ? 'yellow' : 'initial',
                    color: selectedButton === 'Urine' ? 'black' : 'white'
                  }}>
                  Urine
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  // className="btn btn-outline-light w-40"
                  className={`btn btn-outline-light w-40  ${selectedButton === 'Naso Gastric' ? 'selected' : ''}`}

                  value="Naso Gastric"
                  onClick={() => handleButtonClick('Naso Gastric')}
                  style={{
                    fontSize: '16px', padding: '5px',
                    backgroundColor: selectedButton === 'Naso Gastric' ? 'yellow' : 'initial',
                    color: selectedButton === 'Naso Gastric' ? 'black' : 'white'
                  }}>
                  Naso Gastric
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  // className="btn btn-outline-light"
                  className={`btn btn-outline-light  ${selectedButton === 'Faeces' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('Faeces')}
                  value="Faeces"
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Faeces' ? 'yellow' : 'initial',
                    color: selectedButton === 'Faeces' ? 'black' : 'white'
                  }}>
                  Faeces
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light  ${selectedButton === 'Drain' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('Drain')}
                  value="Drain"
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Drain' ? 'yellow' : 'initial',
                    color: selectedButton === 'Drain' ? 'black' : 'white'
                  }}>
                  Drain
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  className={`btn btn-outline-light  ${selectedButton === 'Drain 2' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('Drain 2')}
                  value="Drain 2"
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Drain 2' ? 'yellow' : 'initial',
                    color: selectedButton === 'Drain 2' ? 'black' : 'white'
                  }}>
                  Drain 2
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4">
                <button
                  // className="btn btn-outline-light w-40"
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Chest Tube' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('Chest Tube')}
                  value="Chest Tube"
                  style={{
                    fontSize: '16px', padding: '5px',
                    backgroundColor: selectedButton === 'Chest Tube' ? 'yellow' : 'initial',
                    color: selectedButton === 'Chest Tube' ? 'black' : 'white'
                  }}>
                  Chest Tube
                </button>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4 col-xs-4 mt-4">
                <button
                  className={`btn btn-outline-light w-40 ${selectedButton === 'Others' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('Others')}
                  value="other"
                  style={{
                    fontSize: '16px', padding: '5px 30px',
                    backgroundColor: selectedButton === 'Others' ? 'yellow' : 'initial',
                    color: selectedButton === 'Others' ? 'black' : 'white'
                  }}>
                  Others
                </button>
              </div>
            </div>
          )
        }










        <div className="row mt-5 text-center">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xs-12 text-center">
            <button onClick={handlesubmit} className="btn btn-info" style={{ padding: '5px' }}>
              Submit
            </button>
          </div>
        </div>

      </div>
    </section>
  )
}

export default InputOutputMonitor