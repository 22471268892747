import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style2";

const ChatDisclaimer = ({ onAgreed }) => {
  const globalClass = useGlobalStyles();
  const textItems = [
    `Welcome to the Chat facility.`,
    `The chat function has been provided by your doctor to review reports and ask small queries related to treatment and your condition.`,
    `For lengthier discussions, a physical consultation is suggested.`,
    `Medical suggestions provided through chat need to be followed at your own risk.`,
    `As per usage statistics, the doctor may take up to 24 hours to respond to your queries. If there is anything urgent please visit the hospital
    or any other nearby hospital and review with your doctor at his next
    earliest availability.`,
    `Please keep your queries concise and to the point and try to ask all
    your queries as one message. Please expect a response during office
    hours between 9 am to 6 pm.`,
  ];
  return (
    <Box
      style={{
        backgroundColor: "#E6E6E6",
        borderRadius: "14px",
      }}
      textAlign="center"
      m={2}
      p={2}
    >
      {textItems.map((item, index) => (
        <Box my={2} textAlign="center" key={index}>
          <Typography className={globalClass.inActiveBgText} variant="caption">
            {item}
          </Typography>
        </Box>
      ))}
      <Button
        onClick={onAgreed}
        variant="contained"
        disableElevation
        color="primary"
      >
        I Agree
      </Button>
    </Box>
  );
};

export default ChatDisclaimer;
