import React, { useContext, useState } from "react";
import { SampleContext } from "../../contexts/SampleContext";
import "./pres.css";
import Api from "../../utils/api2";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import moment from "moment";
import AuthService from "../../utils/services/auth-service";
import { useRouteMatch } from "react-router-dom";

const Pres = () => {
  const { back1, back2 } = useContext(SampleContext);
  const [pres, setPres] = useState();
  const [vital, setvital] = useState({});
  const [coMorbidities, setcoMorbidities] = useState({});

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const name = searchParams.get("id");
  const history = useHistory();
  // const navigate = useNavigate();

  const redirectap = () => {
    if (!AuthService.isAuthenticated) {
      window.location.replace("/");
    }
  };
  redirectap();

  useEffect(() => {
    const UserString = localStorage.getItem("USER");
    const userObject = JSON.parse(UserString);

    const patientSlug = userObject?.slug;
    if (name.length) {
      Api.get(`appointment/print-new/${name}`).subscribe(
        (resp) => {
          if (resp.data.status == true) {
            setPres(resp.data.data);

            if (resp.data.data.patient.slug != patientSlug) {
              window.location.replace("/");

              return;
            }
            if (resp.data.data.prescription.vitals) {
              setvital(resp.data.data.prescription.vitals);
            }
            if (resp.data.data.prescription.coMorbidities) {
              setcoMorbidities(resp.data.data.prescription.coMorbidities);
            }
          } else {
            window.location.replace("/");
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            window.location.replace("/");
          }
        }
      );
    } else {
      window.location.replace("/");
    }
  }, []);

  return (
    <div style={back1}>
      <div className="row">
        {pres?.prescription?.images?.length > 0 ? (
          <>
            {pres?.prescription?.images?.map((item, i) => {
              return (
                <>
                  <div style={{ width: "100%" }}>
                    <img
                      src={`data:${item?.contenttype};base64,${item?.data?.data}`}
                      style={{ width: "100%" }}
                    />
                    <br />
                  </div>
                  <div class="mt-2"></div>
                </>
              );
            })}
            <button
              class="btn btn-primary col-1 mb-5"
              onClick={window.print}
              id="printPageButton"
            >
              Print
            </button>
          </>
        ) : (
          <div className="col-md-8 mx-auto">
            <table
              className="report-container"
              style={{
                fontFamily: "Comfortaa, cursive",
                fontSize: "9px",
                width: "100%",
              }}
            >
              <thead className="report-header">
                <tr>
                  <th className="report-header-cell">
                    <div className="header-info">
                      <img
                        src={pres?.doctor?.topLetterHead?.content}
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tfoot className="report-footer">
                <tr>
                  <td className="report-footer-cell">
                    <div className="footer-info footer_image">
                      <img
                        className=""
                        src={pres?.doctor?.bottomLetterHead?.content}
                        alt=""
                        style={{
                          position: "static",
                          bottom: "0px",
                          width: "100%",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
              <tbody className="report-content">
                <tr>
                  <td className="report-content-cell">
                    <div className="main">
                      <main class="overflow-hidden">
                        <section class="pt-4">
                          <div class="container">
                            <div class="row">
                              <div class="col-9">
                                <div class="row">
                                  <div class="col-4">
                                    <b>
                                      {pres?.patient?.gender == "MALE"
                                        ? "Mr. "
                                        : ""}
                                      {pres?.patient?.gender == "FEMALE"
                                        ? "Ms. "
                                        : ""}
                                      {pres?.patient?.name} (
                                      {pres?.patient?.age}/
                                      <span className="text-capitalize">
                                        {pres?.patient?.gender}
                                      </span>
                                      )
                                    </b>
                                    <br />
                                    <small>
                                      {" "}
                                      <b>Date:</b>{" "}
                                      {moment(pres?.prescription?.date).format(
                                        "Do, MMMM YYYY"
                                      )}
                                    </small>
                                  </div>
                                  <div class="col-4">
                                    <b>UHID: </b>
                                    {pres?.patient?.uhid}
                                    <br />
                                    <b>Address:</b> {pres?.patient?.address}
                                  </div>

                                  <div class="col-4">
                                    <b>Ph:</b> {pres?.patient?.mobileNumber}
                                  </div>
                                </div>
                                <div class="col-3"></div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        </section>

                        <section class="code">
                          <div class="container">
                            <div class="row">
                              <div class="col-md-8 col-lg-8">
                                {pres?.prescription?.fieldList.map((field) => {
                                  return (
                                    <>
                                      {(field?.fieldType == "text" ||
                                        field?.fieldType == "investigation") &&
                                        field?.fieldValue != null &&
                                        field?.fieldValue && (
                                          <>
                                            <b>{field?.fieldName}:</b>{" "}
                                            {field?.fieldValue}
                                            <br />
                                            <br />
                                          </>
                                        )}

                                      {field?.fieldType == "coMorbidities" &&
                                        field?.fieldValue &&
                                        Object.keys(field?.fieldValue)?.length >
                                          0 && (
                                          <>
                                            {<b>Co Morbidities:</b>}{" "}
                                            &nbsp;&nbsp;
                                            {Object.keys(
                                              field?.fieldValue
                                            )?.map((key, index) => {
                                              return (
                                                <>
                                                  ● <b>{key}</b> :{" "}
                                                  {field?.fieldValue[key]}{" "}
                                                  &nbsp;
                                                </>
                                              );
                                            })}
                                            <br />
                                            <br />
                                          </>
                                        )}

                                      {field?.fieldType == "vitals" &&
                                        field?.fieldValue &&
                                        Object.keys(field?.fieldValue)?.length >
                                          0 && (
                                          <>
                                            {<b>Vitals:</b>} &nbsp;&nbsp;
                                            {Object.keys(
                                              field?.fieldValue
                                            )?.map((key, index) => {
                                              return (
                                                <>
                                                  ● <b>{key}</b> :{" "}
                                                  {field?.fieldValue[key]}{" "}
                                                  &nbsp;
                                                </>
                                              );
                                            })}
                                            <br />
                                            <br />
                                          </>
                                        )}

                                      {field?.fieldType == "medications" &&
                                        field?.fieldValue?.length > 0 && (
                                          <>
                                            <b>{field?.fieldName}</b>{" "}
                                            <ul>
                                              {field?.fieldValue.map((v, i) => {
                                                return (
                                                  <>
                                                    <li>
                                                      {v?.name} {v?.dose}{" "}
                                                      {v?.frequency} x {v?.num}{" "}
                                                      {v?.interval} (
                                                      {v?.remarks})
                                                    </li>
                                                  </>
                                                );
                                              })}
                                            </ul>
                                          </>
                                        )}

                                      {field?.fieldType ==
                                        "dietaryRestriction" &&
                                        field?.fieldValue && (
                                          <>
                                            <b>{field.fieldName}</b>{" "}
                                            {field?.fieldValue && (
                                              <ul>
                                                <li> {field?.fieldValue}</li>
                                              </ul>
                                            )}{" "}
                                            <br />
                                          </>
                                        )}
                                    </>
                                  );
                                })}
                                <small>
                                  {pres?.prescription?.isFollowUpRequired &&
                                    `Follow Up After ${pres?.prescription?.followUp} As per Appointment`}
                                  {pres?.prescription?.nextAppointmentDate &&
                                    ` on ${moment(
                                      pres?.prescription?.nextAppointmentDate
                                    ).format("DD-MM-YYYY, hh:mm A")}`}
                                </small>
                              </div>
                              <div class="col-md-4 col-lg-4 text-md-center text-lg-center my-4">
                                <small>
                                  {" "}
                                  Scan QR to view
                                  <br />
                                  this prescription
                                </small>
                                <br />
                                <br />
                                <QRCode
                                  id="123456"
                                  value={`${pres?.tenant?.authWebUrl}/pres?id=${name}`}
                                  size={200}
                                  level={"H"}
                                  width={"100%"}
                                  includeMargin={true}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                        <section>
                          <div class="container mt-4">
                            <div class="row">
                              <div class="col-8"></div>
                            </div>
                          </div>
                        </section>
                        <div class="container my-2">
                          <div class="row">
                            <div class="col-12">
                              <br />
                              <img
                                src={pres?.doctor?.signature?.content}
                                className="img-fluid bg-white"
                                style={{
                                  objectFit: "contain",
                                  height: "30px",
                                }}
                              />
                              <br />
                              {pres?.doctor?.name}
                              <br />
                              {pres?.doctor?.registrationNo}
                              <hr />
                              {pres?.doctor?.appointmentUrl && (
                                <>
                                  Book your next appointment at{" "}
                                  <a>{`${pres?.doctor?.appointmentUrl}`}</a>{" "}
                                  <br />
                                </>
                              )}
                              {pres?.doctor?.email} |{" "}
                              {pres?.doctor?.booking_number}
                              <div class="mt-2">
                                <button
                                  class="btn btn-primary"
                                  onClick={window.print}
                                  id="printPageButton"
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                            <div class="col-7"></div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pres;
