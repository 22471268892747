import { atom } from "recoil";

export const LoginCred = atom({
  key: "login-cred",
  default: {
    mobileNumber: "",
    password: "",
    countryCode: "+91",
    email: "",
    name: "",
  },
});
