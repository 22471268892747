import React, { useState, useEffect, useRef } from 'react'
import Api2 from '../../utils/api2'
// import boypic from "../../../src/assets/boy.png"
import boypic from "../../../src/assets/userpic.png"

import { useHistory, useLocation } from "react-router-dom"
import Routes from '../../routes/routes'
import { async } from 'rxjs/internal/scheduler/async'
import moment from 'moment'
import { TablePagination, Typography, Box, Grid, TextField, Button } from "@material-ui/core"


const ViewIochart = () => {

  const [patientData, setPatientData] = useState()
  const [admitHistorydata, setAdmithistorydata] = useState([])
  const [totalInput, settotalInput] = useState(0)
  const [totalOutput, settotalOutput] = useState(0)
  const [totalelements, setTotalElements] = useState(0)
  let history = useHistory();


  useEffect(() => {

    const UserString = localStorage.getItem("USER")
    const userObject = JSON.parse(UserString)


    const patientSlug = userObject?.slug;

    // Only make the API call if it hasn't been made before

    const admitPatient = async () => {
      await Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
        if (resp) {
          console.log("test_rooms", resp?.data?.data);
          setPatientData(resp?.data?.data);
          console.log('patient id ', patientData?.id);
        }
      });
    }




    admitPatient();





  }, [])



  const admithistory = async () => {
    await Api2.post(`/admit/history/search`, {
      size: 20,
      page: 0,
      filterModel: {
        admitId: {
          type: "equals",
          // value: "6478601cb15649193601d60b"
          value: patientData?.id
        },
        inputOutput: {
          type: "custom",
          value: "exists"
        }
      },
      sortModel: []
    }).subscribe((response) => {
      if (response) {

        let total = response?.data?.data?.content;
        // console.log('admit history data', response?.data?.data?.totalElements)
        setTotalElements(response?.data?.data?.totalElements)
        let filteredDataforinput = total.filter(item => item.inputOutput.inputOutputType === 'input');
        let filteredDataforoutput = total.filter(item => item.inputOutput.inputOutputType === 'output');


        let totalInput = filteredDataforinput.reduce((acc, curr) => {
          return acc + curr.inputOutput.volume
        }, 0)
        let totalOutput = filteredDataforoutput.reduce((acc, curr) => {
          return acc + curr.inputOutput.volume
        }, 0)

        settotalInput(totalInput)
        settotalOutput(totalOutput)



        setAdmithistorydata(response?.data?.data?.content)

      }
    }, (error) => {
      console.log(error);
    }
    )
  }
  useEffect(() => {
    admithistory()
  }, [patientData?.id])

  // if (patientData?.id !== undefined) {
  //   admithistory()
  // }
  // admithistory()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <section style={{ marginTop: "40px" }}>

      <div style={{ marginBottom: "14vh" }} className='container'>

        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            {/* <div class="circle1">
              {
                patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
                ) : (
                  <img className='circle1pic' src={boypic} alt="" />
                )
              }



            </div> */}
            {/* <div class="text-white" style="border: 1px solid white; width: 60px; height: 60px; padding: 15px; border-radius: 50%; cursor: pointer;"> */}
            <div onClick={() => history.push(Routes.ROOM)} class="text-white" style={{ border: "1px solid white", width: "60px", height: "60px", padding: "15px", borderRadius: "50%", cursor: 'pointer' }}>
              <i class="fa fa-arrow-left"></i>
            </div>
          </div>
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <div class=" text-white text-end  ">
              <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
              <h6>{patientData?.patient?.uhid}</h6>
            </div>
          </div>
        </div>


        <div class="row  pt-5">
          <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
            <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
              patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                <>
                  {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                </>
              ) : null
            }
              <br />

              {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
              <br />{patientData?.patient?.allergies ? (<>
                Allergies:{patientData?.patient?.allergies}
              </>) : null}
            </p>
          </div>

        </div>











        <div class="table-responsive">
          <table class="table">

            <thead>

              <tr style={{ border: "rgb(62,88,122)" }}>
                <th class="text-center text-white">
                  <h5>Time</h5></th><th class="text-center text-white">
                  <h5>Input</h5></th>
                <th class="text-center text-white">
                  <h5>Output</h5></th></tr>
            </thead>
            <tbody>

              {
                Array.isArray(admitHistorydata) && admitHistorydata.map((row, index) => {
                  return (
                    <tr style={{ border: "rgb(62,88,122)" }}>
                      <td class="text-center text-white">
                        {moment(row?.createdAt).format("hh:mm a")}<br />{moment(row?.createdAt).format("DD-MM-YYYY")}
                      </td>

                      <td class="text-center text-white">{row?.inputOutput?.inputOutputType == "input" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType == "input" ? (row?.inputOutput?.valueType) : ("")}</td>
                      <td class="text-center text-white">{row?.inputOutput?.inputOutputType == "output" ? (row?.inputOutput?.volume + row?.inputOutput?.unit) : ("")}<br />{row?.inputOutput?.inputOutputType == "output" ? (row?.inputOutput?.valueType) : ("")}
                      </td>
                    </tr>

                  )
                })
              }

              {/* <td className='MuiTableCell-root MuiTablePagination-root d-flex justify-content-center text-white' colSpan={1000} style={{ border: "none" }}>
         
        </td> */}
              <TablePagination
                rowsPerPageOptions={[1, 10, 25, 50, 100, 500]}
                count={totalelements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              />


            </tbody>

          </table>
        </div>






        {/* <TablePagination
          rowsPerPageOptions={[1, 10, 25, 50, 100]}
          count={content?.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}





        <div class="" style={{ position: "relative" }}>
          <div class="container pt-3 background" style={{ position: 'fixed', bottom: "0px", width: "100%", backgroundColor: "#e9e6e6", height: "14vh" }}>
            <div class="row text-center text-dark">
              <div class="col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                <h5>Total Input</h5>
                <p>{totalInput} ml</p>

              </div>
              <div class="col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                <h5>Total Output</h5>
                <p> {totalOutput} ml</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </section>
  )
}

export default ViewIochart