const AppConstants = {
  AUTH_TOKEN_KEY: "X-AUTH-TOKEN",
  STAFF_KEY: "usersdatatoken",
  USER_KEY: "USER",
  CONFIG_KEY: "CONFIG",
  TENANT_KEY: "TENANT",
  MSG: {
    IMPORT_SUCCESS: "Imported successfully",
    GENERIC_ERROR: "Something went wrong!",
    SELECT_DATE: "Please select appointment date",
    UPDATE_SUCCESS: "Successfully updated",
    REGISTER_SUCCESS: "Successfully Registered",
    SELECT_FILE_MSG: "Select an image to upload",
    SELECT_APPOINTMENT_MSG: "Select an appointment type to book appointment",
    SELECT_TIME_MSG: "Choose a time slot to book appointment",
    LOGIN_MSG: "Please login before booking appointment",
    ADD_SUCESS: "Row added successfully",
    REPORT_ADD_SUCESS: "Report added successfully",
    DELETE_SUCCESS: "Row(s) deleted successfully",
    CREATE_SUCCESS: "Created successfully",
    SELECT_ROW_MSG: "Select at least one row to delete",
    DELETE_CONFIRM: "Are you sure you want to delete ?",
    CANCEL_SUCCESS: "Successfully cancelled",
    PAYMENT_DONE: "Payment Successfully added",
    APPOINTMENT_BOOKED: "Appointment Booked Successfully!",
    PAYMENT_SCREENSHOT: "Payment Screenshot Required!",
    CHECKEDIN: "Checked in Successfully",
    IMAGE_D: "Report deleted Successfully",
    CANCEL_REMARK: "Remark Required",
    SESSION_EXPIRED: " Please Login Again !"
  },
  MESSAGE: {
    NO_MOBILE_NUMBER: "Pease enter mobile number",
    VALID_MOBILE_NUMBER: "Please enter valid mobile Number",
    NO_OTP: "Please enter the otp",
    NO_NAME: "Please enter the name",
    SUCCESS_OTP: "OTP sent succesfully!",
  },
  PAGE_SIZE: 10,
};

export default AppConstants;
