import React, { useEffect, useState } from 'react'

import Api2 from '../../utils/api2'
// import boypic from "../../../src/assets/boy.png"
import boypic from "../../../src/assets/userpic.png"

import { SHOW_SUCCESS_NOTIFICATION } from '../../utils/app-util'
import AppConstants from '../../utils/app-constants'



const VitalsMeasurement = () => {



    const [patientData, setPatientData] = useState()

    const [formData, setFormData] = useState({
        temperature: '',
        temperature1: '',
        pulserate: '',
        tempraturmin: '',
        farenheitvalue: '',
        firevalue: '',
        smilevalue: '',
        textareavalue: ''
    });


    const handleTemperatureChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {

        const UserString = localStorage.getItem("USER")
        const userObject = JSON.parse(UserString)
        

        const patientSlug = userObject?.slug;

        Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
            if (resp) {
                console.log("test_rooms", resp?.data?.data)

                setPatientData(resp?.data?.data)


            }

        })



    }, [])





    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        const userToken = localStorage.getItem('usersdatatoken');
        const userName = localStorage.getItem('userName');
        // Access form data from formData object
        const { temperature, temperature1, pulserate, tempraturmin,
            farenheitvalue,
            firevalue,
            smilevalue,
            textareavalue
        } = formData;

        let object = {
            admitId: patientData?.id,
            bloodPressure: `${temperature}/${temperature1}`,
            oxygenSaturation: tempraturmin,
            ps: smilevalue,
            pulseRate: pulserate,
            remarks: textareavalue,
            respirationRate: firevalue,
            temperature: farenheitvalue
        }

        Api2.post(`/admit/vitals/add`, object, { headers: { useStaffToken: userToken } })
            .subscribe((response) => {
                if (response?.data?.status) {
                    // alert('saved successfully')
                    SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.CREATE_SUCCESS)
                }
            }, (error) => {
                console.error(error)
            }
            )

        // You can perform actions with the form data here, such as sending it to a server

        // Reset the form fields
        setFormData({
            temperature: '',
            temperature1: '',
            pulserate: '',
            tempraturmin: '',
            farenheitvalue: '',
            firevalue: '',
            smilevalue: '',
            textareavalue: ''
        });
    };














    return (
        <section style={{ marginTop: "40px" }}>

            <div className="container">
                <div class="row  pt-5">
                    <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                        <div class="circle1">
                            {
                                patientData?.patient?.profilePicUrl ? (<img className='circle1pic' src={patientData?.patient?.profilePicUrl} alt="Patient Pic" />
                                ) : (
                                    <img className='circle1pic' src={boypic} alt="" />
                                )
                            }



                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                        <div class=" text-white text-end  ">
                            <h1 class="fw-bold h1size ">{patientData?.roomNumber}</h1>
                            <h6>{patientData?.patient?.uhid}</h6>
                        </div>
                    </div>
                </div>
                <div class="row  pt-5">
                    <div class=" col-md-6 col-lg-6 col-sm-6 col-6 col-xs-6">
                        <p class="text-white  fs-6">{patientData?.patient?.name} <br /> {
                            patientData?.patient?.age != null || patientData?.patient?.gender != null ? (
                                <>
                                    {patientData?.patient?.age != null ? patientData?.patient?.age : ''}{patientData?.patient?.age != null && patientData?.patient?.gender != null ? '/' : ''}{patientData?.patient?.gender != null ? patientData?.patient?.gender : ''}
                                </>
                            ) : null
                        }
                            <br />
                            {/* {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov} <br /> Procedure: Conservative
                    Management <br />{patientData?.patient?.allergies ? (<>
                        Allergies:{patientData?.patient?.allergies}
                    </>) : null}   */}
                            {patientData?.doctor?.name} <br /> Diagnosis: {patientData?.prov}
                            <br />{patientData?.patient?.allergies ? (<>
                                Allergies:{patientData?.patient?.allergies}
                            </>) : null}
                        </p>
                    </div>

                </div>


                <div class="faicon pt-3 ">
                    <div class="d-flex">
                        {/* <i class='bx bxs-thermometer text-light fs-1'></i> */}
                        <i class="fa-solid fa-temperature-full text-light fs-1"></i>
                        <div class=" d-flex ">
                            {/* <input
                                type="text"
                                value={temperature}
                                onChange={handleTemperatureChange}
                                placeholder="Temperature"
                            /> */}
                            <input
                                type="text"
                                name='temperature'
                                value={formData.temperature}
                                onChange={handleTemperatureChange}
                                class=" form-control bg-transparent text-light border border-light mx-3 "
                            />
                            <input
                                type="text"
                                name='temperature1'
                                value={formData.temperature1}
                                onChange={handleTemperatureChange}
                                class=" form-control bg-transparent text-light border border-light mx-3"
                            />
                            <p class="text-light pt-2 ptext">mmHg</p>

                        </div>
                    </div>
                    <div class="d-flex pt-4">
                        {/* <i class='bx bx-pulse text-light fs-1 pt-2'></i> */}
                        <i class="fa-solid fa-heart-pulse text-light fs-1 pt-2"></i>
                        <div class=" d-flex ">
                            <input
                                type="text"
                                name='pulserate'
                                value={formData.pulserate}
                                onChange={handleTemperatureChange}
                                class=" form-control bg-transparent text-light border border-light mx-3 "
                            />

                            <p class="text-light ptext ">Per Minute</p>

                        </div>
                    </div>
                    <div class="d-flex pt-4">
                        {/* <i class='bx bxs-thermometer text-light fs-1 pt-2'></i> */}
                        <i class="fa-solid fa-temperature-full text-light fs-1 pt-2"></i>
                        <div class=" d-flex ">
                            <input
                                type="text"
                                name='tempraturmin'
                                value={formData.tempraturmin}
                                onChange={handleTemperatureChange}

                                class=" form-control bg-transparent text-light border border-light mx-3 "
                            />

                            <p class="text-light ptext ">Per Minute</p>

                        </div>
                    </div>
                    <div class="d-flex pt-4">
                        <i class="fa-solid fa-lungs text-light fs-2 pt-2"></i>
                        <div class="d-flex ">
                            <input
                                value={formData.farenheitvalue}
                                name='farenheitvalue'
                                onChange={handleTemperatureChange}
                                type="text"
                                class=" form-control bg-transparent text-light border border-light mx-3 "
                            />

                            <p class="text-light ptext ">Degree Farenhiet</p>

                        </div>
                    </div>
                    <div class="d-flex pt-4 ">
                        <i class="fa-solid fa-fire-flame-simple text-light fs-1 pt-2"></i>
                        <div class="d-flex ">
                            <input
                                value={formData.firevalue}
                                name='firevalue'
                                onChange={handleTemperatureChange}
                                type="text"

                                class=" form-control bg-transparent text-light border border-light mx-4 " />

                            <p class="text-light pt-3 ptext">%</p>

                        </div>
                    </div>
                    <div class="d-flex pt-4">
                        <i class="fa-solid fa-face-smile text-light fs-1 pt-2"></i>
                        <div class=" d-flex ">
                            <input
                                value={formData.smilevalue}
                                name='smilevalue'
                                onChange={handleTemperatureChange}
                                type="text"
                                class=" form-control bg-transparent text-light border border-light mx-3 "
                            />

                            <p class="text-light pt-3  ptext">/10</p>

                        </div>
                    </div>
                </div>
                <h1 class="text-light pt-5">Remarks</h1>
                <div class="pt-2">
                    <textarea
                        value={formData.textareavalue}
                        name='textareavalue'
                        onChange={handleTemperatureChange}
                        rows="5"
                        class="bg-transparent w-100 text-light p-3 border border-light"
                        placeholder="Please mention any significant remarks"></textarea>

                    <button onClick={handleSubmit} class="btn btn-outline-light mt-3 w-40 text-center">Submit Record</button>
                </div>

            </div>

















        </section>
    )
}

export default VitalsMeasurement