import React from "react";
import {
  Box,
  Typography,
  CardActionArea,
  Avatar,
  Button,
  makeStyles,
} from "@material-ui/core";
import { getFormattedDate } from "../../utils/app-util";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  duration: {
    color: theme.palette.primary.main,
  },
}));

const AppointmentItem = ({
  handleClick,
  image,
  name,
  text,
  date,
  time,
  // duration,
  itemStyle,
  timeStyle,
  status,
}) => {
  const classes = useStyles();

  return (
    <CardActionArea onClick={handleClick} className={itemStyle}>
      <Box my={2} mx={1} display="flex" alignItems="center">
        <Avatar alt="avatar-image" src={image}>
          {name.charAt(0)}
        </Avatar>

        <Box width={"60%"} mx={1}>
          <Typography className={classes.ellipsis} variant="subtitle1">
            {name}
          </Typography>
          <Typography className={classes.ellipsis} variant="subtitle2">
            {text}
          </Typography>
          <Typography
            className={classes.ellipsis}
            variant="caption"
            style={{ marginTop: 4, display: "inline-block" }}
            color={status === "PENDING_APPROVAL" ? "error" : "primary"}
          >
            {status === "PENDING_APPROVAL" && "Pending Approval"}
            {status !== "PENDING_APPROVAL" && "Confirmed"}
          </Typography>

          {/* <Button
                variant="contained"
                color="primary"
                style ={{ fontWeight: "bold", marginLeft: "16rem"}}
              > 
              Cancel
          </Button> */}

        </Box>

        <Box className={timeStyle} width={"25%"}>
          <Typography variant="caption">
            {moment(date).format("D") === moment(new Date()).format("D")
              ? "Today"
              : getFormattedDate(date)}
          </Typography>{" "}
          <br />
          <Typography variant="caption">
            {`${time}`}
            {/* <span className={classes.duration}>{duration} m</span> */}
          </Typography>
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default AppointmentItem;
