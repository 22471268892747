import Api from "../api2";
import Api1 from "../api";

import { map, concatMap } from "rxjs/operators";
import { timer } from "rxjs";

const DataService = {
  fetchList(baseUrl, page, size, filter,short=null) {
    var obj;
    if(short==null)
    {
    obj = {
      size: size,
      page: page,
      filterModel: filter,

    };
  }
  else{
    obj = {
      size: size,
      page: page,
      filterModel: filter,
      sortModel: short,

    };
  }
    return Api.post(`${baseUrl}`, obj).pipe(
      map((response) => response["data"]["data"])
    );
  },

  getList(baseUrl, interval) {
    return timer(0, interval).pipe(
      concatMap((_) => Api.get(`${baseUrl}`)),
      map((response) => response["data"]["data"])
    );
  },

  searchData(data, baseUrl) {
    return Api.get(`${baseUrl}?search=${data}`).pipe(
      map((response) => response["data"])
    );
  },

  updateData(data, baseUrl) {
    return Api.put(`${baseUrl}`, data).pipe(
      map((response) => response["data"]["data"])
    );
  },

  uploadImage( formData) { 
    return Api1.post(`upload_temp_image`, formData).pipe(
      map((response) => (response["data"]["data"]))
      ); 
    },

  createData(data, baseUrl) {
    return Api.post(`${baseUrl}`, data).pipe(
      map((response) => response["data"])
    );
  },

  polledFetchPaymentStatus(baseUrl, interval) {
    return timer(0, interval).pipe(
      concatMap((_) => Api.get(baseUrl)),
      map((response) => response["data"]["data"])
    );
  },
};

export default DataService;
