import React from "react";
import "./loader.scss";

export const CircleLoader = ({ dimension }) => {
  return (
    <div className='circle-loader'>
      <div
        style={{ width: dimension, height: dimension }}
        className={`loader`}
      ></div>
    </div>
  );
};
