import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import Routes from "../../routes/routes";
import Profile from "../profile/profile";
import ChatHisory from "../chat-history/chat-history";
import Sidebar from "../../components/layout/sidebar";
import { SidebarContext } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import AuthService from "../../utils/services/auth-service";
import PurchaseAppointment from "../../screens/home/purchase-appointment";
import Appointments from "../home/appointments";
import Report from "../report/report";
import AddReport from "../report/add-report";
import EditReport from "../report/edit-report";
import Login from "../login";
import Chat from "../chat/chat";
import Room from "../room/room"
import PymntCnfrm from "../package-details/pymnt-cnfrm";

import CallingStaff from "../room/callingStaff";
import Callhousekeepin from "../room/callhousekeepin";
import MyMedication from "../room/myMedication";
import RequestDoctorvisit from "../room/requestDoctorvisit";
import VitalsMeasurement from "../room/vitalsMeasurement";
import InputOutputMonitor from "../room/inputOutputMonitor";
import ViewIochart from "../room/viewIochart";
import Feedback from "../room/feedback";
import Pres from "../home/pres";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.bg,
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "100%",
    backgroundColor: theme.palette.bg,
  },
}));

const Main = () => {
  const tenant = AuthService.getTenant();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header tenant={tenant} title="Home" />

      <main className={classes.content}>
        <Switch>
          <Route
            exact
            path={Routes.APPOINTMENT_LIST}
            component={Appointments}
          />
          <Route exact path={Routes.REPORT} component={Report} />
          <Route exact path={Routes.ADD_REPORT} component={AddReport} />
          <Route exact path={Routes.PRES} component={Pres} />
          <Route exact path={Routes.VIEW_REPORT} component={EditReport} />
          <Route exact path={Routes.ROOM} component={Room} />
          <Route exact path={Routes.CALLINGSTAFF} component={CallingStaff} />
          <Route exact path={Routes.CALLHOUSEKEEPIN} component={Callhousekeepin} />
          <Route exact path={Routes.MYMEDICATION} component={MyMedication} />
          <Route exact path={Routes.REQUESTDOCTORVISIT} component={RequestDoctorvisit} />
          <Route exact path={Routes.VITALS_MEASUREMENT} component={VitalsMeasurement} />
          <Route exact path={Routes.INPUT_OUTPUT_MONITORING} component={InputOutputMonitor} />
          <Route exact path={Routes.VIEW_IO_CHART} component={ViewIochart} />
          <Route exact path={Routes.FEEDBACK} component={Feedback} />
          <Route exact path={Routes.PROFILE} component={Profile} />
          <Route exact path={Routes.CHAT} component={Chat} />
          <Route exact path={Routes.CHAT_HISTORY} component={ChatHisory} />
          <Route
            exact
            path={Routes.BOOK_APPOINTMENT_PYMNT}
            component={PurchaseAppointment}
          />

          <Route exact path={Routes.PYMNT_CNFRM} component={PymntCnfrm} />




          <Redirect to={Routes.APPOINTMENT} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default Main;
