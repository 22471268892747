import React, { useState, useCallback, useContext } from "react";

import { useRecoilValue } from "recoil";
import OtpInput from "react-otp-input";

import AuthService from "../../../utils/auth-service";
import {
  DEFAULT_ERROR_CALLBACK,
  useQuery,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION
} from "../../../utils/app-util";
import AppConstants from "../../../utils/app-constants";
import { LoginCred } from "../../../states";
import { CircleLoader } from "../../../components/loader";
import Routes from "../../../routes/routes"
import { SampleContext } from "../../../contexts/SampleContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const OtpPage = ({ setOpenOtpPage, phoneNumber = '', slug, logo }) => {
  const [OTP, setOTP] = useState("");
  const [userName, setuserName] = useState("");
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {back1, back2, button1} = useContext(SampleContext);
  const history = useHistory();

  const { mobileNumber, email, name, password, countryCode } =
    useRecoilValue(LoginCred);

  const query = useQuery();
  const redirectUrl = query.get("redirectUrl");
  const redirectPath = query.get("redirectPath");
  const date = query.get("date");
  const time = query.get("time");
  const type = query.get("type");

  const handleGoBack = useCallback(() => {
    setOpenOtpPage(false);
  }, [setOpenOtpPage]);

  console.log( );

  const handleVerifyOTP = useCallback(
    (event) => {
      event.preventDefault();
      const payload = {
        mobileNumber : phoneNumber || mobileNumber,
        password: OTP,
        name: name || userName,
        email,
        countryCode: countryCode
      };
      const namecheck = payload.name.toString().length;
      const OTPLength = OTP.toString().length;
      if (OTPLength === 4 && namecheck > 0) {
        setIsLoading(true);
        AuthService.login(payload).subscribe(
          (data) => {
            if (data) {
              if(slug?.length > 0 && slug != undefined)
              {
                window.location.replace(`/${slug}`);
              }
              else{
                // history.goBack();
                window.location.replace(`/`);
              }
            }
            setIsLoading(false);
          },
          (error) => {
            setIsLoading(false);
            DEFAULT_ERROR_CALLBACK(error);
          }
        );
      } else {
        if(!namecheck>0){
          SHOW_ERROR_NOTIFICATION("Please enter your name")
        }
        else
        {
          setIsInvalidOTP(true);
          setIsLoading(false);
        }

      }
    },
    [OTP, date, email, mobileNumber, name, phoneNumber, redirectPath, redirectUrl, time, type, userName]
  );

  const handleResendOtp = useCallback(() => {
    const payload = {
      mobileNumber,
      email,
      password,
      countryCode,
    };
    AuthService.sendOTP(payload).subscribe(
      () => {
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MESSAGE.SUCCESS_OTP);
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setIsLoading(false);
      }
    );
  }, [countryCode, email, mobileNumber, password]);

  const handleChangeOtp = useCallback((enteredOtp) => {
    setOTP(enteredOtp);
    setIsInvalidOTP(false);
  }, []);

  const handleChangeUserName = useCallback((event) => {
    const {value} = event.target;
    setuserName(value);
  }, []);

  useEffect(()=>{
    document.body.style.maxWidth = "100%";
    document.body.style.margin = "0px";
    document.body.style.padding = "0px 0px 0px 0px";
    document.getElementById("loginpage").style.color = back1.color;
    // document.getElementById("loginpage1").style.color = back1.color;
    // document.getElementById("loginpage2").style.color = back1.color;
    // document.getElementById("loginpage3").style.color = back1.color;

  },[])

  return (
    <>
      <div className='login-page__login-box'>
        <center>
          <img
            className='login-page__logo'
            src={logo}
            alt=''
          />
        </center>
      </div>
      <div className='login-page__login-box'>
        <div className='login-page__headline' id="loginpage">Log in to your account</div>
        <div className='login-page__text-content' style={back1}>
          Enter the OTP you recieved on mobile or email to login.
        </div>
      </div>
      <form
        className='login-page__login-box login-page__login-form'
        onSubmit={handleVerifyOTP}
      >
        <>
          {name ? (
            <div className='login-page__username' style={back1}>{`Welcome back ${name}`}</div>
          ) : (
            <div className='login-page__form-row'>
              <label className='login-page__label' htmlFor='name-field'>
                Name
              </label>
              <input
                id='name-field'
                type='text'
                className='login-page__password-input login-page__name-input'
                placeholder='Enter Name'
                onChange={handleChangeUserName}
                value={userName}
                autoComplete="off"
              />
            </div>
          )}
        </>

        <div className='login-page__form-row'>
          <div className='login-page__label login-page__label-row'>
            <label className='login-page__label' htmlFor='' style={back1}>
              Enter OTP
            </label>
            <span onClick={handleResendOtp} className='login-page__resend'>
              Resend OTP
            </span>
          </div>
          <OtpInput
            value={OTP}
            onChange={handleChangeOtp}
            inputStyle='login-page__otp'
            containerStyle='login-page__otp-container'
            isInputNum={true}
            shouldAutoFocus
          />
          {isInvalidOTP && (
            <span className='login-page__validation-error' style={back1}>
              This Field is Required
            </span>
          )}
        </div>
        <button
          className='login-page__login-btn login-page__form-row'
          type='submit'
          style={button1}
        >
          {isLoading ? <CircleLoader dimension={20} /> : "Submit"}
        </button>
      </form>
      <div className='login-page__switch-btn' onClick={handleGoBack}>
        Back
      </div>
    </>
  );
};
export default OtpPage;
